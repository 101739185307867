<template>
  <div
    :id="`m_toolbox_${id}`"
    data-tour="tour_m_toolbox_element"
    class="m-toolbox__module"
  >
    <h6 class="m-toolbox__title">
      {{ t("views.reports.toolbox.divider") }}
    </h6>
    <label class="mb-1 mx-3 type--small">
      {{ t("views.reports.toolbox.size") }}
    </label>
    <div class="m-toolbox__tool m-toolbox__size">
      <m-toolbox-icon
        v-for="(size, i) in sizes"
        :key="i"
        property="divider-size"
        :icon="size"
        :selected="size == settings?.size"
        :label="t(`views.reports.toolbox.size_${size}`)"
        class="m-toolbox__option"
        :class="{
          'm-toolbox__option--selected': size == settings?.size,
        }"
        @click="change(size, 'size')"
      />
    </div>
    <label class="mb-1 mx-3 type--small">
      {{ t("views.reports.toolbox.weight") }}
    </label>
    <div class="m-toolbox__tool m-toolbox__weight">
      <m-toolbox-icon
        v-for="(weight, i) in weights"
        :key="i"
        property="divider-weight"
        :icon="weight"
        :selected="weight == settings?.weight"
        :label="t(`views.reports.toolbox.weight_${weight}`)"
        class="m-toolbox__option"
        :class="{
          'm-toolbox__option--selected': weight == settings?.weight,
        }"
        @click="change(weight, 'weight')"
      />
    </div>
    <label class="mb-1 mx-3 type--small">
      {{ t("views.reports.toolbox.lineCap") }}
    </label>
    <div class="m-toolbox__tool m-toolbox__linecap">
      <m-toolbox-icon
        v-for="(cap, i) in lineCaps"
        :key="i"
        property="divider-cap"
        :icon="cap"
        :selected="cap == settings?.cap"
        :label="t(`views.reports.toolbox.lineCap_${cap}`)"
        class="m-toolbox__option"
        :class="{
          'm-toolbox__option--selected': cap == settings?.cap,
        }"
        @click="change(cap, 'cap')"
      />
    </div>
    <label class="mb-1 mx-3 type--small">
      {{ t("views.reports.toolbox.alignment") }}
    </label>
    <div class="m-toolbox__tool m-toolbox__alignment">
      <m-toolbox-icon
        v-for="(alignment, i) in alignments"
        :key="i"
        property="align"
        :icon="alignment"
        :selected="alignment == settings?.align"
        :label="t(`views.reports.toolbox.alignment_${alignment}`)"
        class="m-toolbox__option"
        :class="{
          'm-toolbox__option--selected': alignment == settings?.align,
        }"
        @click="change(alignment, 'align')"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import MToolboxIcon from "@root/components/MToolboxIcon.vue";

const props = defineProps({
  id: { type: [String, Number] },
  settings: { type: Object },
});

const emit = defineEmits(["update:settings"]);

const { t } = useI18n();

const sizes = ref(["small", "medium", "large"]);
const weights = ref(["slim", "regular", "thick"]);
const lineCaps = ref(["squared", "rounded", "round"]);
const alignments = ref(["left", "center", "right"]);

const change = (val, type) => {
  let s = { ...props.settings };
  s[type] = val;
  emit("update:settings", s, props.id);
};
</script>

<style scoped lang="scss">
.m-toolbox {
  &__tool {
    @include flex(center, center, row);
    gap: $spacing-4;
  }
}
</style>
