<template>
  <div class="m-modal__body">
    <div class="m-modal__search">
      <label>
        {{ t("views.share.modal_shareWithUserTeam") }}
      </label>
      <div class="mt-1">
        <m-search
          id="share_view"
          ref="searchRef"
          :options="notSharedWithOpts"
          type="autocomplete"
          :placeholder="t('views.share.modal_searchUserTeam')"
          floating
          @select="add"
        />
      </div>
    </div>
    <div v-if="additions" class="m-modal__share-with">
      <div
        v-for="(type, idx) in types"
        :key="idx"
        v-show="addedMembers[type].length"
        class="m-share-with__list"
      >
        <div v-for="(el, i) in addedMembers[type]" :key="i" class="m-active">
          <m-user-badge v-if="type == 'workspaceUser'" :user="el" type="tag" />
          <m-team-badge v-else-if="type == 'team'" :team="el" type="tag" />
          <!-- <h6
                v-if="type == 'teams'"
                :title="getUsernames(el)"
                class="type--small"
              >
                {{
                  t("views.share.modal_numberOfColleagues", {
                    number: el.users.length,
                  })
                }}
              </h6> -->
          <m-icon
            :id="`share_view_remove_${type + i}`"
            :tooltip="removeMemberTooltip"
            icon="close"
            variant="secondary"
            size="small"
            @click="remove(el, type)"
          />
        </div>
      </div>
    </div>

    <div v-if="usersTeamsWithAccess.length" class="m-modal__shared-with">
      <label class="mt-4 mb-1">
        {{ t("views.share.modal_usersTeamsWithAccess") }}
      </label>

      <div class="m-shared-with__list mb-1">
        <div
          v-for="(el, i) in usersTeamsWithAccess"
          :key="i"
          class="pa-1 m-list__item m-clickable"
        >
          <m-user-badge
            v-if="el.type == 'workspaceUser'"
            :user="el.obj"
            type="badge"
          />
          <m-team-badge
            v-else-if="el.type == 'team'"
            :team="el.obj"
            type="badge"
          />
          <m-icon
            :id="`share_view_remove_${el.type + i}`"
            :tooltip="removeMemberTooltip"
            icon="close"
            variant="secondary"
            size="small"
            @click="unshare(el.obj, el.type)"
          />
        </div>
      </div>
    </div>
    <div v-if="!additions" class="m-modal__bottom"></div>
  </div>
  <div class="m-modal__footer">
    <m-button
      v-if="slotProps.cancel"
      id="m_modal_cancel"
      :label="t('general.buttons.cancel')"
      type="text"
      variant="terciary"
      class="mt-3"
      @click="reject"
    />
    <m-button
      v-if="slotProps.close"
      id="m_modal_share"
      :label="t(`views.share.buttons_resolve_${alreadyShared}`)"
      type="contained"
      variant="primary"
      class="mt-3 ml-6"
      @click="resolve"
    />
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import { useRouter, useRoute } from "vue-router";
import MTeamBadge from "@components/MTeamBadge.vue";
import MUserBadge from "@components/MUserBadge.vue";
import MSearch from "@components/MSearch.vue";
import MIcon from "@components/MIcon.vue";
import MButton from "@components/MButton.vue";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const props = defineProps({
  slotProps: { type: Object },
});

const { t } = useI18n();
const { api } = useApi();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const alertsStore = useAlertsStore();
const workspacesStore = useWorkspacesStore();
const { push } = useRouter();
const route = useRoute();

const viewId = computed(() => props.slotProps.view.id);
const view = computed(() => props.slotProps.view);
const viewOwner = computed(() => view.value.createdById);
const searchRef = ref(null);
const types = ref(["workspaceUser", "team"]);
const membersList = reactive({ team: [], workspaceUser: [] });
const addedMembers = reactive({ team: [], workspaceUser: [] });
const removedMembers = reactive({ team: [], workspaceUser: [] });

/** @type {import("vue").ComputedRef<import("@/api").MonitioAPI.WorkspaceDTO}> */
const workspace = computed(() => workspacesStore.currentWorkspaceConfig);

const notSharedWith = computed(() => {
  return {
    team: membersList.team.filter(
      (team) =>
        !sharedWith.value.team.find(
          (sharedTeam) => sharedTeam.id === team.id
        ) && !addedMembers.team.find((addedTeam) => addedTeam?.id === team?.id)
    ),
    workspaceUser: membersList.workspaceUser.filter(
      (user) =>
        !sharedWith.value.workspaceUser.find(
          (sharedUser) => sharedUser?.id === user?.id
        ) &&
        !addedMembers.workspaceUser.find(
          (addedUser) => addedUser?.id === user?.id
        )
    ),
  };
});

const sharedWith = computed(() => {
  const format = { team: [], workspaceUser: [] };
  const teams = view.value.sharedWith?.team ?? {};
  const users = view.value.sharedWith?.workspaceUser ?? {};

  for (const userId in users) {
    if (Object.hasOwnProperty.call(users, userId)) {
      const user = workspacesStore.getUserById(userId);
      if (
        !removedMembers.workspaceUser.find(
          (removedUser) => removedUser.id === user.id
        )
      ) {
        format.workspaceUser.push(user);
      }
    }
  }

  for (const teamId in teams) {
    if (Object.hasOwnProperty.call(teams, teamId)) {
      const team = workspacesStore.getTeamById(teamId);
      if (
        !removedMembers.team.find((removedTeam) => removedTeam.id === team.id)
      ) {
        format.team.push(team);
      }
    }
  }

  return format;
});

const notSharedWithOpts = computed(() => {
  if (
    notSharedWith.value.team?.length &&
    notSharedWith.value.workspaceUser?.length
  ) {
    return notSharedWith.value.team
      .map((m) => ({
        ...m,
        value: m.id,
        label: m.name,
        type: "team",
      }))
      .concat(
        notSharedWith.value.workspaceUser?.map((m) => ({
          ...m,
          value: m.accountId,
          label: `${m.accountDetails.firstName} ${m.accountDetails.lastName}`,
          type: "user",
        }))
      );
  } else if (notSharedWith.value.team?.length) {
    return notSharedWith.value.team.map((m) => ({
      ...m,
      value: m.id,
      label: m.name,
      type: "team",
    }));
  } else if (notSharedWith.value.workspaceUser?.length) {
    return notSharedWith.value.workspaceUser.map((m) => ({
      ...m,
      value: m.accountId,
      label: `${m.accountDetails.firstName} ${m.accountDetails.lastName}`,
      type: "user",
    }));
  } else return [];
});

const resetShareValues = () => {
  addedMembers.length = 0;
  removedMembers.length = 0;
};

onMounted(() => {
  (membersList.team = workspace.value?.teams),
    // Filter out current user
    (membersList.workspaceUser = workspace.value?.workspaceUsers.filter(
      (x) => x.id != userStore.details.id && x.active
    ));
});

const getUsernames = (team) => {
  const names = [];
  team.users.forEach((user) => names.push(user.name));
  return names.join(", ");
};

const add = (val) => {
  let type = val.type;
  switch (val.type) {
    case "user":
      type = "workspaceUser";
      break;
    case "team":
      type = "team";
      break;
  }
  const value = membersList[type].find((f) => f.id == val.id);
  addedMembers[type].push(value);
};

const remove = (obj, type) => {
  const index = addedMembers[type].findIndex((member) => member.id === obj.id);
  if (index !== -1) {
    addedMembers[type].splice(index, 1);
  }
};

const unshare = (obj, type) => {
  removedMembers[type].push(obj);
};

const usersTeamsWithAccess = computed(() => {
  const list = [];
  const teams = view.value.sharedWith?.team ?? {};
  const users = view.value.sharedWith?.workspaceUser ?? {};

  for (const userId in users) {
    if (Object.hasOwnProperty.call(users, userId)) {
      if (userId !== viewOwner.value) {
        const user = workspacesStore.getUserById(userId);
        if (!user) continue;
        const wasRemoved = removedMembers.workspaceUser.find(
          (removedUser) => removedUser.id === user.id
        );
        if (!wasRemoved) {
          list.push({ type: "workspaceUser", obj: user });
        }
      }
    }
  }

  for (const teamId in teams) {
    if (Object.hasOwnProperty.call(teams, teamId)) {
      const team = workspacesStore.getTeamById(teamId);
      if (!team) continue;
      const wasRemoved = removedMembers.team.find(
        (removedTeam) => removedTeam.id === team.id
      );
      if (!wasRemoved) {
        list.push({ type: "team", obj: team });
      }
    }
  }

  list.sort((a, b) => {
    let stringA, stringB;

    if (a.type === "workspaceUser") {
      stringA =
        a.obj.accountDetails.firstName + ` ${a.obj.accountDetails.lastName}`;
    } else if (a.type === "team") {
      stringA = a.obj.name;
    }

    if (b.type === "workspaceUser") {
      stringB =
        b.obj.accountDetails.firstName + ` ${b.obj.accountDetails.lastName}`;
    } else if (b.type === "team") {
      stringB = b.obj.name;
    }

    return stringA.localeCompare(stringB);
  });

  return list;
});

const additions = computed(() => {
  const totalAdditions =
    addedMembers.team.length + addedMembers.workspaceUser.length;
  return totalAdditions > 0 ? totalAdditions : false;
});

const remotions = computed(() => {
  const totalRemotions =
    removedMembers.team.length + removedMembers.workspaceUser.length;
  return totalRemotions > 0 ? totalRemotions : false;
});

const listNames = (members, offset) => {
  const teamNames = members.team?.map((team) => team.name) ?? [];
  const workspaceUserNames =
    members.workspaceUser?.map(
      (user) =>
        `${user.accountDetails.firstName} ${user.accountDetails.lastName}`
    ) ?? [];

  const allNames = [...workspaceUserNames, ...teamNames];

  switch (offset) {
    //only first name
    case "first":
      return allNames[0];
    //only last name
    case "last":
      return allNames.pop();
    //all names except the last
    case "list":
      allNames.pop();
      return allNames.join(", ");
    default:
      return [];
  }
};

const resolve = () => {
  const message = [
    t("general.alerts.toast.sharedView_title", { name: view.value.name }),
  ];
  if (additions.value) {
    if (additions.value == 1) {
      message.push(
        t("general.alerts.toast.sharedView_single", {
          name: listNames(addedMembers, "first"),
        })
      );
    } else if (additions.value > 1 && additions.value < 4) {
      message.push(
        t("general.alerts.toast.sharedView", {
          names: listNames(addedMembers, "list"),
          name: listNames(addedMembers, "last"),
        })
      );
    } else {
      message.push(
        t("general.alerts.toast.sharedView_summary", {
          name: listNames(addedMembers, "first"),
          number: additions.value - 1,
        })
      );
    }
    viewsStore.shareViewWith(viewId.value, {
      ids: {
        workspaceUser: Object.fromEntries(
          addedMembers.workspaceUser?.map((x) => [x.id, ["Read"]]) ?? []
        ),
        team: Object.fromEntries(
          addedMembers.team?.map((x) => [x.id, ["Read"]]) ?? []
        ),
      },
    });
  }
  if (remotions.value) {
    if (remotions.value == 1) {
      message.push(
        t("general.alerts.toast.unsharedView_single", {
          name: listNames(removedMembers, "first"),
        })
      );
    } else if (remotions.value > 1 && remotions.value < 4) {
      message.push(
        t("general.alerts.toast.unsharedView", {
          names: listNames(removedMembers, "list"),
          name: listNames(removedMembers, "last"),
        })
      );
    } else {
      message.push(
        t("general.alerts.toast.unsharedView_summary", {
          name: listNames(removedMembers, "first"),
          number: remotions.value - 1,
        })
      );
    }
    viewsStore.unShareViewWith(viewId.value, {
      ids: {
        workspaceUser: Object.fromEntries(
          removedMembers.workspaceUser?.map((x) => [x.id, ["Read", "Write"]]) ??
            []
        ),
        team: Object.fromEntries(
          removedMembers.team?.map((x) => [x.id, ["Read", "Write"]]) ?? []
        ),
      },
    });
  }

  resetShareValues();
  props.slotProps.close();
  if (remotions.value || additions.value) {
    alertsStore.add({
      type: "toast",
      variant: "success",
      message: message,
    });
  }
};

const reject = () => {
  resetShareValues();
  props.slotProps.cancel();
};

const alreadyShared = computed(() => {
  const teams = view.value.sharedWith?.team
    ? Object.keys(view.value.sharedWith?.team).length
    : 0;
  const users = view.value.sharedWith?.workspaceUser
    ? Object.keys(view.value.sharedWith?.workspaceUser).length - 1
    : 0;
  const hasMembers = teams + users > 0 ? true : false;

  if (hasMembers) {
    return "save";
  } else {
    return "share";
  }
});

const removeMemberTooltip = computed(() => {
  return {
    content: t("views.share.modal_remove"),
    position: "dynamic",
  };
});
</script>

<style scoped lang="scss">
.m-modal {
  &__body {
    @include flex(flex-start, stretch, column);
  }

  &__search {
    margin-bottom: $spacing-1;

    > div {
      @include flex(flex-start, stretch, row);

      :deep(.m-search) {
        flex-grow: 1;
      }
    }
  }

  &__share-with {
    height: calc($spacing-11);
    padding: $spacing-2;
    @include round-corners($spacing-1);
    @include flex(flex-start, flex-start, row);
    flex-wrap: wrap;
    gap: $spacing-2;
    @include elevate-inset;
    overflow-y: auto;

    .m-share-with__list {
      @include flex(flex-start, stretch, row);
      flex-wrap: wrap;
      gap: $spacing-2;
    }
  }

  .m-shared-with__list {
    height: 22vh;
    padding: $spacing-2;
    @include round-corners($spacing-1);
    @include flex(flex-start, stretch, column);
    @include elevate-inset;
    overflow-y: auto;

    .m-list__item {
      margin-bottom: $spacing-2;

      &.m-clickable:hover {
        :deep(.m-badge__users .m-badge__image),
        :deep(.m-badge__users .m-users--more) {
          border: $spacing-1 solid color($pri-ultra-light);
        }
      }
    }

    .m-list__item:last-child {
      margin-bottom: $spacing-0;
    }
  }

  &__list {
    margin-bottom: $spacing-4;
    border: 1px solid color($pri-action-inactive, 0.3);
    @include round-corners($spacing-1);
    @include elevate-inset;

    h5 {
      padding: $spacing-2 $spacing-3;
      margin: $spacing-1;
      @include flex(space-between, center, row);
    }
  }

  &__add {
    margin: $spacing-3 $spacing-0;
    @include flex(space-between, flex-end, row);

    :deep(.m-input) {
      margin-right: $spacing-3;
      flex-grow: 1;
    }

    :deep(.m-button) {
      transform: translateY(-($spacing-2));
    }
  }

  &__bottom {
    width: auto;
    height: calc($spacing-11);
  }

  .m-clickable,
  .m-active {
    @include flex(space-between, center, row);
    cursor: default;
  }

  .m-clickable {
    :deep(.m-icon) {
      visibility: hidden;
    }

    &:hover :deep(.m-icon) {
      visibility: visible;
    }
  }

  .m-active {
    @include round-corners($spacing-1);
    background-color: color($pri-action-light, 0.2);

    :deep(.m-icon) {
      display: flex;
    }
  }

  .m-badge--tag {
    :deep(h5) {
      color: color($pri-action-inactive);
    }
  }
}
</style>
