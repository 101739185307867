<template>
  <div v-if="!appStore.fullscreen" class="m-views m-wrapper">
    <div id="scrollable" class="m-container">
      <div class="m-container__wrapper">
        <div class="m-views__content">
          <div
            role="button"
            class="m-create-view m-clickable"
            @click="openCreateView"
          >
            <div class="m-create-view__title">
              <m-icon
                id="m_create_view_icon"
                icon="view-new"
                status="active"
                variant="primary"
                class="mb-1"
                @click="openCreateView"
              />
              <span class="h6 type--small type--info">
                {{ t("views.create") }}
              </span>
            </div>
          </div>
          <div
            v-for="(view, i) in views"
            :key="i"
            class="m-preview-view m-clickable"
            @click="open(view)"
          >
            <div class="m-preview-view__image">
              <m-image :src="view.details.image" />
            </div>
            <div class="m-preview-view__details">
              <p class="h5">{{ view.name }}</p>
              <m-options
                v-if="!view.isBaseView && !view.isShared"
                :id="`m_views_view_${snakeCase(view.id)}`"
                :options="options"
                variant="secondary"
                hover="highlight"
                disableSort
                @select="(val) => selectOption(view, val)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import { useAppStore } from "@root/store/app";
import { useViewsStore } from "@root/store/modules/views";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

import { snakeCase } from "lodash-es";

import useCreateViewModal from "@components/modals/MCreateView/useCreateViewModal";
import useMViewSettingsModal from "@components/modals/MViewSettings/useMViewSettingsModal";
import MIcon from "@components/MIcon.vue";
import MImage from "@components/MImage.vue";
import MOptions from "@components/MOptions.vue";

const { t } = useI18n();
const { push, currentRoute } = useRouter();
const route = useRoute();
const appStore = useAppStore();
const viewsStore = useViewsStore();
const alertsStore = useAlertsStore();
const workspacesStore = useWorkspacesStore();
const { open: openCreateView } = useCreateViewModal();
const { open: openViewSettings } = useMViewSettingsModal();

const workspaceId = ref(workspacesStore.id);

const views = computed(() => {
  const baseViews = viewsStore.userViews
    .concat(viewsStore.sharedViews)
    .filter((f) => f.isBaseView)
    .map((m) => ({ ...m, readonly: true }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const allViews = viewsStore.userViews.concat(viewsStore.sharedViews);

  const draftViews = viewsStore.draftViews
    .filter((f) => !f.isBaseView && f.workspaceId == workspaceId.value)
    .map((m) => ({ ...m, readonly: true, isDraftView: true }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const organizedViews = allViews
    //.filter((f) => !f.isBaseView) //Filter out the base view
    .filter((f) => !f.folder && !f.isBaseView)
    .map((m) => ({ ...m, readonly: m.isShared }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return baseViews.concat(draftViews.concat(organizedViews));
});

const open = (view) => {
  push(`/views/${view.id}/tiles`);
};

const openDeleteDialog = (view) => {
  const resolveDialog = () => {
    if (view.isDraftView) {
      viewsStore.removeDraftViewById(view?.id);
    } else viewsStore.removeViewById(view?.id);
    push("/views/all");
  };

  alertsStore.add({
    type: "dialog",
    variant: "error",
    heading: t("general.alerts.dialog.deleteView.heading", {
      name: view.name,
    }),
    message: t("general.alerts.dialog.deleteView.message"),
    resolve: {
      label: t("general.buttons.delete"),
      action: resolveDialog,
    },
    cancel: t("general.buttons.keep"),
  });
};

const options = computed(() => {
  //"share",
  const opts = ["edit", "delete"];
  return opts.map((m) => ({
    icon: m,
    value: m,
    label: t(`views.${m}`),
  }));
});

const selectOption = (view, val) => {
  switch (val) {
    case "edit":
      openViewSettings(view);
      break;
    case "delete":
      openDeleteDialog(view);
    //   break;
    // case "share":
    //   break;
  }
};
</script>

<style scoped lang="scss">
.m-views {
  width: 100%;

  .m-container {
    width: 100%;
    height: 100%;
    padding-right: $spacing-2;
    @include flex(center, flex-start, row);

    &__wrapper {
      padding: $spacing-1;
    }
  }

  &__content {
    @include flex(flex-start, flex-start, row);
    flex-wrap: wrap;
    gap: $spacing-3;

    .m-create-view {
      padding: $spacing-2;
      aspect-ratio: 10 / 9;

      &__title {
        width: 100%;
        height: 100%;
        border: 1px dashed color($pri-action-inactive, 0.6);
        border-radius: $spacing-1;
        @include flex(center, center, column);
      }

      .m-icon,
      .h6 {
        color: color($pri-action-inactive);
        fill: color($pri-action-inactive);
      }

      &:hover {
        background: color($pri-action-light, 0.2);
        @include opacity-hover;
      }

      &:active,
      &:focus {
        @include opacity-active;
      }
    }

    .m-preview-view {
      padding: $spacing-2;
      @include flex(flex-start, flex-start, column);
      cursor: pointer;

      &__image {
        width: 100%;
        @include round-corners($spacing-1);
        @include flex(center, stretch, row);
        aspect-ratio: 1 / 0.78;
        overflow: hidden;

        .m-image {
          width: unset;
          min-height: 100%;
          height: unset;
        }
      }

      &__details {
        width: 100%;
        padding: $spacing-1 $spacing-0 $spacing-0 $spacing-1;
        @include flex(space-between, center, row);
      }
    }
  }
}

@include mq(desktopXS) {
  .m-views {
    .m-create-view,
    .m-preview-view {
      min-width: calc((100% - $spacing-6) / 3);
      width: calc((100% - $spacing-6) / 3);
    }
  }
}

@include mq(desktopS) {
  .m-views {
    .m-create-view,
    .m-preview-view {
      min-width: calc((100% - $spacing-8) / 4);
      width: calc((100% - $spacing-8) / 4);
    }
  }
}

@include mq(desktopM) {
  .m-views {
    .m-create-view,
    .m-preview-view {
      min-width: calc((100% - $spacing-11) / 4);
      width: calc((100% - $spacing-11) / 4);
    }
  }
}

@include mq(desktopL) {
  .m-views {
    .m-create-view,
    .m-preview-view {
      min-width: calc((100% - $spacing-11) / 5);
      width: calc((100% - $spacing-11) / 5);
    }
  }
}

@include mq(desktopXL) {
  .m-views {
    .m-create-view,
    .m-preview-view {
      min-width: calc((100% - $spacing-11 - $spacing-5) / 6);
      width: calc((100% - $spacing-11 - $spacing-5) / 6);
    }
  }
}
</style>
