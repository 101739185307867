<template>
  <div
    :id="`m_toolbox_${id}`"
    data-tour="tour_m_toolbox_element"
    class="m-toolbox__module"
  >
    <h6 class="m-toolbox__title">
      {{ t("views.reports.toolbox.dashboard") }}
    </h6>
    <div class="m-toolbox__tool">
      <!-- TODO should be a m-autocomplete -->
      <m-selector
        id="m_toolbox_dashboard"
        :outsideLabel="t('views.reports.toolbox.dashboard')"
        v-model:selected="dashboard"
        :placeholder="t('views.reports.toolbox.dashboard_placeholder')"
        :options="dashboardsList"
        size="small"
      />
    </div>

    <div class="mb-3 m-toolbox__tool">
      <m-checkbox
        id="m_toolbox_element_custom_settings"
        v-model="elementCustomSettings"
        :label="t('views.dashboards.toolbox.elementCustomSettings')"
        size="small"
      />
    </div>
    <div v-if="elementCustomSettings" class="m-toolbox__tool">
      <m-selector
        id="m_toolbox_articles"
        :outsideLabel="t('views.reports.toolbox.view')"
        v-model:selected="viewId"
        :options="viewsList"
        size="small"
      />
    </div>
    <div v-if="elementCustomSettings" class="mb-6 m-toolbox__tool">
      <m-selector
        v-if="settings.customSettings?.dateRestriction?.isRelative"
        id="m_toolbox_since"
        :outsideLabel="t('views.reports.toolbox.since')"
        :selected="restriction.timeFrame"
        @update:selected="
          (val) =>
            (restriction = {
              isRelative: true,
              timeFrame: val.value,
            })
        "
        :options="restrictionOpts"
        size="small"
      />
      <m-simple-date-picker
        v-else
        v-model="restriction"
        size="small"
        orientation="vertical"
        hideButton
      />
      <m-button
        id="switch"
        type="text"
        variant="primary"
        size="xsmall"
        :label="
          restriction?.isRelative
            ? t('views.dashboards.toolbox.switchToCustomDate')
            : t('views.dashboards.toolbox.switchToDatePresets')
        "
        class="mt-1"
        @click="
          () =>
            (restriction = {
              ...restriction,
              isRelative: !restriction.isRelative,
            })
        "
      />
    </div>

    <!-- <label class="type--small mx-3">
      {{ t("views.reports.toolbox.header") }}
    </label>
    <div class="m-toolbox__tool">
      <div class="m-toolbox__header">
        <m-checkbox
          id="m_toolbox_dashboard_title"
          :modelValue="header.title"
          @update:modelValue="toggleHeader('title')"
          :label="t('views.reports.toolbox.header_title')"
          size="small"
        />
        <m-checkbox
          id="m_toolbox_dashboard_description"
          :modelValue="header.description"
          @update:modelValue="toggleHeader('description')"
          :label="t('views.reports.toolbox.header_description')"
          size="small"
        />
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import TimeFrame from "@utils/enums/timeFrames";
import { DateTime } from "luxon";
import { useViewFilters } from "@hooks/useViewFilters";
import MSelector from "@components/MSelector.vue";
import MButton from "@components/MButton.vue";
import MSimpleDatePicker from "@components/MSimpleDatePicker.vue";
import structuredClone from "@utils/structuredClone";
import MCheckbox from "@components/MCheckbox.vue";
import DateTimeUtils from "@root/utils/dateTime";
import { useViewsStore } from "@root/store/modules/views";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import { useReportsStore } from "@root/store/modules/reports";
import { useUserStore } from "@root/store/modules/user";

const props = defineProps({
  id: { type: [String, Number] },
  previewViewId: String,
  settings: {
    type: Object,
  },
});

const emit = defineEmits(["update:settings"]);

const { t } = useI18n();
const userStore = useUserStore();
const viewsStore = useViewsStore();
const reportsStore = useReportsStore();
const dashboardsStore = useDashboardsStore();
const route = useRoute();
const router = useRouter();
const { dateRestriction } = useViewFilters(router, route);
const reportId = computed(() => route.params.reportId);

const viewId = computed({
  get() {
    return props.settings.customSettings?.viewId ?? props.previewViewId;
  },
  set(val) {
    if (!val) return;
    if (val == props.settings?.customSettings?.viewId) return;
    const clone = structuredClone(props.settings);
    clone.customSettings ??= {};
    clone.customSettings.viewId = val.value;
    emit("update:settings", clone, props.id);
  },
});

const viewsList = computed(() => {
  return viewsStore.getAllViews.map((m) => ({ value: m.id, label: m.name }));
});

const dashboard = computed({
  get() {
    return props.settings?.dashboard?.id;
  },
  set(val) {
    if (!val) return;
    if (val == props.settings?.dashboard) return;
    const clone = structuredClone(props.settings);
    clone.dashboard = val;
    emit("update:settings", clone, props.id);
  },
});

const dashboardsList = computed(() => {
  return dashboardsStore.getAll.map((m) => ({
    value: m.id,
    label: m.name,
    ...m,
  }));
});

const restriction = computed({
  get() {
    if (!props.settings?.customSettings.dateRestriction)
      return dateRestriction.value;
    return props.settings.customSettings.dateRestriction;
  },
  set(val) {
    if (val.isRelative) {
      if (!val.timeFrame) val.timeFrame = "last7days";
    } else {
      if (val.timeFrame) {
        const report = reportsStore.getById(reportId.value);
        val = {
          ...val,
          ...DateTimeUtils.getTimeFrame(
            val.timeFrame,
            userStore.timeZone,
            report
          ),
        };
      }
    }

    const clone = structuredClone(props.settings);
    clone.customSettings.dateRestriction = val;
    emit("update:settings", clone, props.id);
  },
});

const restrictionOpts = computed(() => {
  const timeframes = TimeFrame.ToArray().map((m) => ({
    label: t(`general.time.${m.value}`),
    isRelative: true,
    ...m,
  }));
  timeframes.shift(); // removes "All"
  return timeframes;
});

const elementCustomSettings = computed({
  get() {
    return props.settings?.customSettings || false;
  },
  set(val) {
    const s = { ...props.settings };
    if (!val) {
      s.customSettings = null;
    } else {
      s.customSettings ??= {
        dateRestriction: { ...dateRestriction.value, reportId: reportId.value },
      };
      s.customSettings.viewId ??= viewId.value;
    }
    emit("update:settings", s, props.id);
  },
});
</script>

<style scoped lang="scss">
.m-toolbox {
  &__header {
    @include flex(space-between, center, row);
  }
}
</style>
