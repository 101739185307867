<template>
  <div :id="`m_module_${id}`" data-tour="tour_report_m_module" @click="select">
    <div v-if="editor" class="m-report__actions">
      <m-icon
        id="m_report_edit"
        :tooltip="t('general.buttons.edit')"
        icon="edit"
        variant="secondary"
        size="small"
        hover="weight"
        @click="select"
      />
      <m-icon
        id="m_report_remove"
        :tooltip="t('general.buttons.remove')"
        icon="delete"
        variant="secondary"
        size="small"
        hover="weight"
        @click="remove"
      />
    </div>
    <m-image
      v-if="settings.url"
      :src="settings.url"
      class="m-report__image"
      :class="`m-report__image--${settings.align} m-report__image--${settings.size} m-report__image--${settings.style}`"
    />
    <p v-else class="type--small type--empty">
      {{ t("views.reports.modules.image_noImage") }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import MIcon from "@components/MIcon.vue";
import MImage from "@components/MImage.vue";
import { useReportsStore } from "@root/store/modules/reports";

const props = defineProps({
  id: { type: [String, Number] },
  settings: {
    type: Object,
    default: () => ({
      size: "medium",
      style: "source",
      align: "center",
    }),
  },
});

const emit = defineEmits([
  "update:settings",
  "select-element",
  "remove-element",
]);

const { t } = useI18n();
const route = useRoute();
const reportsStore = useReportsStore();
const editor = computed(() => route.name.toLowerCase().includes("builder"));

const select = () => {
  emit("select-element", props.id);
};

const remove = () => {
  emit("remove-element", props.id);
};
</script>

<style scoped lang="scss">
.m-report {
  &__image {
    &--left {
      align-self: flex-start;
    }

    &--right {
      align-self: flex-end;
    }

    &--small {
      width: $column;
    }

    &--medium {
      width: $column-3;
    }

    &--large {
      width: 100%;
    }

    &--source {
      object-fit: cover;
    }

    &--square {
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }

    &--cinema {
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }
  }
}
</style>
