<template>
  <div
    :class="ClassicEditor ? 'm-report--editor' : 'm-report--preview'"
    @click="select"
  >
    <ckeditor :editor="ClassicEditor" v-model="data" :config="config" />
    <!--     <div v-if="editor" class="m-report__actions">
    <m-icon
        id="m_report_edit"
        icon="edit"
        size="xsmall"
        class="mr-2"
        @click.stop="select"
      /> 
      <m-icon
        id="m_report_remove"
        icon="delete"
        size="xsmall"
        @click.stop="remove"
      />
    </div>
    <textarea
      v-if="editor"
      v-model="value"
      class="m-report__text"
      :placeholder="t('views.reports.toolbox.placeholderText')"
      data-autofocus="true"
      v-focus
    ></textarea>
    <div
      v-else-if="settings.text"
      v-html="settings.text"
      class="m-report__text"
    ></div> -->
  </div>
</template>

<script setup>
import { computed } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const props = defineProps({
  modelValue: { type: String },
  config: { type: Object },
});

const data = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emits("update:modelValue", val);
  },
});

const emits = defineEmits(["update:modelValue"]);
</script>
