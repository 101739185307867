<template>
  <div class="m-mention m-modal__body">
    <div class="m-mention__actions">
      <div class="m-actions__mention" v-if="mode != 'unlink'">
        <h4 class="mr-3">{{ t("editMentionLink.mention") }}</h4>
        <h6>{{ value?.text }}</h6>
        <div v-if="mode != 'edit'" class="m-actions__actions">
          <m-button
            id="m_mention_link"
            type="outlined"
            variant="primary"
            size="xsmall"
            :label="t('editMentionLink.editLinkButton')"
            class="mr-5"
            @click="editLink"
          />
          <m-button
            id="m_mention_unlink"
            type="outlined"
            variant="error"
            size="xsmall"
            :label="t('editMentionLink.unlinkButton')"
            @click="unlinkMode"
          />
        </div>
      </div>
    </div>
    <template v-if="mode == 'unlink'">
      <h4 class="mr-3">{{ t("unLinkMention.modalDescription") }}</h4>
      <div class="switch_container">
        <m-toggle
          id="toggle_value"
          v-model:modelValue="applyToPresentDocument"
          :label="$t('unLinkMention.toggle')"
        />
      </div>
    </template>
    <template v-else>
      <div
        v-if="mode == 'edit' && applyToPresentDocument"
        class="mt-4 m-divider"
      ></div>

      <div v-if="mode == 'view'" class="m-mention__preview">
        <h4 class="mx-0 m-toolbox__title">{{ t("editMentionLink.entity") }}</h4>
        <div>
          <span class="h4 mr-3">
            {{ t("editMentionLink.name") }}
          </span>
          <span
            :id="`m_edit_mention_preview_${snakeCase(entity.name)}_name`"
            :data-tooltip-content="entity.name"
            data-tooltip-position="dynamic"
            class="h6"
          >
            {{ entity.name }}
          </span>
        </div>
        <div v-if="entity.description">
          <span class="h4 mr-3">
            {{ t("editMentionLink.description") }}
          </span>
          <span class="h6">
            {{ entity.description }}
          </span>
        </div>
        <div>
          <span class="h4 mr-3">
            {{ t("editMentionLink.link") }}
          </span>
          <a
            v-if="entity.uri"
            :id="`m_edit_mention_preview_${snakeCase(entity.name)}_link`"
            :data-tooltip-content="entity.uri"
            data-tooltip-position="dynamic"
            :href="entity.uri"
            target="_blank"
            class="type--wrap"
          >
            {{ entity.uri }}
          </a>
          <h6 v-else class="type--empty">{{ t("editMentionLink.noLink") }}</h6>
        </div>
        <div>
          <span class="h4 mr-3">
            {{ t("editMentionLink.type") }}
          </span>
          <span
            :id="`m_edit_mention_preview_${snakeCase(entity.name)}_type`"
            :data-tooltip-content="entity.type"
            data-tooltip-position="dynamic"
            class="h6"
          >
            {{ entity.type }}
          </span>
        </div>
      </div>

      <div
        v-else-if="mode == 'edit'"
        class="m-mention__edit"
        :class="{ 'm-mention__edit--scroll': !applyToPresentDocument }"
      >
        <div class="mt-2 m-mention__entity">
          <h4 class="mx-0 mb-2 m-toolbox__title">
            {{ t("editMentionLink.entity") }}
          </h4>
          <div>
            <label class="mb-1">
              {{ t("editMentionLink.name") }}
            </label>
            <m-autocomplete
              id="entity_name"
              @select="(val) => changedName(val)"
              @clear="clear"
              :propertyTypes="allPropertyTypes"
              :allowCustomQuerySelection="true"
              :query="entity.name"
              :clear="false"
              :class="{ 'mb-5': originalEntityName == entity.name }"
              floating
            />
          </div>
          <!--    <m-input
            v-else
            id="entity_name"
            :label="t('editMentionLink.name')"
            leading-icon="search"
            v-model="entity.name"
            @click="() => (isSearching = true)"
          /> -->
          <template v-if="originalEntityName != entity.name">
            <m-input
              id="entity_description"
              v-model="entity.description"
              :placeholder="t('editMentionLink.description')"
              :label="t('editMentionLink.description')"
            />
            <div>
              <m-input
                id="entity_link"
                v-model="entity.uri"
                :placeholder="t('editMentionLink.link')"
                :label="t('editMentionLink.link')"
              />
            </div>
            <m-selector
              id="entity_iptc"
              :options="keyTypes"
              :selected="entity.type"
              @update:selected="changedType"
              contentType="multiple"
              :outsideLabel="t('editMentionLink.type')"
              floating
            />
            <m-toggle
              id="toggle_value"
              v-model="applyToPresentDocument"
              :label="t('editMentionLink.changeOnlyPresentDocument')"
              class="mb-2"
            />
          </template>
        </div>
        <div
          v-if="!applyToPresentDocument && originalEntityName != entity.name"
          class="m-preview__rule"
        >
          <div class="mt-4 mb-2 m-divider"></div>
          <h5 class="mx-0 m-toolbox__title">
            {{ t("editMentionLink.ruleFilters") }}
          </h5>
          <div class="m-rule__content">
            <p class="type--warning type--small">
              {{ `${t("editMentionLink.viewsAffected")}:` }}
              <span class="ml-1 type--500">
                {{ backend.platformId }}
              </span>
            </p>
            <!--  <h6 v-if="!applyToPresentDocument" class="type--xsmall">
              {{ t("editMentionLink.changeWillNotAffectPresentDocument") }}
            </h6> -->
            <m-toggle
              id="m_edit_mention"
              v-model:modelValue="filters.caseSensitive"
              :label="t('editMentionLink.caseSensitive')"
            />
            <div>
              <m-selector
                id="entity_iptc"
                :options="IPTCs"
                :selected="filters.iptcs"
                @update:selected="updateFilterIPTCs"
                class="eem-autocomplete"
                type="multiple"
                :outsideLabel="t('editMentionLink.filterByIptc')"
                :placeholder="t('editMentionLink.filterByIptcPlaceholder')"
                :position="['left', 'top']"
                floating
              />
              <h6 class="ma-1 type--xsmall type--empty">
                <span v-for="(iptc, i) in filters.iptcs" :key="i" class="pr-2">
                  {{ iptc.label }}
                </span>
              </h6>
            </div>
            <div>
              <m-selector
                id="entity_languages"
                :options="languages"
                :selected="filters.languages"
                type="multiple"
                @update:selected="updateFilterLanguages"
                :outsideLabel="t('editMentionLink.filterByLanguage')"
                :placeholder="t('editMentionLink.filterByLanguagePlaceholder')"
                :position="['left', 'top']"
                floating
              />
              <h6 class="ma-1 type--xsmall type--empty">
                <span
                  v-for="(language, i) in filters.languages"
                  :key="i"
                  class="pr-2"
                >
                  {{ language.label }}
                </span>
              </h6>
            </div>
            <!--      <h6 v-if="!applyToPresentDocument" class="type--xsmall">
              {{ t("editMentionLink.changeWillNotAffectPresentDocument") }}
            </h6> -->
          </div>
        </div>
      </div>
    </template>
  </div>
  <div class="m-mention m-modal__footer">
    <template v-if="mode == 'unlink'">
      <m-button
        v-if="props.slotProps.cancel"
        id="m_modal_cancel"
        :label="t('general.buttons.cancel')"
        type="text"
        variant="terciary"
        class="mt-3"
        @click="props.slotProps.cancel"
      />
      <m-button
        v-if="props.slotProps.cancel"
        id="m_modal_continue"
        :label="t('unLinkMention.buttonContinue')"
        type="contained"
        variant="error"
        class="mt-3 ml-6"
        @click="unlinkMention"
      />
    </template>
    <template v-else>
      <m-button
        v-if="props.slotProps.cancel"
        id="m_modal_cancel"
        :label="t('general.buttons.cancel')"
        type="text"
        variant="terciary"
        class="mt-3"
        @click="props.slotProps.cancel"
      />
      <m-button
        v-if="props.slotProps.cancel"
        id="m_modal_continue"
        :label="t('general.buttons.save')"
        type="contained"
        variant="primary"
        class="mt-3 ml-6"
        :disabled="!canSave"
        :loading="isUpdatingEntity"
        @click="editEntity"
      />
    </template>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue";
import { IPTCLayer0Labels } from "../../../iptcs";
import { useI18n } from "vue-i18n";
import { checkForWikiImage } from "@utils/utils";

import { useApi } from "@api/api";
import MInput from "@components/MInput.vue";
import MAutocomplete from "@components/MAutocomplete.vue";
import MButton from "@components/MButton.vue";
import MToggle from "@components/MToggle.vue";
import useTranslator from "@hooks/useTranslator";
import MSelector from "@components/MSelector.vue";
import structuredClone from "@utils/structuredClone";
import { isEqual, snakeCase } from "lodash-es";
import { useUserStore } from "@root/store/modules/user";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const dropdown = ref(false);
const overOption = ref(false);
const { t } = useI18n();
const userStore = useUserStore();
const workspacesStore = useWorkspacesStore();
const { api } = useApi();
const { localizeLanguageCode } = useTranslator();
const userLocale = computed(() => userStore.i18nLanguage);
const userLang = computed(() => userLocale.value.split("-")[0]);

const props = defineProps({
  /** @type {mode: string, value : string, articleLang:string, articleIptcs:string[]} */
  slotProps: Object,
  name: String,
});

const mode = ref("view");
onMounted(() => {
  if (props.slotProps.mode == "created") mode.value = "edit";
});

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const workspaceId = ref(workspaceConfig.value.id);
const allPropertyTypes = computed(() => {
  return userStore.config.propertyTypeSettings.facets[workspaceId.value]
    .filter((x) => x.active && x.selected)
    .map((x) => x.searchKey);
});

const backend = ref(workspacesStore.currentWorkspaceConfig?.backend);
const value = ref(props.slotProps?.value);
const entityMetadata = ref(value.value?.entity);
const originalEntityName = ref(entityMetadata.value?.baseForm);
const entity = ref({
  name: entityMetadata.value?.baseForm,
  type: entityMetadata.value?.type,
  description: entityMetadata.value?.description,
  id: entityMetadata.value?.insightId,
  uri:
    entityMetadata.value?.uri ||
    value.value?.wikidataUri?.page ||
    entityMetadata.value?.page,
  nature: entityMetadata.value?.insightId
    ? "FromInsightDB"
    : "FromCurrentEDLSystem",
});
const originalEntity = structuredClone(entity.value);
const filters = ref({ languages: [], iptcs: [], caseSensitive: true });
const initialFilters = structuredClone(filters.value);

const applyToPresentDocument = ref(false);

const canSave = computed(
  () =>
    !isEqual(entity.value, originalEntity) ||
    !isEqual(filters.value, initialFilters)
);

const IPTCs = ref(
  IPTCLayer0Labels.map((element) => {
    return {
      label: element,
      value: element,
    };
  })
);

const updateFilterIPTCs = (val) => {
  if (!filters.value.iptcs.find((x) => x.value == val.value)) {
    filters.value.iptcs.push(val);
  } else {
    filters.value.iptcs = filters.value.iptcs.filter(
      (x) => x.value != val.value
    );
  }
};

const languages = ref(
  workspacesStore.languages.map((x) => ({
    value: x.isoCode,
    label: localizeLanguageCode(x.isoCode),
  }))
);
const updateFilterLanguages = (val) => {
  if (!filters.value.languages.find((x) => x.value == val.value)) {
    filters.value.languages.push(val);
  } else {
    filters.value.languages = filters.value.languages.filter(
      (x) => x.value != val.value
    );
  }
};

const isSearching = ref(false);
const isUpdatingEntity = ref(false);
//#####################################

const keyTypes = computed(() => {
  return [
    { label: t("general.facets.people"), value: "people" },
    { label: t("general.facets.organization"), value: "organization" },
    { label: t("general.facets.places"), value: "places" },
    { label: t("general.facets.other"), value: "other" },
  ];
});

const changedName = async (val) => {
  isSearching.value = false;
  entity.value.type = val.filter.replace("_qid", "");
  entity.value.name = val.label;
  if (val.uri) {
    entity.value.uri = val.uri;
  } else {
    const data = await checkForWikiImage(
      userLang.value,
      val.label,
      val.label,
      userLang.value
    );
    if (data.page) entity.value.uri = data.page;
    else entity.value.uri = "";
  }
  entity.value.description = val.description;
};
const changedType = (val) => {
  entity.value.type = val.value;
};
const editLink = () => {
  mode.value = "edit";
};
const clear = () => {
  entity.value.type = null;
  entity.value.name = "";
  entity.value.uri = "";
  entity.value.description = "";
};
const editEntity = async () => {
  isUpdatingEntity.value = true;
  const createAction = {
    mention: value.value?.text,
    mentionStartPos: value.value?.start,
    mentionEndPos: value.value?.end,
    mentionMatchCaseSensitive: filters.value.caseSensitive,
    changeOnlyPresentDocument: applyToPresentDocument.value,
    documentId: props.slotProps.article.id,
    action: "CreateLink",
    targetEntity: {
      nature: "UserDefined",
      idEntityInsight: entity.value.id,
      uri: entity.value.uri,
      baseForm: entity.value.name,
      type: entity.value.type,
      description: entity.value.description,
      entityLinkingKbid: entity.value.qid,
      baseFormLanguage: props.slotProps.article?.language,
    },
    filterByDocIptcAnyOf: filters.value.iptcs?.map((x) => x.value) || [],
    filterByLanguages: filters.value.languages?.map((x) => x.value) || [],
  };

  const removeAction = {
    ...createAction,
    action: "RemoveLink",
    targetEntity: {
      nature: "UserDefined",
      idEntityInsight: originalEntity.id,
      uri: originalEntity.uri,
      baseForm: originalEntity.name,
      type: originalEntity.type,
      description: originalEntity.description,
      entityLinkingKbid: originalEntity.qid,
      baseFormLanguage: props.slotProps.article?.language,
    },
  };

  try {
    /** @type {import("@root/types.api.local").MonitioAPI.MentionSection} */
    const result = await api.entity.putEntityMentionRule([
      removeAction,
      createAction,
    ]);

    if (result.data?.translations)
      // Return the new document
      props.slotProps.close({
        event: "editMention",
        result: result.data,
      });
  } catch (error) {
    alert("something went wrong display error message here");
  }
  isUpdatingEntity.value = false;
};

const unlinkMode = async () => {
  mode.value = "unlink";
};

const unlinkMention = async () => {
  const removeAction = {
    mention: value.value?.text,
    mentionStartPos: value.value?.start,
    mentionEndPos: value.value?.end,
    mentionMatchCaseSensitive: filters.value.caseSensitive,
    changeOnlyPresentDocument: applyToPresentDocument.value,
    documentId: props.slotProps.article.id,
    action: "RemoveLink",
    targetEntity: {
      nature: "UserDefined",
      idEntityInsight: originalEntity.id,
      uri: originalEntity.uri,
      baseForm: originalEntity.name,
      type: originalEntity.type,
      description: originalEntity.description,
      entityLinkingKbid: originalEntity.qid,
      baseFormLanguage: props.slotProps.article?.language,
    },
  };

  try {
    /** @type {import("@root/types.api.local").MonitioAPI.MentionSection} */
    const result = await api.entity.putEntityMentionRule([removeAction]);
    //Return the new document
    props.slotProps.close({
      event: "editMention",
      result: result.data,
    });
  } catch (error) {
    alert("something went wrong display error message here");
  }
};
</script>

<style scoped lang="scss">
.m-mention {
  &.m-modal {
    &__body {
      max-height: 60vh;
      padding: $spacing-0;
      @include flex(flex-start, stretch, column);
    }

    &__footer {
      margin-top: $spacing-3;
      @include flex(flex-end, center, row);
    }
  }

  &__actions {
    padding: $spacing-0;

    .m-actions {
      &__mention {
        margin: $spacing-1 $spacing-0 $spacing-4;
        @include flex(flex-start, center, row);
      }

      &__actions {
        @include flex(flex-end, center, row);
        align-self: flex-end;
        flex-grow: 1;
      }
    }
  }

  &__preview {
    padding: $spacing-4 $spacing-5;
    margin: $spacing-0 $spacing-0 $spacing-3;
    @include elevate-inset;

    > div:not(.m-preview__rule) {
      margin-bottom: $spacing-3;
      @include flex(flex-start, baseline, row);
    }

    .type--wrap {
      width: calc(100% - $spacing-11);
    }

    .m-rule__content {
      > div {
        @include flex(flex-start, center, row);
      }
    }
  }

  .m-divider {
    width: calc(100% - ($spacing-5 * 2));
  }

  &__edit {
    padding: $spacing-0 $spacing-6;
    flex-grow: 1;
    overflow-y: auto;

    &--scroll {
      @include elevate-inset;
      background-color: color($white);
    }
  }

  &__entity {
    > *:not(:last-child) {
      margin-bottom: $spacing-5;
    }
  }

  .m-rule {
    &__content {
      .type--warning {
        padding: $spacing-1 $spacing-3;
        border-radius: $spacing-1;
        background-color: color($warning-light, 0.3);
      }

      > * {
        margin-bottom: $spacing-5;
      }
    }
  }
}
</style>
