<template>
  <div
    :id="`m_module_${id}`"
    data-tour="tour_report_m_module"
    :class="editor ? 'm-report--editor' : 'm-report--preview'"
    @click="select"
  >
    <div v-if="editor" class="m-report__actions">
      <m-icon
        id="m_report_edit"
        :tooltip="t('general.buttons.edit')"
        icon="edit"
        variant="secondary"
        size="small"
        hover="weight"
        @click="select"
      />
      <m-icon
        id="m_report_remove"
        :tooltip="t('general.buttons.remove')"
        icon="delete"
        variant="secondary"
        size="small"
        hover="weight"
        @click="remove"
      />
    </div>
    <div
      class="m-report__divider"
      :class="`m-report__divider--${settings.align} m-report__divider--${settings.cap} m-report__divider--${settings.weight} m-report__divider--${settings.size}`"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useReportsStore } from "@root/store/modules/reports";
import MIcon from "@components/MIcon.vue";

const props = defineProps({
  id: { type: [String, Number] },
  settings: {
    type: Object,
    default: () => ({
      size: "medium",
      weight: "regular",
      cap: "rounded",
      align: "center",
    }),
  },
});

const { t } = useI18n();
const emit = defineEmits(["select-element", "remove-element"]);

const { currentRoute } = useRouter();

const reportsStore = useReportsStore();
const editor: ComputedRef<boolean> = computed(() =>
  currentRoute.value.path.includes("builder")
);

const select = () => {
  emit("select-element", props.id);
};

const remove = () => {
  emit("remove-element", props.id);
};
</script>

<style scoped lang="scss">
.m-report--editor,
.m-report--preview {
  @include flex(flex-start, center, column);
}

.m-report__divider {
  background-color: color($pri-action-inactive, 0.3);

  &--left {
    align-self: flex-start;
  }

  &--center {
    align-self: center;
  }

  &--right {
    align-self: flex-end;
  }

  &--round {
    @include round-corners($spacing-2);
  }

  &--rounded {
    @include round-corners($spacing-1);
  }

  &--slim {
    height: $spacing-1;
  }

  &--regular {
    height: $spacing-2;
  }

  &--thick {
    height: $spacing-3;
  }

  &--small {
    width: $column;
  }

  &--medium {
    width: $column-3;
  }

  &--large {
    width: 100%;
  }
}
</style>
