<template>
  <div
    :id="`m_toolbox_${id}`"
    data-tour="tour_m_toolbox_element"
    class="m-toolbox__module"
  >
    <h6 class="m-toolbox__title">
      {{ t("views.reports.toolbox.articles") }}
    </h6>
    <div class="m-toolbox__tool">
      <m-selector
        id="m_toolbox_articles"
        :outsideLabel="$t('views.reports.toolbox.articles_type')"
        v-model:selected="type"
        :options="typeOpts"
        size="small"
      />
    </div>
    <template v-if="type == 'latest'">
      <div class="m-toolbox__tool">
        <m-input
          id="m_toolbox_max_results"
          v-model="maxResults"
          :label="$t('views.reports.toolbox.articles_maxResults')"
          type="number"
          size="small"
        />
      </div>
      <div class="mb-3 m-toolbox__tool">
        <m-checkbox
          id="m_toolbox_element_custom_settings"
          v-model="aggregateDuplicates"
          :label="t('views.dashboards.toolbox.aggregateDuplicates')"
          size="small"
        />
      </div>
    </template>

    <label class="type--small mb-1 mx-3">
      {{ t("views.reports.toolbox.preview") }}
    </label>
    <div class="m-toolbox__tool">
      <m-toolbox-icon
        id="toolbox_icon_preview"
        property="article-preview"
        :icon="articlesPreview"
        :disabled="true"
        class="m-toolbox__option"
      />
      <div class="m-toolbox__preview">
        <m-button
          v-for="(type, i) in previews"
          :key="i"
          :id="`m_toolbox_articles_${type}`"
          :type="getButtonType(type)"
          variant="primary"
          :label="$t(`views.reports.toolbox.preview_${type}`)"
          size="xsmall"
          :disabled="type == 'title' ? true : false"
          @click="togglePreview(type)"
        />
      </div>
    </div>
    <div class="mb-3 m-toolbox__tool">
      <m-checkbox
        id="m_toolbox_element_custom_settings"
        v-model="elementCustomSettings"
        :label="t('views.dashboards.toolbox.elementCustomSettings')"
        size="small"
      />
    </div>
    <div v-if="elementCustomSettings" class="m-toolbox__tool">
      <m-selector
        id="m_toolbox_articles"
        :outsideLabel="$t('views.reports.toolbox.view')"
        v-model:selected="viewId"
        :options="viewsList"
        size="small"
      />
    </div>
    <div
      v-if="elementCustomSettings && type != 'saved'"
      class="mb-6 m-toolbox__tool"
    >
      <m-selector
        v-if="settings.customSettings?.dateRestriction?.isRelative"
        id="m_toolbox_since"
        :outsideLabel="$t('views.reports.toolbox.since')"
        :selected="restriction.timeFrame"
        @update:selected="
          (val) =>
            (restriction = {
              isRelative: true,
              timeFrame: val.value,
            })
        "
        :options="restrictionOpts"
        size="small"
      />
      <m-simple-date-picker
        v-else
        v-model="restriction"
        size="small"
        orientation="vertical"
        hideButton
      />
      <m-button
        id="switch"
        type="text"
        variant="primary"
        size="xsmall"
        :label="
          restriction?.isRelative
            ? t('views.dashboards.toolbox.switchToCustomDate')
            : t('views.dashboards.toolbox.switchToDatePresets')
        "
        class="mt-1"
        @click="
          () =>
            (restriction = {
              ...restriction,
              isRelative: !restriction.isRelative,
            })
        "
      />
    </div>
    <div class="m-divider"></div>
    <!-- <div class="m-toolbox__tool">
      <m-toggle
        id="m_toolbox_duplicates"
        :modelValue="duplicates"
        @update:modelValue="toggleDuplicates"
        :label="$t('views.reports.toolbox.duplicates_aggregate')"
        size="small"
      />
    </div> -->
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import MToolboxIcon from "@components/MToolboxIcon.vue";
import { useViewFilters } from "@hooks/useViewFilters";
import MSelector from "@components/MSelector.vue";
import TimeFrames from "@utils/enums/timeFrames";
import MSimpleDatePicker from "@components/MSimpleDatePicker.vue";
import MInput from "@components/MInput.vue";
import MButton from "@components/MButton.vue";
import TimeFrame from "@root/utils/enums/timeFrames";
import MCheckbox from "@components/MCheckbox.vue";
import { DateTime } from "luxon";
import DateTimeUtils from "@root/utils/dateTime";
import structuredClone from "@root/utils/structuredClone";
import { useViewsStore } from "@root/store/modules/views";
import { useReportsStore } from "@root/store/modules/reports";
import { useUserStore } from "@root/store/modules/user";

const props = defineProps({
  id: { type: [String, Number] },
  previewViewId: String,
  settings: {
    type: Object,
    default: () => ({
      source: "view",
      subset: "latest",
      header: ["title"],
      preview: {
        title: true,
        feed: true,
        date: true,
        thumbnail: true,
      },
      customSettings: {
        viewId: undefined,
        dateRestriction: {
          isRelative: true,
          timeFrame: "sinceSnapshot",
        },
      },
      maxNumDocs: 8,
    }),
  },
});

const emit = defineEmits(["update:settings"]);

const { t } = useI18n();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const reportsStore = useReportsStore();
const route = useRoute();
const router = useRouter();
const reportId = computed(() => route.params.reportId);
const { dateRestriction } = useViewFilters(router, route);

const viewId = computed({
  get() {
    return props.settings.customSettings?.viewId ?? props.previewViewId;
  },
  set(val) {
    if (!val) return;
    if (val == props.settings?.customSettings?.viewId) return;
    const clone = structuredClone(props.settings);
    clone.customSettings ??= {};
    clone.customSettings.viewId = val.value;
    emit("update:settings", clone, props.id);
  },
});

const viewsList = computed(() => {
  return viewsStore.getAllViews.map((m) => ({ value: m.id, label: m.name }));
});

const restriction = computed({
  get() {
    if (!props.settings?.customSettings.dateRestriction)
      return dateRestriction.value;
    return props.settings.customSettings.dateRestriction;
  },
  set(val) {
    if (val.isRelative) {
      if (!val.timeFrame) val.timeFrame = "last7days";
    } else {
      if (val.timeFrame) {
        const report = reportsStore.getById(reportId.value);
        val = {
          ...val,
          ...DateTimeUtils.getTimeFrame(
            val.timeFrame,
            userStore.timeZone,
            report
          ),
        };
      }
    }

    const clone = structuredClone(props.settings);
    clone.customSettings.dateRestriction = val;
    emit("update:settings", clone, props.id);
  },
});

const restrictionOpts = computed(() => {
  const timeframes = TimeFrame.ToArray().map((m) => ({
    label: t(`general.time.${m.value}`),
    isRelative: true,
    ...m,
  }));
  timeframes.shift(); // removes "All"
  return timeframes;
});

const selectingCustomDate = computed(
  () => !props.settings.dateRestriction?.isRelative
);

const defaultTimeFrame = {
  timeFrame: "today",
  isRelative: false,
  end: DateTime.now().endOf("day"),
  start: DateTime.now().minus({ weeks: 1 }).startOf("day"),
};
const selectedDate = computed(() => {
  if (
    !props.settings.dateRestriction?.end ||
    !props.settings.dateRestriction?.start
  )
    return {
      start: defaultTimeFrame.start,
      end: defaultTimeFrame.end,
    };
  else
    return {
      start: props.settings.dateRestriction.start,
      end: props.settings.dateRestriction.end,
    };
});

const maxResults = computed({
  get() {
    return props.settings?.maxNumDocs;
  },
  set(val) {
    if (!val) return;
    if (val == props.settings?.maxNumDocs) return;
    const clone = structuredClone(props.settings);
    clone.maxNumDocs = val;
    emit("update:settings", clone, props.id);
  },
});

const aggregateDuplicates = computed({
  get() {
    if (
      props.settings?.groupDuplicates == null ||
      props.settings?.groupDuplicates == undefined
    )
      return true;
    return props.settings?.groupDuplicates;
  },
  set(val) {
    if (val == props.settings?.groupDuplicates) return;
    const clone = structuredClone(props.settings);
    clone.groupDuplicates = val;
    emit("update:settings", clone, props.id);
  },
});

const type = computed({
  get() {
    return props.settings?.subset;
  },
  set(val) {
    if (!val) return;
    if (val == props.settings?.subset) return;
    const clone = structuredClone(props.settings);
    clone.subset = val.value;
    emit("update:settings", clone, props.id);
  },
});
const typeOpts = computed(() => {
  return [
    { value: "latest", label: t("views.reports.toolbox.articles_latest") },
    { value: "saved", label: t("views.reports.toolbox.articles_saved") },
  ];
});

const preview = computed(() => props.settings?.preview);
const articlesPreview = computed(() => {
  const p = preview.value;

  if (p.thumbnail && p.feed && p.date) {
    return "thumbnail-title-feed-date";
  } else if (p.thumbnail && p.feed) {
    return "thumbnail-title-feed";
  } else if (p.thumbnail && p.date) {
    return "thumbnail-title-date";
  } else if (p.thumbnail) {
    return "thumbnail-title";
  } else if (p.feed && p.date) {
    return "title-feed-date";
  } else if (p.feed) {
    return "title-feed";
  } else if (p.date) {
    return "title-date";
  } else {
    return "title";
  }
});
const previews = ref(["title", "feed", "date", "thumbnail"]);
const togglePreview = (val) => {
  const s = { ...props.settings };
  s.preview[val] = !s.preview[val];
  emit("update:settings", s, props.id);
};

const getButtonType = (val) => {
  if (preview.value && preview.value[val]) return "contained";
  else return "outlined";
};

const elementCustomSettings = computed({
  get() {
    return props.settings?.customSettings || false;
  },
  set(val) {
    const s = { ...props.settings };
    if (!val) {
      s.customSettings = null;
    } else {
      s.customSettings ??= {
        dateRestriction: { ...dateRestriction.value, reportId: reportId.value },
      };
      s.customSettings.viewId ??= viewId.value;
    }
    emit("update:settings", s, props.id);
  },
});
</script>

<style scoped lang="scss">
.m-toolbox {
  #toolbox_icon_preview {
    cursor: default;

    :deep(svg:hover) {
      stroke-width: unset;
      stroke: unset;
    }

    :deep(*) {
      cursor: default;
    }
  }

  &__preview {
    margin-top: $spacing-2;
    @include flex(center, center, row);
    flex-wrap: wrap;
    gap: $spacing-2;
  }

  &__option:hover {
    border-color: transparent;
    box-shadow: none;
    cursor: default;

    * {
      cursor: default;
    }
  }
}
</style>
