export default (app) => {
  // v-focus
  app.directive("focus", {
    mounted(el) {
      // console.log("running v-focus on: " + el.id);
      const height = el.getBoundingClientRect().height;
      const y = el.getBoundingClientRect().y;

      const documentNodes = document?.querySelectorAll(
        '[data-autofocus="true"]'
      );

      const modal = document?.getElementsByClassName("m-modal")[0];
      const modalNodes = modal?.querySelectorAll('[data-autofocus="true"]');

      // if it is a modal it should focus on the first visible input
      // otherwise it should focus on the first visible input
      // we are passing an attribute to the html element called data-autofocus that tells us if we want to autofocus or not on that element
      if (
        (modal && modalNodes[0]?.id == el.id) ||
        (y + height <= window.innerHeight && documentNodes[0]?.id == el.id)
      ) {
        // console.log(el);
        el.focus();
      }
    },
  });
};
