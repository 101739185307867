import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";

export default () => {
  const { open, close, cancel } = useModal("m-modal");

  const handleModal = () => {
    open("m_modal_update", {
      width: 3,
      disabled: false,
      buttons: ["custom"],
    });
  };

  return {
    open: handleModal,
    close,
    cancel,
  };
};
