<template>
  <div
    :id="`m_toggle_${id}`"
    :data-tooltip-content="tooltip?.content ?? tooltip"
    :data-tooltip-posititon="tooltip?.position ?? 'dynamic'"
    class="m-toggle"
    :class="{
      'm-toggle--small': size == 'small',
      'm-toggle--disabled': disabled,
    }"
    @click="toggle"
  >
    <component
      :is="modelValue ? 'h4' : 'h6'"
      :class="{
        'type--empty': !modelValue,
        'type--small': size == 'small',
      }"
    >
      {{ label }}
    </component>
    <div
      :data-test="`test_m_toggle_${id}`"
      tabindex="0"
      role="button"
      class="m-toggle__track"
      :class="`m-toggle--${state}`"
      @keydown.enter="toggle"
    >
      <div class="m-toggle__thumb"></div>
    </div>
  </div>
</template>

<script setup>
/*
 * Monitio Toggle component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/617840767/Forms#Setup.3
 */

import { ref, computed, watch } from "vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  modelValue: { type: Boolean },
  size: { type: String, default: "default" },
  label: { type: String, required: true },
  tooltip: { type: [Object, String] },
  disabled: { type: Boolean, default: false },
});

const emit = defineEmits(["update:modelValue"]);

watch(
  () => props,
  () => {
    // size validation
    if (!["default", "small", "xsmall"].includes(props.size)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "size": expected string with value "default", "small" or "xsmall" and got "${props.size}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/617840767/Forms#Setup.2 for instructions on how to use the MToggle component.`
      );
    }

    // tooltip validator
    if (props.tooltip && typeof props.tooltip == "object") {
      if (
        props.tooltip?.content === undefined ||
        props.tooltip?.content === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "content" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/617840767/Forms#Setup.2 for instructions on how to use the MToggle component'
        );
      }
      if (
        props.tooltip?.position === undefined ||
        props.tooltip?.position === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "position" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/617840767/Forms#Setup.2 for instructions on how to use the MToggle component'
        );
      } else if (
        ![
          "dynamic",
          "top-left",
          "top-center",
          "top-right",
          "left",
          "right",
          "bottom-left",
          "bottom-center",
          "bottom-right",
        ].includes(props.tooltip?.position)
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "tooltip": expected attribute "position" with value "dynamic", "top-left", "top-center", "top-right", "left", "right", "bottom-left", "bottom-center" or "bottom-right", and got "${props.tooltip?.position}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
        );
      }
    }
  },
  { immediate: true }
);

const state = computed(() => {
  if (props.modelValue) return "active";
  else return "inactive";
});

const toggle = () => {
  if (!props.disabled) emit("update:modelValue", !props.modelValue);
};
</script>

<style scoped lang="scss">
.m-toggle {
  @include flex(space-between, center, row);

  &--disabled {
    @include opacity-disabled;
    cursor: default;
  }

  &__track {
    width: calc($spacing-4 * 2.5);
    padding: $spacing-1;
    @include round-corners($spacing-4);
    cursor: pointer;

    * {
      cursor: pointer;
    }
  }

  &__thumb {
    width: $spacing-4;
    height: $spacing-4;
    @include round-corners;
    @include elevate-button;
    transition: transform 0.3s ease-in-out;
  }

  &--small .m-toggle__track {
    width: calc($spacing-3 * 3);
  }

  &--small .m-toggle__thumb {
    width: $spacing-3;
    height: $spacing-3;
  }

  &--active {
    background-color: color($pri-action-base, 0.9);

    .m-toggle__thumb {
      transform: translateX($spacing-4);
      background-color: color($white);
    }
  }

  &--inactive {
    background-color: color($pri-action-inactive, 0.35);

    &:hover {
      background-color: color($pri-action-light);
    }

    .m-toggle__thumb {
      transform: translateX($spacing-0);
      background-color: color($white);
    }
  }
}
</style>
