<template>
  <div
    :id="`m_module_${id}`"
    class="m-report__ckeditor"
    :class="editor ? 'm-report--editor' : 'm-report--preview'"
    data-tour="tour_report_m_module"
    @click="select"
  >
    <m-ckeditor
      v-if="editor && isSelected"
      v-model="editorData"
      :config="{ startupFocus: true }"
    />
    <div
      v-else-if="settings.content"
      v-html="editorData"
      class="m-report__text"
    ></div>
    <div v-if="editor" class="m-report__actions">
      <m-icon
        v-if="!isSelected"
        id="m_report_edit"
        :tooltip="t('general.buttons.edit')"
        icon="edit"
        variant="secondary"
        size="small"
        hover="weight"
        @click="select"
      />
      <m-icon
        id="m_report_remove"
        :tooltip="t('general.buttons.remove')"
        icon="delete"
        variant="secondary"
        size="small"
        hover="weight"
        @click="remove"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import MCkeditor from "@components/MCkeditor.vue";
import { debounce } from "lodash-es";
import MIcon from "@components/MIcon.vue";
import structuredClone from "@utils/structuredClone";
import { useReportsStore } from "@root/store/modules/reports";

const props = defineProps({
  id: { type: [String, Number] },
  settings: { type: Object },
  isSelected: { type: Boolean },
});

const emit = defineEmits([
  "update:settings",
  "select-element",
  "remove-element",
]);

const { t } = useI18n();
const reportsStore = useReportsStore();
const route = useRoute();
const editor = computed(() => route.name.toLowerCase().includes("builder"));

const editorData = ref(
  props.settings?.content || t("views.reports.toolbox.placeholderText")
);

watch(
  () => editorData.value,
  (val) => {
    if (!val) return;
    if (val == props.settings?.content) return;
    const clone = structuredClone(props.settings);
    clone.content = val;
    updateSettings(clone, props.id);
    emit("update:settings", clone, props.id);
  }
);
const updateSettings = debounce((settings, id) => {
  emit("update:settings", settings, props.id);
}, 1000);

const select = () => {
  emit("select-element", props.id);
};

const remove = () => {
  emit("remove-element", props.id);
};
</script>

<style scoped lang="scss">
.m-report--editor {
  display: block !important; //For CkEditor its best for this to be a block

  .m-report__text {
    padding: $spacing-6;
    padding-left: $spacing-6;
    padding-right: $spacing-6;
  }
}

.m-report__text {
  width: 100%;
  min-height: 69px;
  font-family: "Roboto", "Helvetica", arial, sans-serif;
  text-align: left;
}
</style>
