export default (app) => {
  // v-click-outside

  app.directive("click-outside", {
    mounted(el, binding) {
      el.clickOutsideEvent = (event) => {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      };
      setTimeout(() => {
        document.body.addEventListener("click", el.clickOutsideEvent);
      }, 200);
    },
    unmounted(el) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
    },
  });
};
