<template>
  <div class="m-modal__body" data-test="m_modal_create_team">
    <m-input
      id="create_team_name"
      v-model="team.name"
      type="text"
      :label="t('settings.teams.createTeam.name')"
      :placeholder="t('settings.teams.createTeam.namePlaceholder')"
      required
      :validation="validateTeamName"
      class="pb-3"
    />
    <div class="pt-3 pb-6 m-create-team__add">
      <label>
        {{ t("settings.teams.createTeam.teammates") }}
      </label>
      <m-search
        id="m_create_team_add"
        :options="availableUsers"
        :placeholder="t('settings.teams.createTeam.teammatesPlaceholder')"
        type="autocomplete"
        required
        class="mt-1"
        @select="add"
      />
    </div>
    <div class="m-create-team__teammates">
      <h5 class="type--small">
        {{ t("settings.teams.createTeam.teammatesList") }}
      </h5>
      <div
        class="m-teammates__list"
        :class="{ 'm-teammates__list--empty': team.users.length == 0 }"
      >
        <h6 v-if="team.users.length == 0" class="type--small type--empty">
          {{ t("settings.teams.createTeam.teammatesEmpty") }}
        </h6>
        <div
          v-for="(user, i) in team.users"
          :key="i"
          class="m-teammates__user m-clickable"
          @mouseenter="hover = user.id"
          @mouseleave="hover = undefined"
        >
          <m-user-badge :user="user" type="badge" />
          <m-icon
            v-if="hover == user.id"
            id="m_team_remove"
            icon="close"
            size="small"
            @click="remove(user)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="m-modal__footer">
    <m-button
      v-if="slotProps.cancel"
      id="m_modal_cancel"
      :label="t('general.buttons.cancel')"
      type="text"
      variant="terciary"
      class="mt-3"
      @click="cancel"
    />
    <m-button
      v-if="slotProps.cancel"
      id="m_modal_continue"
      :label="t('settings.teams.createTeam.action')"
      type="contained"
      variant="primary"
      class="mt-3 ml-6"
      :disabled="disabled"
      @click="create"
    />
  </div>
</template>

<script setup>
import { ref, reactive, computed } from "vue";
import { useI18n } from "vue-i18n";

import MSearch from "@components/MSearch.vue";
import MUserBadge from "@components/MUserBadge.vue";
import MIcon from "@components/MIcon.vue";
import MInput from "@components/MInput.vue";
import MButton from "@components/MButton.vue";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const props = defineProps({
  slotProps: Object,
});

const { t } = useI18n();
const userStore = useUserStore();
const alertsStore = useAlertsStore();
const workspacesStore = useWorkspacesStore();

const hover = ref(undefined);

const team = reactive({
  name: "",
  description: "",
  users: [],
});

const validateTeamName = computed(() => {
  if (team.name == "") {
    return {
      type: "error",
      label: t("settings.teams.createTeam.noTeamName"),
    };
  }
  return null;
});

const availableUsers = computed(() => {
  const available =
    workspacesStore.currentWorkspaceConfig?.workspaceUsers
      .filter((x) => x.active)
      .filter((x) => x.id != userStore.details.id)
      .map((m) => ({
        value: m.id,
        label: `${m.accountDetails.firstName} ${m.accountDetails.lastName}`,
        type: "user",
        ...m,
      })) || [];

  team.users?.forEach((fe) => {
    const idx = available.findIndex((f) => f.id == fe.id);
    if (idx != -1) available.splice(idx, 1);
  });

  return available;
});

const add = (val) => {
  team.users.push(val);
};

const remove = (val) => {
  const idx = team.users.findIndex((f) => f.id == val.id);
  team.users.splice(idx, 1);
};

const disabled = computed(() => team.name == "" || team.users.length == 0);

const clearFields = () => {
  team.name = "";
  team.description = "";
  team.users = [];
};

const create = () => {
  userStore.createTeam(team.users, team.description, team.name);
  alertsStore.add({
    type: "toast",
    variant: "success",
    message: t("general.alerts.toast.createdTeam", { name: team.name }),
  });
  clearFields();
  props.slotProps.close();
};

const cancel = () => {
  clearFields();
  props.slotProps.close();
};
</script>

<style scoped lang="scss">
.m-teammates {
  &__list {
    height: 22vh;
    padding: $spacing-1;
    @include round-corners($spacing-1);
    @include elevate-inset;
    overflow-y: auto;

    &--empty {
      @include flex(center, center, row);
    }
  }

  &__user {
    padding: $spacing-1 $spacing-2;
    @include flex(space-between, center, row);
    gap: $spacing-3;

    &:not(:last-child) {
      margin-bottom: $spacing-1;
    }
  }
}
</style>
