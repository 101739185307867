<template>
  <div v-if="queue.length" class="m-toast__wrapper">
    <m-toast v-for="(toast, i) in queue" :key="i" :toast="toast" />
  </div>
</template>

<script setup>
import { computed } from "vue";

import MToast from "@components/alerts/MToast.vue";
import { useAlertsStore } from "@root/store/modules/alerts";

const alertsStore = useAlertsStore();
const queue = computed(() => alertsStore.toast);
</script>

<style scoped lang="scss">
.m-toast__wrapper {
  max-height: calc(100vh - $header-height - $spacing-2);
  padding: $spacing-2 $spacing-3 $spacing-7 $spacing-5;
  @include flex(center, flex-end, column-reverse);
  gap: $spacing-3;
  position: absolute;
  top: $header-height + $spacing-2;
  right: $spacing-0;
  z-index: $z-notification;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
