<template>
  <div class="m-modal__body" data-test="m_modal_create_view">
    <m-picture-upload
      id="create_view"
      :label="t('createView.thumbnail')"
      :imageURL="view.details.image"
      :custom-upload-function="uploadPicture"
      @upload="uploadedPicture"
    />
    <m-input
      id="create_view_name"
      v-model="view.name"
      type="text"
      :label="t('createView.name')"
      :placeholder="t('createView.name')"
      :validation="validateViewName"
      maxlength="32"
      required
      class="mt-3 mb-8"
      @resolve="finishedEditing"
    />

    <m-textarea
      id="create_view_description"
      v-model="view.details.description"
      type="text"
      :label="t('createView.description')"
      :placeholder="t('createView.description')"
      class="mb-3"
    />
    <div v-if="hasDefinedFilters" class="m-modal__filters">
      <div class="m-filters__header">
        <label class="mt-4 mb-1">
          {{ t("createView.filters") }}
        </label>
        <m-icon
          :tooltip="infoTooltip"
          icon="info"
          variant="terciary"
          size="small"
        />
      </div>
      <div class="m-filters__entities">
        <m-filter-badge
          v-for="(filter, i) in viewFilters"
          :key="i"
          :id="`badge_${i}`"
          :filter="filter"
        />
      </div>
    </div>
  </div>
  <div class="m-modal__footer">
    <m-button
      v-if="slotProps.cancel"
      id="m_modal_cancel"
      :label="t('general.buttons.cancel')"
      type="text"
      variant="terciary"
      class="mt-3"
      @click="cancel"
    />
    <m-button
      id="m_modal_continue"
      :label="t('createView.buttons_resolve')"
      type="contained"
      variant="primary"
      class="mt-3 ml-6"
      :disabled="disabled"
      :data-tooltip-content="disabled ? t('createView.addViewName') : undefined"
      data-tooltip-position="dynamic"
      @click="create"
    />
  </div>
  <!-- <modal-regist name="m_dialog_tour_create_view"></modal-regist> -->
</template>

<script setup>
import { reactive, computed, onMounted, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import { useRouter, useRoute } from "vue-router";
import MIcon from "@components/MIcon.vue";
import MInput from "@components/MInput.vue";
import MButton from "@components/MButton.vue";
import MFilterBadge from "@components/MFilterBadge.vue";
import MTextarea from "@components/MTextarea.vue";
import MPictureUpload from "@components/MPictureUpload.vue";
import Guid from "@utils/guid";
import structuredClone from "@utils/structuredClone";
import { checkForWikiImage } from "@utils/utils";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { cloneDeep } from "lodash-es";

const props = defineProps({
  /** @description {mode, value, articleLang, articleIptcs} */
  slotProps: Object,
});

const { t } = useI18n();
const { api } = useApi();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const alertsStore = useAlertsStore();
const workspacesStore = useWorkspacesStore();
const { push } = useRouter();
const route = useRoute();

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);
const viewFilters = ref([]);

const view = reactive({
  id: Guid.NewGuid(),
  name: "",
  workspaceId: workspacesStore.id,
  details: {
    filters: [],
    image: undefined,
    description: "",
  },
});

onMounted(() => {
  const entities = [];
  let entity;

  viewsStore.filters.forEach((filter) => {
    filter.facets.forEach((facet) => {
      if (facet.query.length > 0) {
        facet.query.forEach((queryItem) => {
          entity = { type: "", label: "", image: null, negated: null };
          if (queryItem?.label) {
            entity.label = queryItem.label;
            entity.type = facet.value;
            entity.negated = queryItem.negated;
            if (props.slotProps.type === "fromStory") {
              if (queryItem.thumbnail) {
                entity.image = queryItem.thumbnail;
              }
            }

            entities.push(entity);
          }
        });
      }
    });
  });

  viewFilters.value = entities;
});

watch(
  () => viewFilters.value,
  async (val) => {
    if (val) {
      for (const filter of viewFilters.value) {
        if (props.slotProps.type === "fromStory") {
          if (filter.image) {
            uploadPicture(filter.image);
          }
        } else {
          await checkForWikiImage(
            userLang.value,
            filter.label,
            filter.label
          ).then((data) => {
            filter.image = data.image ?? undefined;
          });
        }
      }
    }
  },
  { immediate: true }
);

const currentViewNames = computed(() => {
  return viewsStore.getAllViews
    .concat(viewsStore.draftViews)
    .map((x) => x.name);
});

const validateViewName = computed(() => {
  if (currentViewNames.value?.includes(view.name)) {
    return {
      type: "error",
      label: t("createView.viewNameAlreadyExists"),
    };
  }
  return null;
});

const userLocale = computed(() => userStore.i18nLanguage);
const userLang = computed(() => userLocale.value.split("-")[0]);

/** If there's empty fields disable the buttons */
const disabled = computed(() => {
  return view.name == "" || view.name == " " || validateViewName.value;
});

const uploadPicture = (val) => {
  // Should be the blob URL
  return api.views.uploadPicture(view.id, val);
};

const uploadedPicture = (val) => {
  // Should be the blob URL
  view.details.image = val;
};

const clearFields = () => {
  // eslint-disable-next-line no-extra-semi
  (view.id = Guid.NewGuid()), (view.name = "");
  view.details.description = "";
  view.details.image = undefined;
};

const hasDefinedFilters = computed(() => {
  return viewsStore.filters.length !== 0;
});

const create = async () => {
  const viewName = view.name;

  const clone = structuredClone(view);

  if (hasDefinedFilters.value) {
    clone.details.filters = viewsStore.filters;

    try {
      await viewsStore.saveView(clone, viewId.value);
      viewsStore.setFilters([]);
      push(`/views/${clone.id}/tiles`);
    } catch (error) {
      console.error("Couldn't create view");
    }
  } else {
    await viewsStore.addDraftView(clone, viewId.value);
    push({
      name: "createManageFilters",
      params: {
        viewId: viewId.value,
        tempViewId: clone.id,
      },
    });
  }

  clearFields();
  props.slotProps.close();

  alertsStore.add({
    type: "toast",
    variant: "success",
    message: t("general.alerts.toast.createdView", { name: viewName }),
  });
};

const cancel = async () => {
  viewsStore.removeDraftViewById(view.id);
  props.slotProps.cancel();
};

const finishedEditing = () => {
  if (!disabled.value) {
    create();
  }
};

const infoTooltip = computed(() => {
  return {
    content: t("createView.filters_info"),
    position: "left",
  };
});
</script>

<style scoped lang="scss">
.m-modal__body {
  @include flex(flex-start, stretch, column);

  .m-filters {
    &__entities {
      height: 10vh;
      padding: $spacing-2;
      @include round-corners($spacing-1);
      @include flex(flex-start, stretch, column);
      gap: $spacing-1;
      @include elevate-inset;
      overflow-y: auto;
    }

    &__header {
      @include flex(space-between, center, row);

      .m-icon--info {
        margin-top: auto;
        margin-bottom: $spacing-1;
      }
    }
  }

  :deep(.m-textarea__content textarea) {
    min-height: $spacing-9;
  }
}
.m-hint {
  transform: translate(3px, 25px) !important;
  z-index: 1;
}
</style>
