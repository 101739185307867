/**
 * Central file that imports every custom directive and executes it.
 * This is executed in the main.js file.
 *
 * To add new directives just make a file in the directives folder, this
 * file needs to export a default function that will receive the app itself.
 * Then just import it here and execute it, passing the app.
 */

import validationMessageDirective from "@directives/validationMessage.js";
import focusDirective from "@directives/focus";
import clickOutsideDirective from "@directives/clickOutside";

export default (app) => {
  validationMessageDirective(app);
  focusDirective(app);
  clickOutsideDirective(app);
};
