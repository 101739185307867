<template>
  <div :id="`m_textarea_${id}`" class="m-textarea__wrapper">
    <div
      class="m-textarea__heading"
      :class="
        label
          ? 'm-textarea__heading--space-between'
          : 'm-textarea__heading--flex-end'
      "
    >
      <label v-if="label" :for="id" :class="{ 'type--small': size == 'small' }">
        {{ label }}
      </label>
      <m-icon
        v-if="info"
        :tooltip="getTooltip(info)"
        icon="info"
        :size="size"
      />
      <m-icon
        v-if="required"
        :tooltip="getTooltip(t('general.forms_textarea_required'))"
        icon="required"
        :size="size"
      />
    </div>
    <div
      class="m-textarea__content pa-0"
      :class="[
        `m-textarea__content--${size}`,
        validationMessage?.type
          ? `m-textarea__content--${validationMessage?.type}`
          : '',
      ]"
    >
      <textarea
        ref="textareaRef"
        :id="id"
        :data-test="`test_m_textarea_${id}`"
        v-model="value"
        :placeholder="placeholder"
        class="py-1 px-4"
        :class="`type--${size}`"
        :readonly="disabled"
        autocomplete="off"
        @keydown.stop="validate"
        @keydown.enter.stop="resolve"
        @keydown.esc.stop="finishedEditing"
        @blur="finishedEditing"
      ></textarea>
    </div>
    <p
      v-if="validationMessage"
      class="m-textarea__validation"
      :class="`type--${validationMessage.type}`"
    >
      {{ validationMessage.label }}
    </p>
  </div>
</template>

<script>
/*
 * Monitio Textarea component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/666632193/Textarea
 */

import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import MIcon from "@components/MIcon.vue";
import { debounce } from "lodash-es";

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  components: { MIcon },
  props: {
    id: {
      type: String,
      required: true,
      validator(id) {
        if (id.match(/[\s-]/g)) {
          console.error(
            `Invalid attribute "id": string "${id}" has to be in snake_case.`
          );
        }
        return true;
      },
    },
    modelValue: { type: [Number, String] },
    size: {
      type: String,
      default: "default",
      validator(size) {
        if (!["default", "small", "xsmall"].includes(size)) {
          console.error(
            `Invalid prop "size": expected string with value "default", "small" or "xsmall" and got "${size}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/666632193/Textarea for instructions on how to use the MInput component.`
          );
        }
        return true;
      },
    },
    label: { type: String },
    placeholder: { type: String, required: true },
    info: { type: String },
    required: { type: Boolean, default: false },
    validation: { type: [Function, Object] },
    disabled: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "is-valid", "resolve", "blur"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const textareaRef = ref(null);
    const value = ref(props.modelValue);
    const validationMessage = ref({ type: null, label: null });

    const getTooltip = (content) => {
      return {
        content: content,
        position: "dynamic",
      };
    };

    watch(
      () => value.value,
      (val, oldVal) => {
        if (val != oldVal) {
          emit("update:modelValue", val);
          const vm = validationMessage.value;
          vm.type = null;
          vm.label = null;
        }
      }
    );

    watch(
      () => validationMessage.value,
      (val) => {
        emit("is-valid", val.type == null ? true : false);
      },
      { immediate: true }
    );

    const validate = debounce(() => {
      if (typeof props.validation == "function") {
        const message = props.validation();
        const vm = validationMessage.value;
        vm.type = message.type;
        vm.label = message.label;
      } else if (typeof props.validation == "object") {
        const vm = validationMessage.value;
        vm.type = props.validation.type;
        vm.label = props.validation.label;
      }
    }, 1000);

    const resolve = () => {
      finishedEditing();
      emit("resolve", value.value);
    };

    const finishedEditing = (evt) => {
      if (typeof props.validation == "function") {
        const message = props.validation();
        const vm = validationMessage.value;
        vm.type = message.type;
        vm.label = message.label;
      } else if (typeof props.validation == "object") {
        const vm = validationMessage.value;
        vm.type = props.validation.type;
        vm.label = props.validation.label;
      }

      textareaRef.value.blur();
      emit("blur", value.value);
    };

    return {
      textareaRef,
      value,
      getTooltip,
      finishedEditing,
      validationMessage,
      validate,
      resolve,
    };
  },
};
</script>

<style scoped lang="scss">
::-webkit-inner-spin-button {
  display: none;
}
</style>
