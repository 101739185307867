import ApplyCustomDirectives from "@directives/index";
import { createApp } from "vue";
import App from "@root/App.vue";
import { setupI18n } from "@root/i18n";
import { registSignalR } from "@hubs/main";
import * as Sentry from "@sentry/vue";
import { createTransport } from "@sentry/core";

import storage from "@utils/tokenStorage";

import router from "./router";
import { createPinia } from "pinia";
import MTextFormatter from "@components/MTextFormatter.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueGridLayout from "vue-grid-layout";
import VCalendar from "v-calendar";
import VueTippy from "vue-tippy";

import MModalBase from "@components/modals/MBase.vue";
import MDialogBase from "@components/alerts/MDialog.vue";

import rmHeading from "@components/report/modules/MModuleHeading.vue";
import rmImage from "@components/report/modules/MModuleImage.vue";
import rmDivider from "@components/report/modules/MModuleDivider.vue";
import rmHtml from "@components/report/modules/MModuleHtml.vue";
import rmArticles from "@components/report/modules/MModuleArticles.vue";
import rmDashboards from "@components/report/modules/MModuleDashboards.vue";
import rmClusters from "@components/report/modules/MModuleClusters.vue";

import rtHeading from "@components/report/toolbox/MToolboxHeading.vue";
import rtImage from "@components/report/toolbox/MToolboxImage.vue";
import rtDivider from "@components/report/toolbox/MToolboxDivider.vue";
import rtHtml from "@components/report/toolbox/MToolboxHtml.vue";
import rtArticles from "@components/report/toolbox/MToolboxArticles.vue";
import rtDashboards from "@components/report/toolbox/MToolboxDashboards.vue";
import rtClusters from "@components/report/toolbox/MToolboxClusters.vue";

console.log(
  `%c Monitio %c`,
  "background:hsla(216, 30%, 91%, 1) ; padding: 1px; border-radius: 2px; color: #2b4473",
  "background:transparent"
);

if (window._env_.DEPLOYMENT_MODE == "DEVELOPMENT") {
  const community = window._env_.VUE_APP_apiUrl.includes("community");
  console.log(
    `%c Backend: ${community ? "Community" : window._env_.VUE_APP_apiUrl} `,
    "background:hsla(216, 30%, 91%, 1) ; padding: 1px; border-radius: 2px; color: #2b4473"
  );
}

setupI18n().then((i18n) => {
  const app = createApp(App);
  const pinia = createPinia();
  registSignalR(app);

  app.mixin({
    methods: {
      $toTitleCase: (str) =>
        (str && str.toTitleCase && str.toTitleCase()) || "",
    },
  });

  if (window._env_.DEPLOYMENT_MODE != "DEVELOPMENT") {
    console.debug(
      `%c Initializing Sentry...`,
      "background:hsla(216, 30%, 91%, 1) ; padding: 1px; border-radius: 2px; color: #2b4473"
    );
    Sentry.init({
      app,
      //tunnel: "/api/v1/sentry/log",
      dsn: window._env_.SENTRY_DSN,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      integrations: [Sentry.replayIntegration()],
      // Inject monitio bearer token here. Note there's no token before the users logs in, so those request will error
      transport: (options) => {
        function makeRequest(request) {
          options.headers ??= {};
          if (storage?.jwtToken?.token)
            options.headers[
              "Authorization"
            ] = `Bearer ${storage.jwtToken.token}`;
          const requestOptions = {
            body: request.body,
            method: "POST",
            referrerPolicy: "origin",
            headers: options.headers,
            ...options.fetchOptions,
          };

          return fetch(options.url, requestOptions);
        }

        return createTransport(options, makeRequest);
      },
    });
  }

  app.config.productionTip = false;
  /* Regist report modules globally */
  app.component("rmHeading", rmHeading);
  app.component("rmImage", rmImage);
  app.component("rmArticles", rmArticles);
  app.component("rmDivider", rmDivider);
  app.component("rmHtml", rmHtml);
  app.component("rmDashboards", rmDashboards);
  app.component("rmClusters", rmClusters);

  /* Regist report toolbox modules globally */
  app.component("rtHeading", rtHeading);
  app.component("rtImage", rtImage);
  app.component("rtArticles", rtArticles);
  app.component("rtDivider", rtDivider);
  app.component("rtHtml", rtHtml);
  app.component("rtDashboards", rtDashboards);
  app.component("rtClusters", rtClusters);
  //#endregion

  //#region Custom Vue Libraries are registered here
  // MDatePicker
  app.use(VCalendar, {
    componentPrefix: "m",
    transition: "none",
    /*   masks: { Needs review but i think these settings slow down the calendar alot
      title: "MMMM YYYY",
      weekdays: "W",
      navMonths: "MMMM",
      input: "DD/MM/YY",
      dayPopover: "WWW, MMM D, YYYY",
      data: "DD/MM/YY",
    }, */
  });
  app.use(VueTippy, {
    directive: "tippy",
    component: "tippy",
  });
  app.use(CKEditor);
  //#endregion

  /**
   * Apply app's custom directives.
   * To add new directives please go to the src/directives/index
   * And read the file header
   */
  ApplyCustomDirectives(app);

  app.use(router);

  // Regist the store
  app.use(pinia);

  app.component("m-modal-layout", MModalBase);
  app.component("m-dialog-layout", MDialogBase);
  app.use(VueGridLayout);
  app.use(MTextFormatter);
  app.use(i18n);

  app.provide("tours", app.config.globalProperties.$tours);
  app.mount("#app");
});
