<template>
  <div class="m-modal__body">
    <!-- <m-image :src="about" class="m-about__image" /> -->
    <div class="m-about__content">
      <div class="mr-3 m-content__title">
        <p class="mb-2 h6 type--small type--nowrap">
          {{ t("about.ui_version") }}
        </p>
        <p class="h6 type--small type--nowrap">
          {{ t("about.platform_version") }}
        </p>
      </div>
      <div>
        <p class="mb-2 h5 type--small type--nowrap">{{ uiVersion }}</p>
        <p class="h5 type--small type--nowrap">{{ platformVersion }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import MImage from "@components/MImage.vue";
import about from "@assets/illustrations/about.svg";
import { useAppStore } from "@root/store/app";

const { t } = useI18n();
const appStore = useAppStore();
const uiVersion = computed(() => appStore.uiVersion);
const platformVersion = computed(() => appStore.platformVersion);
</script>

<style scoped lang="scss">
.m-about {
  &__content {
    @include flex(center, center, row);

    > div {
      min-width: ($spacing-14 * 2) + $spacing-1;
    }

    .m-content__title {
      text-align: right;
    }
  }

  &__image {
    width: 100%;
    transform: scale(1.2);
    pointer-events: none;
  }
}
</style>
