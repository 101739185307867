<template>
  <div
    :id="`m_toolbox_${id}`"
    data-tour="tour_m_toolbox_element"
    class="m-toolbox__module"
  >
    <h6 class="m-toolbox__title">
      {{ t("views.reports.toolbox.image") }}
    </h6>
    <div class="m-toolbox__tool">
      <!-- TODO image uploader 
      ? api.reportsmodule.uploadPicture(reader.result) -->
      <m-input
        id="m_toolbox_report_img"
        :modelValue="imgUrl"
        type="upload"
        :label="t('views.reports.toolbox.url')"
        :placeholder="t('views.reports.toolbox.url_placeholder')"
        size="small"
        @resolve="setImgUrl"
      />
    </div>
    <label class="mb-1 mx-3 type--small">
      {{ t("views.reports.toolbox.size") }}
    </label>
    <div class="m-toolbox__tool m-toolbox__size">
      <m-toolbox-icon
        v-for="(size, i) in sizes"
        :key="i"
        property="image-size"
        :icon="size"
        :selected="size == settings?.size"
        :label="t(`views.reports.toolbox.size_${size}`)"
        class="m-toolbox__option"
        :class="{
          'm-toolbox__option--selected': size == settings?.size,
        }"
        @click="change(size, 'size')"
      />
    </div>
    <label class="mb-1 mx-3 type--small">
      {{ t("views.reports.toolbox.ratio") }}
    </label>
    <div class="m-toolbox__tool m-toolbox__ratio">
      <m-toolbox-icon
        v-for="(ratio, i) in ratios"
        :key="i"
        property="image-ratio"
        :icon="ratio"
        :selected="ratio == settings?.style"
        :label="t(`views.reports.toolbox.ratio_${ratio}`)"
        class="m-toolbox__option"
        :class="{
          'm-toolbox__option--selected': ratio == settings?.style,
        }"
        @click="change(ratio, 'style')"
      />
    </div>
    <label class="mb-1 mx-3 type--small">
      {{ t("views.reports.toolbox.alignment") }}
    </label>
    <div class="m-toolbox__tool m-toolbox__alignment">
      <m-toolbox-icon
        v-for="(alignment, i) in alignments"
        :key="i"
        property="align"
        :icon="alignment"
        :selected="alignment == settings?.align"
        :label="t(`views.reports.toolbox.alignment_${alignment}`)"
        class="m-toolbox__option"
        :class="{
          'm-toolbox__option--selected': alignment == settings?.align,
        }"
        @click="change(alignment, 'align')"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import MInput from "@components/MInput.vue";
import MToolboxIcon from "@root/components/MToolboxIcon.vue";
import structuredClone from "@utils/structuredClone";

const props = defineProps({
  id: { type: [String, Number] },
  settings: { type: Object },
});

const emit = defineEmits(["update:settings"]);

const { t } = useI18n();

const imgUrl = computed(() => props.settings?.url || "");

const setImgUrl = (val) => {
  const clone = structuredClone(props.settings);
  if (typeof val == "string") clone.url = val;
  else {
    clone.url = val.url;
    clone.name = val.name;
  }
  emit("update:settings", clone, props.id);
};

const sizes = ref(["small", "medium", "large"]);
const ratios = ref(["source", "square", "cinema"]);
const alignments = ref(["left", "center", "right"]);

const change = (val, type) => {
  const clone = structuredClone(props.settings);
  clone[type] = val;
  emit("update:settings", clone, props.id);
};
</script>

<style scoped lang="scss">
.m-toolbox {
  &__size,
  &__ratio,
  &__alignment {
    @include flex(center, center, row);
    gap: $spacing-4;
  }
}
</style>
