import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import { useI18n } from "vue-i18n";
import { unref } from "vue";
import { useViewsStore } from "@root/store/modules/views";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

export default () => {
  const { open, close, cancel } = useModal("m-modal");
  const { t } = useI18n();
  const viewsStore = useViewsStore();
  const workspacesStore = useWorkspacesStore();

  const workspaceConfig = workspacesStore.currentWorkspaceConfig;

  const save = async (view, newView) => {
    const isDraftView = viewsStore.draftViews.find((x) => x.id == view.id)
      ? true
      : false;
    const clone = { ...view };
    clone.name = newView.name;
    if (!clone.details) {
      clone.details = {
        descripion: newView.description,
        image: newView.thumbnail,
      };
    } else {
      clone.details.descripion = newView.description;
      clone.details.image = newView.thumbnail;
    }

    if (isDraftView) {
      viewsStore.updateView(clone);
    } else {
      const id = await viewsStore.saveView(
        clone,
        workspaceConfig.baseViewId,
        false
      );
    }
  };

  const handleModal = (view) => {
    return new Promise((res, rej) => {
      open("m_modal_view_settings", {
        heading: t("views.settings.modalTitle"),
        description: t("views.settings.modalDescription"),
        width: 5,
        close,
        cancel,
        buttons: ["custom"],
        view,
      }).then(
        ({ reason }) => {
          save(unref(view), reason);
          res(reason);
        },
        (reason) => {
          // rejected
          rej(reason);
        }
      );
    });
  };

  return {
    open: handleModal,
    close,
    cancel,
  };
};
