<template>
  <div class="m-modal__body">
    <m-image :src="imageSrc" class="m-onboarding__image" />
    <div class="m-onboarding__header">
      <h2 class="mb-4">
        {{ t(`onboarding.slide0${activeSlide}.header`) }}
      </h2>
      <h6 class="mb-2">
        {{
          t(`onboarding.slide0${activeSlide}.description_firstLine`, {
            username: userStore.details.accountDetails.firstName,
          })
        }}
      </h6>
      <h6 v-if="activeSlide != numberOfSlides">
        {{ t(`onboarding.slide0${activeSlide}.description_secondLine`) }}
      </h6>
    </div>
    <div class="m-onboarding__status">
      <div
        v-for="(status, idx) in numberOfSlides"
        :key="idx"
        class="m-onboarding__circle mx-1"
        :class="{ 'm-onboarding__circle--active': idx == activeSlide - 1 }"
      ></div>
    </div>
  </div>
  <div class="m-modal__footer">
    <m-button
      id="m_onboarding_cancel"
      :label="cancelLabel"
      type="text"
      variant="terciary"
      size="block"
      class="mt-3"
      @click="cancelClick"
    />
    <m-button
      id="m_onboarding_continue"
      :label="continueLabel"
      type="contained"
      variant="primary"
      size="block"
      class="mt-3 ml-6"
      @click="continueClick"
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import { useI18n } from "vue-i18n";
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";

import MImage from "@components/MImage.vue";
import MButton from "@components/MButton.vue";

import onboarding_01 from "@assets/illustrations/onboarding_01.svg";
import onboarding_02 from "@assets/illustrations/onboarding_02.svg";
import onboarding_03 from "@assets/illustrations/onboarding_03.svg";
import onboarding_04 from "@assets/illustrations/onboarding_05.svg";

import useTour from "@hooks/useTour/useTour.js";
import { useUserStore } from "@root/store/modules/user";

const emit = defineEmits(["skip-tour"]);

const { t } = useI18n();
const userStore = useUserStore();
const { close, cancel } = useModal("m-modal");

const { createViewTour } = useTour();

const numberOfSlides = ref(4); // number of slides with status (circles)
const activeSlide = ref(1);

const imageSrc = computed(() => {
  switch (activeSlide.value) {
    case 1:
      return onboarding_01;
    case 2:
      return onboarding_02;
    case 3:
      return onboarding_03;
    case 4:
      return onboarding_04;
    default:
      return "";
  }
});

const startOnboarding = () => {
  userStore.startTour("onboarding");
  activeSlide.value++;
};

const previousCard = () => {
  activeSlide.value--;
};

const nextCard = () => {
  activeSlide.value++;
};

const onboardingCompleted = () => {
  userStore.completeTour("onboarding");
  // if (
  //   !userStore.checkCompletedTour("createView") &&
  //   userStore.checkCompletedTour("onboarding")
  // ) {
  //   createViewTour.start();
  //   userStore.startTour("createView");
  // }
  close();
};

const onboardingCanceled = () => {
  userStore.completeTour("onboarding");
  // emit("skip-tour");
  cancel();
};

const cancelLabel = computed(() => {
  if (activeSlide.value == 1) {
    return t("onboarding.buttons.skip");
  } else return t("onboarding.buttons.previous");
});

const cancelClick = () => {
  if (activeSlide.value == 1) {
    onboardingCanceled();
  } else previousCard();
};

const continueLabel = computed(() => {
  if (activeSlide.value == 1) {
    return t("onboarding.buttons.start");
  } else if (activeSlide.value == numberOfSlides.value) {
    return t("onboarding.buttons.explore");
  } else return t("onboarding.buttons.next");
});

const continueClick = () => {
  if (activeSlide.value == 1) {
    startOnboarding();
  } else if (activeSlide.value == numberOfSlides.value) {
    onboardingCompleted();
  } else nextCard();
};

// onMounted(() => {
//   document.getElementById("m_button_m_onboarding_continue").focus();
// });
</script>

<style scoped lang="scss">
.m-load-image {
  width: 100%;
  height: 500px;
  background-color: color($sec-400);
}
.m-onboarding {
  &__image {
    width: 100%;
    transform: scale(1.1);
    pointer-events: none;
  }

  &__status {
    @include flex(center, center, row);
  }

  &__header {
    height: 150px;
  }

  &__circle {
    width: 6px;
    height: 6px;
    margin-top: $spacing-6;
    @include round-corners;
    background: color($pri-action-inactive, 0.3);

    &--active {
      background-color: color($pri-action-inactive);
    }
  }

  &__grid {
    @include flex(center, center, row);
    flex-wrap: wrap;
    gap: $spacing-6;
  }

  &__view {
    width: calc(50% - ($spacing-6 / 2));
    height: 80px;
    @include round-corners($spacing-1);
    @include flex(center, center, row);
    position: relative;
    background-color: color($pri-action-inactive, 0.3);
    overflow: hidden;

    .m-view__overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(
        180deg,
        color($white, 0) 0%,
        color($pri-dark) 100%
      );
      @include opacity-inactive;
    }

    h4 {
      position: absolute;
      bottom: $spacing-4;
      color: color($white);
      @include ellipsis;
    }

    &:hover .m-view__overlay {
      cursor: pointer;
      @include opacity-active;
    }

    &--selected {
      box-shadow: 0px 0px 0px 2px color($white),
        0px 0px 0px 3px color($pri-action);
    }
  }

  &__footer {
    margin-top: $spacing-5;
    @include flex(flex-end, center, row);
  }
}
</style>
