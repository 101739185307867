<template>
  <div class="m-modal__body">
    <m-image :src="update_version" class="m-update__image" />
    <div class="m-update__header">
      <h2 class="mb-4">
        {{ t("update.title") }}
      </h2>
      <h6 class="mb-2">
        {{ t("update.description") }}
      </h6>
    </div>
  </div>
  <div class="m-modal__footer">
    <m-button
      id="m_update_continue"
      :label="t('update.buttons_update')"
      type="contained"
      variant="primary"
      size="block"
      class="mt-3"
      @click="continueClick"
    />
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

import MImage from "@components/MImage.vue";
import MButton from "@components/MButton.vue";

import update_version from "@assets/illustrations/update_version.svg";

const props = defineProps({
  /** @description {mode, value, articleLang, articleIptcs} */
  slotProps: Object,
  name: String,
});

const { t } = useI18n();

const continueClick = () => {
  window.location.reload(true);
};
</script>

<style scoped lang="scss">
.m-update {
  &__image {
    width: 100%;
    pointer-events: none;
  }

  &__footer {
    margin-top: $spacing-5;
    @include flex(flex-end, center, row);
  }
}
</style>
