<template>
  <div
    :id="`m_module_${id}`"
    data-tour="tour_report_m_module"
    :class="editor ? 'm-report--editor' : 'm-report--preview'"
    @click="select"
  >
    <div v-if="editor" class="m-report__actions">
      <m-icon
        id="m_report_edit"
        :tooltip="t('general.buttons.edit')"
        icon="edit"
        variant="secondary"
        size="small"
        hover="weight"
        @click="select"
      />
      <m-icon
        id="m_report_remove"
        :tooltip="t('general.buttons.remove')"
        icon="delete"
        variant="secondary"
        size="small"
        hover="weight"
        @click="remove"
      />
    </div>
    <m-loading v-if="isLoading" size="small" overlay blured />
    <p
      v-else-if="settings.subset == 'saved' && articles.length == 0"
      class="type--small type--empty"
    >
      {{ t("views.reports.toolbox.articles_saved_empty") }}
    </p>
    <div v-else-if="settings.header?.length != 0" class="m-report__heading">
      <h2 class="type--small">
        {{ view?.name }}
      </h2>
      <h6 class="type--small">
        <template v-if="computedDateRestriction.isRelative">
          {{ t(`general.time.${computedDateRestriction.timeFrame}`) }}
        </template>
        <template v-else>
          {{ computedDateRestriction.start.toLocaleString() }} -
          {{ computedDateRestriction.end.toLocaleString() }}
        </template>
      </h6>
    </div>
    <div
      v-if="!isLoading && articles.length != 0"
      class="m-report__articles m-articles__wrapper m-articles__wrapper--details"
      :class="{ 'm-report__articles--duplicates': settings.dups }"
    >
      <div
        v-for="article in articles"
        :key="article.id"
        class="m-article m-article--details"
        @click="goToArticle(article)"
      >
        <div class="m-article__container">
          <div class="m-article__wrapper">
            <div v-if="settings.preview?.thumbnail" class="m-article__image">
              <m-image
                v-if="settings.preview?.thumbnail"
                :src="getImage(article)"
              />
            </div>
            <div class="m-article__content">
              <div class="m-article__heading">
                <h4 class="m-article__title" :key="preferredLanguage">
                  {{ getArticleTitle(article).title }}
                </h4>
              </div>
              <div class="m-article__bottom">
                <div class="m-article__feed">
                  <m-feed-link
                    v-if="!editor && settings.preview?.feed"
                    :feed="getFeed(article)"
                    class="mr-2"
                  />
                  <div
                    v-else-if="settings.preview?.feed"
                    class="m-article__feed mr-2"
                  >
                    <h5 class="type--small">
                      {{ getFeed(article).name }}
                    </h5>
                  </div>
                </div>
                <h6
                  v-if="settings.preview?.date"
                  class="m-article__date type--xsmall"
                >
                  {{ dateFormat(article.date, "static") }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, unref, onMounted, computed, watch } from "vue";

import { useApi } from "@api/api";
import { useRouter, useRoute } from "vue-router";
import useDateFormat from "@hooks/useDateFormat";
import { useViewFilters } from "@hooks/useViewFilters";
import MFeedLink from "@components/MFeedLink.vue";
import MIcon from "@components/MIcon.vue";
import MImage from "@components/MImage.vue";
import MLoading from "@components/MLoading.vue";
import { useI18n } from "vue-i18n";
import x_background from "@assets/images/x_background.png";
import { useReportsStore } from "@root/store/modules/reports";
import { useViewsStore } from "@root/store/modules/views";
import { isEqual } from "lodash-es";

const props = defineProps({
  id: { type: [String, Number] },
  preferredLanguage: String,
  previewViewId: String,
  settings: {
    type: Object,
  },
});

const emit = defineEmits([
  "select-element",
  "remove-element",
  "update-module-data",
]);

const reportsStore = useReportsStore();
const viewsStore = useViewsStore();
const { api } = useApi();
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const { dateFormat } = useDateFormat();
const { sortBy, show, dateRestriction } = useViewFilters(router, route);

/** @type {import("vue").ComputedRef<import("@root/types.api.local").MonitioAPI.DateRestriction>} */
const computedDateRestriction = computed(
  () => props.settings.customSettings?.dateRestriction ?? dateRestriction.value
);

const editor = computed(() => route.name.toLowerCase().includes("builder"));

const viewId = computed(
  () => props.settings?.customSettings?.viewId ?? props.previewViewId
);
const view = computed(() => {
  return viewsStore?.getViewById(viewId.value);
});

const apiArticlesData = ref([]);
const totalNumOfArticles = ref(0);

/** Use this computed variable to pre process the articles as needed for the UI */
const articles = computed(() => {
  const articles = [...apiArticlesData.value];
  // send emit event to update module data on the outside
  emit("update-module-data", articles);
  return articles;
});
/**
 *
 * @param {string} id Its a Guid
 * @param {Monitio.URLQueryObject} query
 */
const getArticles = async (id, dr) => {
  const aggregateDuplicates =
    props.settings?.groupDuplicates == null ||
    props.settings?.groupDuplicates == undefined
      ? true
      : props.settings?.groupDuplicates;

  const response = await api.search.search(
    unref(id),
    dr,
    0,
    props.settings.maxNumDocs - 1,
    null,
    sortBy.value,
    show.value,
    aggregateDuplicates
  ); //start by seding view id to the backend today
  if (response.status != 200) return;

  /** Get the total number of documents in the backend (sum of all indexes atm) */
  let totalNumOfArticles = 0; //If the request happens more than once in the view we need to reset this
  for (const index in response.data.result.numDocuments) {
    totalNumOfArticles += response.data.result.numDocuments[index];
  }

  return {
    articlesData: response.data.result.documents,
    articlesCount: totalNumOfArticles,
  };
};

const getSavedArticles = async (id, dr) => {
  const response = await api.search.savedArticles(unref(id), dr); //start by seding view id to the backend today
  if (response.status != 200) return;

  /** Get the total number of documents in the backend (sum of all indexes atm) */
  let totalNumOfArticles = 0; //If the request happens more than once in the view we need to reset this
  for (const index in response.data.result.numDocuments) {
    totalNumOfArticles += response.data.result.numDocuments[index];
  }

  return {
    articlesData: response.data.result.documents,
    articlesCount: totalNumOfArticles,
  };
};

const isLoading = ref(true);
onMounted(async () => {
  const viewId = props.settings?.customSettings?.viewId ?? props.previewViewId;

  const { articlesData, articlesCount } =
    props.settings?.subset == "saved"
      ? await getSavedArticles(viewId, computedDateRestriction.value)
      : await getArticles(viewId, computedDateRestriction.value);
  apiArticlesData.value = articlesData;
  totalNumOfArticles.value = articlesCount;
  isLoading.value = false;
});

watch(
  () => [props.settings, computedDateRestriction.value, viewId.value],
  async ([newSettings, newDR, newViewId], [oldSettings, oldDR, oldViewId]) => {
    if (
      oldViewId == newViewId &&
      isEqual(newDR, oldDR) &&
      newSettings.source == oldSettings.source &&
      newSettings.subset == oldSettings.subset &&
      newSettings.maxNumDocs == oldSettings.maxNumDocs &&
      newSettings.groupDuplicates == oldSettings.groupDuplicates &&
      isEqual(
        newSettings.customSettings ?? {},
        oldSettings.customSettings ?? {}
      )
    )
      return;
    isLoading.value = true;

    const { articlesData, articlesCount } =
      newSettings.subset == "saved"
        ? await getSavedArticles(newViewId, newDR)
        : await getArticles(newViewId, newDR);
    apiArticlesData.value = articlesData;
    totalNumOfArticles.value = articlesCount;
    isLoading.value = false;
  }
);

/* watch(
  () => queryObject.value,
  async () => {
    const viewId = props.settings?.sourceId
      ? props.settings?.sourceId
      : view.value.id;

    const { articlesData, articlesCount } =
      props.settings?.subset == "saved"
        ? await getSavedArticles(viewId)
        : await getArticles(viewId);
    apiArticlesData.value = articlesData;
    totalNumOfArticles.value = articlesCount;
    isLoading.value = false;
  }
); */

const tweet = (article) =>
  article.metadata?.sourceItemUrlList?.some(
    (x) => x.includes("twitter.com") || x.includes("://x.com")
  );

const selectedLang = (article) => {
  if (props.preferredLanguage == "original") return article.documentLang;
  else return props.preferredLanguage;
};

const getArticleTitle = (article) => {
  const articleLang = article.documentLang;
  const result = {
    title: article.translations?.[selectedLang(article)]?.title,
    lang: selectedLang(article),
  };
  if (!result.title) {
    result.title = article.translations?.[articleLang]?.title;
    result.lang = articleLang;
  }
  if (!result.title) {
    result.title = t("article.main_title_untitled");
    result.lang = selectedLang(article);
  } //Fallback if there's nothing only
  return result;
};

const getImage = (article) => {
  console.log(article, tweet(article));

  if (article.thumbnail) {
    return article.thumbnail;
  } else if (tweet(article)) {
    return x_background;
  } else return "";
};

const getFeed = (article) => {
  const feed = {};
  if (tweet(article))
    feed.handle = article.metadata?.socialMediaInformation?.twitter?.Handle;

  feed.name = article.metadata?.sourceItemFeeds?.[0]?.name;
  feed.url = article.metadata?.sourceItemUrlList?.[0];

  return feed;
};

const goToArticle = (article) => {
  /**
   * The encondedParams is a stringified object that is passed to MArticle view
   * that way we can pass several params all in one string.
   * This is usefull so people can easily share links between them without to many arguments in the URL
   */
  if (!editor.value) {
    router.push({
      name: "article",
      params: {
        viewId: unref(view.value?.id),
        articleId: article.id,
        breadcrumb: article.title,
      },
    });
  }
};

const select = () => {
  emit("select-element", props.id);
};

const remove = () => {
  emit("remove-element", props.id);
};
</script>

<style scoped lang="scss">
.m-report {
  &__heading {
    margin-bottom: $spacing-2;
    align-self: flex-start;
    text-align: left;
  }

  &--editor {
    .m-article:hover {
      padding: $spacing-3 !important;
      cursor: default;

      * {
        cursor: default;
      }

      .m-article__container {
        padding: unset !important;
        background-color: unset !important;
        box-shadow: unset !important;
      }
    }
  }
}
</style>
