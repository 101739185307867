<template>
  <div class="m-main-nav" :class="[`m-main-nav--${navState}`]">
    <m-icon
      id="views_nav_toggle"
      icon="arrow"
      :tooltip="toggleLabelTooltip"
      variant="secondary"
      hover="highlight"
      class="m-main-nav__toggle"
      :class="`m-main-nav__toggle--${toggleArrow}`"
      @click="toggleNavState"
    />
    <div class="m-main-nav__list">
      <m-logo :type="logoType" height="24" class="m-main-nav__logo" />
      <div
        id="m_main_nav_home"
        :data-tooltip-content="tooltip.content('home')"
        :data-tooltip-template="tooltip.template()"
        :data-tooltip-position="tooltip.position"
        :data-tooltip-type="tooltip.type"
        class="m-main-nav__home m-clickable"
        :class="{ 'm-main-nav__home--active': currentRoute.name == 'home' }"
        @click="push('/home')"
      >
        <div
          v-if="currentRoute.name == 'home'"
          class="m-main-nav__marker"
        ></div>
        <m-icon
          id="m_main_nav_home"
          icon="home"
          variant="sidenav"
          :weight="currentRoute.name == 'home' ? 'bold' : 'regular'"
          status="active"
          @click="push('/home')"
        />
        <span v-if="navState == 'expanded'" class="h5">
          {{ t("navigation.main.home") }}
        </span>
      </div>
      <div class="m-divider"></div>
      <div v-if="navState == 'expanded'" class="m-main-nav__views">
        <div class="d-flex-start-center">
          <m-icon
            id="m_main_nav_views"
            icon="views"
            variant="sidenav"
            status="active"
          />
          <span class="h5">
            {{ t("navigation.main.views") }}
          </span>
        </div>
        <m-icon
          v-if="navState == 'expanded'"
          id="m_main_nav_create_view"
          icon="add-outlined"
          variant="sidenav"
          size="small"
          hover="highlight"
          @click="openCreateView"
        />
      </div>
      <div
        v-if="navState == 'collapsed'"
        id="m_main_nav_create_view"
        :data-tooltip-content="tooltip.content('create')"
        :data-tooltip-template="tooltip.template()"
        :data-tooltip-position="tooltip.position"
        :data-tooltip-type="tooltip.type"
        class="m-main-nav__create-view m-clickable"
        @click="openCreateView"
      >
        <m-icon
          id="m_main_nav_create_view"
          icon="view-new"
          variant="sidenav"
          @click="openCreateView"
        />
      </div>
      <m-search
        v-if="navState == 'expanded'"
        id="m_main_nav_views_search"
        v-model:query="query"
        size="small"
        :placeholder="t('navigation.main.views_searchPlaceholder')"
        class="my-2"
      />
      <!-- <div v-for="(folder, i) in folders" :key="i" class=""></div> -->
      <div class="m-main-nav__views-wrapper">
        <div
          v-for="(view, i) in views"
          :key="i"
          :id="`m_main_nav_view_${snakeCase(view.id)}`"
          :data-tooltip-content="tooltip.content(view)"
          :data-tooltip-template="tooltip.template(view)"
          :data-tooltip-position="tooltip.position"
          :data-tooltip-type="tooltip.type"
          class="m-main-nav__view m-clickable"
          :class="{
            'm-main-nav__view--active': isActive(view.id),
          }"
          @mouseenter="setNewsletterState(view.newsletterSubscribed)"
          @click="openView(view)"
          @keyup.enter="openView(view)"
        >
          <div>
            <div v-if="isActive(view.id)" class="m-main-nav__marker"></div>
            <div class="m-view__badge">
              <m-image
                :src="view.details.image"
                :fallback="monitio_thumbnail"
                width="32"
                height="32"
                class="m-view__thumbnail"
              />
              <div v-if="limitCount(view.count)" class="m-view__count">
                <span class="h4">
                  {{ limitCount(view.count) }}
                </span>
              </div>
            </div>
            <div v-if="navState == 'expanded'" class="m-view__details">
              <span class="h5">{{ view.name }}</span>
              <span
                v-if="view.isBaseView"
                class="h6 type--xsmall d-flex-start-center"
              >
                {{ t("navigation.main.views_view_base") }}
              </span>
              <span
                v-else-if="view.isDraftView"
                class="h6 type--xsmall d-flex-start-center"
              >
                <m-icon
                  :id="`m_main_nav_view_draft_${snakeCase(view.id)}`"
                  icon="edit"
                  size="xsmall"
                  variant="terciary"
                  weight="light"
                  status="active"
                  class="mr-1"
                />
                {{ t("navigation.main.views_view_draft") }}
              </span>
              <!-- <span
                v-else-if="getShareInfo(view)"
                class="h6 type--xsmall d-flex-start-center"
              >
                <m-icon
                  :id="`m_main_nav_view_share_${snakeCase(view.id)}`"
                  :icon="getShareInfo(view).icon"
                  size="xsmall"
                  variant="terciary"
                  weight="light"
                  status="active"
                  class="mr-1"
                />
                {{ getShareInfo(view).label }}
              </span> -->
            </div>
          </div>
          <div v-if="navState == 'expanded'">
            <div class="m-view__actions">
              <m-icon
                v-if="canAccessNewsletter(view)"
                :id="`m_main_nav_${snakeCase(view.id)}_newsletter`"
                :icon="newsletterState"
                variant="secondary"
                size="small"
                hover="highlight"
                :data-tooltip-content="newsletterStateTooltip"
                @mouseenter="(evt) => toggleNewsletterState(evt, view)"
                @mouseleave="(evt) => toggleNewsletterState(evt, view)"
                @click="toggleNewsletter(view)"
              />
              <m-icon
                v-if="!view.isBaseView && view.isShared"
                id="m_main_nav_views_leave"
                :tooltip="newsletterTooltip"
                icon="unshare"
                variant="secondary"
                size="small"
                hover="highlight"
                @click="leaveView(view)"
              />
              <m-options
                v-if="!view.isBaseView && !view.isShared"
                id="m_main_nav_views_options"
                variant="secondary"
                :options="viewOptions(view)"
                size="small"
                floating
                disableSort
                class="m-clickable"
                @select="(opt) => viewSelectOpt(view, opt)"
              />
            </div>
          </div>
        </div>
        <div v-if="query.trim().length > 1 && views.length == 0">
          <span
            class="mx-2 h6 type--small type--empty"
            :class="`type--${size}`"
          >
            {{ t("navigation.main.views_empty") }}
          </span>
        </div>
      </div>
      <div class="m-divider"></div>
      <div
        ref="templatesRef"
        id="m_main_nav_templates"
        :data-tooltip-content="tooltip.content('templates')"
        :data-tooltip-template="tooltip.template()"
        :data-tooltip-position="tooltip.position"
        :data-tooltip-type="tooltip.type"
        class="m-main-nav__template m-clickable"
        :class="{
          'm-main-nav__template--active':
            currentRoute.path.startsWith('/template'),
        }"
        @click="toggleTemplatesDropdown"
        @keyup.up="openTemplatesDropdown = true"
        @keyup.down.exact="openTemplatesDropdown = true"
        @keyup.alt.down="openTemplatesDropdown = true"
        @keyup.home="openTemplatesDropdown = true"
        @keyup.end="openTemplatesDropdown = true"
        @keyup.space="openTemplatesDropdown = true"
        @keyup.enter="openTemplatesDropdown = true"
        @keyup.esc="closeTemplatesDropdown"
      >
        <div
          v-if="currentRoute.path.startsWith('/templates')"
          class="m-main-nav__marker"
        ></div>
        <div class="d-flex-start-center">
          <m-icon
            id="m_main_nav_templates"
            icon="construction"
            variant="sidenav"
            :weight="
              currentRoute.path.startsWith('/templates') ? 'bold' : 'regular'
            "
            status="active"
            @click="toggleTemplatesDropdown"
          />
          <span
            v-if="navState == 'expanded'"
            id="m_main_nav_templates_label"
            class="h5"
          >
            {{ t("navigation.main.templates") }}
          </span>
        </div>
        <m-icon
          v-if="navState == 'expanded'"
          id="m_main_nav_templates_arrow"
          icon="arrow"
          variant="sidenav"
          size="small"
          @click="toggleTemplatesDropdown"
        />
        <m-dropdown
          id="m_main_nav_templates_dropdown"
          labelledBy="m_main_nav_templates_label"
          :options="templatesOptions"
          v-model:visible="openTemplatesDropdown"
          floating
          :keyup="keyup"
          :keydown="keydown"
          fullwidth
          :parent="templatesRef"
          class="m-templates__dropdown"
          @update:selected="openTemplates"
          @keep-dropdown-open="keepTemplatesDropdownOpen"
          @mouseenter="keepTemplatesDropdownOpen"
          @mouseleave="closeTemplatesDropdown"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import { useApi } from "@api/api";
import { useSignalR } from "@root/hubs/main";
import { snakeCase } from "lodash-es";

import useViewReact from "@root/hubs/reactions/useViewReact";
import useCreateViewModal from "@components/modals/MCreateView/useCreateViewModal";
import useMShareViewModal from "@components/modals/MShareView/useMShareViewModal";
import useMViewSettingsModal from "@components/modals/MViewSettings/useMViewSettingsModal";
import useDropdown from "@hooks/useDropdown";

import monitio_thumbnail from "@assets/images/monitio_thumbnail.png";
import MLogo from "@components/MLogo.vue";
import MSearch from "@components/MSearch.vue";
import MImage from "@components/MImage.vue";
import MIcon from "@components/MIcon.vue";
import MOptions from "@components/MOptions.vue";
import MDropdown from "@components/MDropdown.vue";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import { useReportsStore } from "@root/store/modules/reports";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useSessionStore } from "@root/store/modules/session";

const { t } = useI18n();
const { currentRoute, push } = useRouter();
const route = useRoute();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const alertsStore = useAlertsStore();
const dashboardsStore = useDashboardsStore();
const reportsStore = useReportsStore();
const sessionStore = useSessionStore();
const workspacesStore = useWorkspacesStore();
const { api } = useApi();
const signalR = useSignalR();
const workspaceId = ref(workspacesStore.id);
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const templatesRef = ref(null);

const { open: openCreateView } = useCreateViewModal();
const { open: openShareView } = useMShareViewModal();
const { open: openViewSettings } = useMViewSettingsModal();

const {
  openDropdown: openTemplatesDropdown,
  toggleDropdown: toggleTemplatesDropdown,
  keepDropdownOpen: keepTemplatesDropdownOpen,
  closeDropdown: closeTemplatesDropdown,
} = useDropdown();

const keyup = ref(null);
const keydown = ref(null);

const keyUpMovement = (evt) => {
  keyup.value = evt;
};

const keyDownMovement = (evt) => {
  keydown.value = evt;
};

onMounted(() => {
  templatesRef.value?.addEventListener("keyup", keyUpMovement);
  templatesRef.value?.addEventListener("keydown", keyDownMovement);
});

onBeforeUnmount(() => {
  templatesRef.value?.removeEventListener("keyup", keyUpMovement);
  templatesRef.value?.removeEventListener("keydown", keyDownMovement);
});

const navState = computed(() => sessionStore.mainNavState);
const toggleNavState = () => {
  if (navState.value == "expanded") {
    sessionStore.mainNavStateSet("collapsed");
  } else sessionStore.mainNavStateSet("expanded");
};

const toggleArrow = computed(() => {
  if (navState.value == "collapsed") {
    return "right";
  } else return "left";
});

const toggleLabelTooltip = computed(() => {
  return {
    content: t(`navigation.main.${navState.value}`),
    position: "dynamic",
  };
});

const logoType = computed(() => {
  if (navState.value == "collapsed") {
    return "ico";
  } else return "horizontal";
});

const query = ref("");

const views = computed(() => {
  const baseViews = viewsStore.userViews
    .concat(viewsStore.sharedViews)
    .filter(
      (f) =>
        f.isBaseView && f.name.toLowerCase().includes(query.value.toLowerCase())
    )
    .map((m) => ({ ...m, readonly: true }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const allViews = viewsStore.userViews.concat(viewsStore.sharedViews);

  const draftViews = viewsStore.draftViews
    .filter(
      (f) =>
        !f.isBaseView &&
        f.name.toLowerCase().includes(query.value.toLowerCase()) &&
        f.workspaceId == workspaceId.value
    )
    .map((m) => ({ ...m, readonly: true, isDraftView: true }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const organizedViews = allViews
    //.filter((f) => !f.isBaseView) //Filter out the base view
    .filter((f) => !f.folder && !f.isBaseView)
    .filter((f) => f.name.toLowerCase().includes(query.value.toLowerCase()))
    .map((m) => ({ ...m, readonly: m.isShared }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return baseViews.concat(draftViews.concat(organizedViews));
});

const folders = computed(() => {
  const folders = [];
  viewsStore.userViews.forEach((view) => {
    if (view.folder) {
      folders.find((f) => {
        if (!(f.name == view.folder.name)) folders.push(f);
      });
    }
  });
  return folders
    .filter((f) => f.name.toLowerCase().includes(query.value.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));
});

const limitCount = (val) => {
  if (val > 99) {
    return "+99";
  } else return val;
};

const getShareInfo = (view) => {
  let info = null;
  if (view?.isShared) {
    const user = workspacesStore.getUserById(view.createdById);
    const firstName = user?.accountDetails?.firstName;
    const lastName = user?.accountDetails?.lastName;

    info = {
      icon: "view-shared",
      label: t("navigation.main.views_view_owner", {
        name: `${firstName} ${lastName}`,
      }),
    };
  } else if (
    view?.sharedWith?.team?.length > 0 &&
    view?.sharedWith?.workspaceUser?.length > 0
  ) {
    const users = view.sharedWith.workspaceUser;
    const teams = view.sharedWith.team;
    info = {
      icon: "views-shared",
      list: users.concat(teams).sort((a, b) => a.name.localeCompare(b.name)),
      label: t("navigation.main.views_view_shared", {
        count: users.concat(teams).length,
      }),
    };
  } else if (view?.sharedWith?.team?.length > 0) {
    const teams = view.sharedWith?.team;
    info = {
      icon: "views-shared",
      list: teams.sort((a, b) => a.name.localeCompare(b.name)),
      label: t("navigation.main.views_view_shared", {
        count: teams.length,
      }),
    };
  } else if (view?.sharedWith?.workspaceUser?.length > 0) {
    const users = view.sharedWith?.workspaceUser;
    info = {
      icon: "views-shared",
      list: users.sort((a, b) => a.name.localeCompare(b.name)),
      label: t("navigation.main.views_view_shared", {
        count: users.length,
      }),
    };
  }
  return info;
};

const canAccessNewsletter = (view) => {
  if (view?.isDraftView) return false;
  /** If its not shared, the user is the owner */
  if (!view?.isShared) return true;
  /** Check here if the view is shared directly with the user */
  if (
    view?.sharedWith?.workspaceUser?.find((f) => f.id == userStore.details.id)
  ) {
    return true;
  }

  /** Next, check if one user is team leader / ownner of any of the teams that this view is shared with */
  const { accountId, teams } = userStore.details;
  const sharedWithUserTeams = teams?.filter(
    (x) => view.sharedWith?.team?.[x.id]
  );
  if (
    sharedWithUserTeams.length &&
    sharedWithUserTeams.some((x) => x.ownerId == accountId)
  ) {
    return true;
  }

  /** If none pass forbid */
  return false;
};

const changingNewsletter = ref(false);
const newsletterState = ref("newsletter-off");

const newsletterStateTooltip = computed(() => {
  switch (newsletterState.value) {
    case "newsletter-on":
      return t("navigation.main.views_view_newsletter-on");
    case "newsletter-off":
    default:
      return t("navigation.main.views_view_newsletter-off");
  }
});

const setNewsletterState = (state) => {
  if (state) newsletterState.value = "newsletter-on";
  else newsletterState.value = "newsletter-off";
};

const toggleNewsletterState = (evt, view) => {
  const state = view.newsletterSubscribed;

  if (
    (state && newsletterState.value == "newsletter-on") ||
    (!state && newsletterState.value == "newsletter-off")
  ) {
    if (evt.type == "mouseleave") return;
  }
  if (newsletterState.value == "newsletter-on") {
    newsletterState.value = "newsletter-off";
  } else newsletterState.value = "newsletter-on";
};

const toggleNewsletter = async (view) => {
  const state = view.newsletterSubscribed;
  if (
    (state && newsletterState.value == "newsletter-on") ||
    (!state && newsletterState.value == "newsletter-off")
  ) {
    return;
  }
  changingNewsletter.value = true;
  if (view?.newsletterSubscribed) {
    const request = await api.views.unsubscribeNewsletter(view?.id);
    if (request.status != 200) return;
    const v = { ...view };
    v.newsletterSubscribed = false;
    viewsStore.updateView(v);
  } else {
    const request = await api.views.subscribeNewsletter(view?.id);
    if (request.status != 200) return;
    const v = { ...view };
    v.newsletterSubscribed = true;
    viewsStore.updateView(v);
  }
  changingNewsletter.value = false;
};

const newsletterTooltip = computed(() => {
  return t(`navigation.main.views_${newsletterState.value}`);
});

const viewOptions = (view) => {
  let list;
  if (view.isDraftView) {
    list = ["delete"];
  } else list = ["edit", "manage-filters", /* "share" ,*/ "delete"];

  return list.map((m) => ({
    icon: m,
    value: m,
    label: t(`navigation.main.views_view_options_${m}`),
  }));
};

const tooltip = computed(() => {
  if (navState.value == "collapsed") {
    return {
      content: (val) => {
        let label;
        let content;
        switch (val) {
          case "home":
            label = t("navigation.main.home_collapsed_action");
            break;
          case "toggle":
            label = t("navigation.main.collapsed");
            break;
          case "create":
            label = t("navigation.main.views_collapsed_create");
            break;
          case "templates":
            label = t("navigation.main.templates_collapsed");
            break;
          default:
            content = {
              label: val.name,
              info: val.name,
            };
            if (val.isBaseView) {
              content.info = t("navigation.main.views_view_base");
            } else if (val.isDraftView) {
              content.info = t("navigation.main.views_view_draft");
            } else if (getShareInfo(val)) {
              content.info = getShareInfo(val).label;
              if (getShareInfo(val)?.list?.length) {
                content.info = getShareInfo(val).label;
              }
            }
            return JSON.stringify(content);
        }
        return JSON.stringify({ label });
      },
      template: (view) => {
        if (view?.isBaseView) return "baseView";
        else if (view?.isDraftView) return "draftView";
        else if (getShareInfo(view)?.list?.length) return "sharedView";
        if (getShareInfo(view)) return "viewShared";
        else return "nav";
      },
      position: "right",
      type: "blue",
    };
  } else {
    return {
      content: (val) => {
        switch (val) {
          case "home":
            return t(`navigation.main.home_expanded_action`);
          case "toggle":
            return t("navigation.main.expanded");
          case "create":
            return t("navigation.main.views_expanded_create");
          default:
            return t("navigation.main.views_view_open", { name: val.name });
        }
      },
      template: () => null,
      position: "dynamic",
    };
  }
});

const openDeleteViewDialog = (view) => {
  // console.log("delete", view.id);
  const resolveDialog = () => {
    if (currentRoute.value.params.viewId == view.id) {
      push("/home");
    }

    if (view.isDraftView) {
      viewsStore.removeDraftViewById(view?.id);
    } else viewsStore.removeViewById(view?.id);

    alertsStore.add({
      type: "toast",
      variant: "success",
      message: t("general.alerts.toast.deletedView", {
        name: view.name,
      }),
    });
  };

  alertsStore.add({
    type: "dialog",
    variant: "error",
    heading: t("general.alerts.dialog.deleteView.heading", {
      name: view.name,
    }),
    message: t("general.alerts.dialog.deleteView.message"),
    resolve: {
      label: t("general.buttons.delete"),
      action: resolveDialog,
    },
    cancel: t("general.buttons.keep"),
  });
};

const viewSelectOpt = (view, opt) => {
  switch (opt) {
    case "share":
      openShareView(view);
      break;
    case "edit":
      openViewSettings(view);
      break;
    case "manage-filters":
      push({ name: "manageFilters", params: { viewId: view.id } });
      break;
    case "delete":
      openDeleteViewDialog(view);
      break;
  }
};

const isActive = (val) => {
  if (currentRoute.value.name == "createManageFilters") {
    return currentRoute.value.params.tempViewId == val;
  } else return currentRoute.value.params.viewId == val;
};

const openView = (view) => {
  if (view.isDraftView) {
    const viewId = route.params.viewId ?? workspaceConfig.value.baseViewId;
    push(`/views/${viewId}/create-view/${view.id}/manage-filters`);
    // viewsStore.selectView(props.element);
  } else if (view.id) {
    let exists;

    switch (currentRoute.value.name) {
      // These cases are a white list of routes that support view switching within them
      case "articles":
      case "tiles":
      case "headlines":
      case "trending-entities":
      case "entity-network":
      case "saved":
        push({
          path: `/views/${view.id}/${currentRoute.value.name}`,
          query: currentRoute.value.query,
        });
        break;
      case "report":
        // eslint-disable-next-line no-case-declarations
        const templateId = reportsStore.getById(
          currentRoute.value.params.reportId
        ).template.id;
        console.log(templateId);
        exists = reportsStore
          .getListByViewId(view.id)
          .find((f) => f.template.id == templateId);

        push({
          name: exists ? "report" : "report-not-found",
          params: {
            ...currentRoute.value.params,
            reportId: exists?.id,
            viewId: view.id,
          },
          query: currentRoute.value.query,
        });
        break;
      case "dashboard":
        exists = dashboardsStore
          .getListByViewId(view.id)
          .find((f) => f.id == currentRoute.value.params.dashboardId);
        push({
          name: exists ? "dashboard" : "dashboard-not-found",
          params: {
            ...currentRoute.value.params,
            viewId: view.id,
          },
          query: currentRoute.value.query,
        });
        break;
      default:
        push({
          path: `/views/${view.id}/tiles`,
          query: currentRoute.value.query,
        });
        break;
    }
  }
};

const createFolder = () => {
  // TODO
};

const templatesOptions = computed(() => {
  const opts = ["dashboards", "reports"];

  return opts.map((m) => ({
    icon: m,
    value: m,
    label: t(`navigation.main.templates_${m}`),
  }));
});

const openTemplates = (val) => {
  push({ name: `${val.value}Templates` });
};

//#region Websockets events handling
const { reactToChange, reactToDelete } = useViewReact();
onMounted(() => {
  signalR.addEventListener("changed", reactToChange);
  signalR.addEventListener("delete", reactToDelete);
});

onUnmounted(() => {
  signalR.removeEventListener("changed", reactToChange);
  signalR.removeEventListener("changed", reactToDelete);
});
//#endregion
</script>

<style scoped lang="scss">
.m-main-nav {
  min-width: $sidenav-width;
  width: $sidenav-width;
  padding: $spacing-2 $spacing-1 $spacing-0;
  border: 1px solid color($pri-action-medium, 0.1);
  @include round-corners($spacing-1);
  position: relative;
  background-color: color($pri-ultra-light);

  &__toggle {
    margin: $spacing-2 $spacing-1;
    @include round-corners;
    position: absolute;
    top: $spacing-0;
    right: $spacing-0;
    display: none;
    align-self: flex-end;
    background-color: color($white);
    @include elevate-button;

    &--right {
      transform: rotate(-90deg) translateY(73%);
    }

    &--left {
      transform: rotate(90deg) translateY(-73%);
    }
  }

  &:hover .m-main-nav__toggle {
    display: flex;
  }

  &__list {
    max-height: calc(100vh - $spacing-2);
    @include flex(flex-start, stretch, column);
  }

  &__logo {
    margin: $spacing-1 math.div($spacing-3, 2) $spacing-2;
    align-self: flex-start;
    transform: scale(1.17);
    transform-origin: left;
  }

  &__marker {
    width: math.div($spacing-1, 2);
    height: $spacing-6;
    @include round-corners($spacing-1);
    position: absolute;
    left: 0;
    background-color: color($pri-action);
    transform: translateX(-$spacing-1);
  }

  .m-divider {
    opacity: 0.5;
  }

  &__home,
  &__views,
  &__create-view,
  &__view,
  &__template {
    height: $spacing-9;
    margin: $spacing-1 $spacing-0;
    @include round-corners($spacing-1);
    position: relative;
    @include flex(space-between, center, row);
    gap: $spacing-2;

    .h5 {
      color: color($pri-action-inactive);
      @include one-line-ellipsis;
    }

    &--active {
      background-color: color($pri-action, 0.1);
      @include opacity-active;

      .m-icon {
        color: color($pri-action);
        fill: color($pri-action);
        stroke: color($pri-action);
      }

      .h5 {
        font-weight: 700;
        color: color($pri-action);
      }
    }
  }

  &__home {
    justify-content: flex-start;
  }

  &__create-view {
    justify-content: center;
  }

  &__views .d-flex-start-center,
  &__template .d-flex-start-center {
    gap: $spacing-2;
  }

  &__home,
  &__create-view,
  &__views,
  &__template {
    padding: $spacing-1 $spacing-2;

    &:not(.m-main-nav__views):hover {
      .h5,
      .m-icon {
        color: color($pri-action);
        fill: color($pri-action);
      }
    }
  }

  &__views-wrapper {
    width: calc(100% + $spacing-4);
    height: calc(100vh - (($spacing-14 + $spacing-1) * 4));
    max-height: calc(100vh - (($spacing-14 + $spacing-1) * 4));
    padding-left: $spacing-2;
    padding-right: $spacing-2;
    flex-grow: 1;
    transform: translateX(-$spacing-2);
    overflow-x: auto;
  }

  &__view {
    height: $spacing-11;
    padding: $spacing-1;

    > div {
      @include flex(flex-start, center, row);
      gap: $spacing-3;
    }

    .h6.type--xsmall {
      opacity: 0.6;
    }

    .m-view {
      &__badge .m-image {
        width: $spacing-9;
        height: $spacing-9;
        position: relative;
        aspect-ratio: 1 / 1;
        object-fit: cover;
      }

      &__thumbnail {
        border: 1px solid color($pri-action, 0);
        @include round-corners($spacing-1);
        overflow: hidden;
      }

      &__count {
        min-width: $spacing-6;
        height: $spacing-6;
        border: 2px solid color($white);
        @include round-corners($spacing-7);
        @include flex(center, center, row);
        position: absolute;
        right: $spacing-0;
        bottom: $spacing-0;
        transform: translate(math.div($spacing-1, 2), math.div($spacing-1, 2));
        background-color: color($red);

        span {
          font-size: 0.625rem;
          line-height: 0.75rem;
          color: color($white);
        }
      }

      &__actions {
        display: none;
      }
    }

    &:hover {
      .m-view {
        &__thumbnail {
          border: 1px solid color($pri-action, 0.3);
        }

        &__details span.h5 {
          color: color($pri-action);
          fill: color($pri-action);
        }

        &__actions {
          @include flex(flex-start, center, row);
          gap: $spacing-1;
        }
      }
    }
  }

  &--collapsed {
    min-width: $sidenav-width--collapsed;
    width: $sidenav-width--collapsed;
    .m-main-nav {
      &__logo {
        transform: scale(1) translateX(2px);
      }

      &__home,
      &__views,
      &__view,
      &__template {
        padding: $spacing-1;
        @include flex(center, center, row);
        gap: $spacing-0;
      }

      &__views-wrapper {
        height: calc(100vh - ($spacing-12 * 4));
        max-height: calc(100vh - ($spacing-12 * 4));
      }
    }
  }
}

.m-templates__dropdown {
  border: 1px solid color($pri-action-medium, 0.1);
  background-color: color($pri-ultra-light);

  .h6 {
    color: color($pri-action-inactive);
    font-weight: 500;
  }

  .m-icon {
    fill: color($pri-action-inactive);
    stroke-width: 0 !important;
  }
}
</style>
