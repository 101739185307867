<template>
  <div
    :id="`m_module_${id}`"
    data-tour="tour_report_m_module"
    :class="editor ? 'm-report--editor' : 'm-report--preview'"
    @click="select"
  >
    <div v-if="editor" class="m-report__actions">
      <m-icon
        id="m_report_edit"
        :tooltip="t('general.buttons.edit')"
        icon="edit"
        variant="secondary"
        size="small"
        hover="weight"
        @click="select"
      />
      <m-icon
        id="m_report_remove"
        :tooltip="t('general.buttons.remove')"
        icon="delete"
        variant="secondary"
        size="small"
        hover="weight"
        @click="remove"
      />
    </div>
    <textarea
      v-if="editor"
      v-model="value"
      class="m-report__heading"
      :class="`m-report__heading--${settings.align} ${settings.style}`"
      :placeholder="t('views.reports.toolbox.placeholderText')"
      data-autofocus="true"
      v-focus
    ></textarea>
    <component
      v-else-if="settings.title"
      :is="settings.style"
      class="m-report__heading"
      :class="`m-report__heading--${settings.align}`"
    >
      {{ settings.title }}
    </component>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import MIcon from "@components/MIcon.vue";
import { useReportsStore } from "@root/store/modules/reports";
import { debounce } from "lodash-es";

const props = defineProps({
  id: { type: [String, Number] },
  settings: {
    type: Object,
    default: () => ({
      style: "h1",
      align: "center",
    }),
  },
});

const { t } = useI18n();

const emit = defineEmits([
  "update:settings",
  "select-element",
  "remove-element",
]);

const reportsStore = useReportsStore();
const route = useRoute();
const editor = computed(() => route.name.toLowerCase().includes("builder"));

const value = ref(props.settings?.title);

const updateSettings = debounce(function (val) {
  const s = { ...props.settings };
  s.title = val;
  emit("update:settings", s, props.id);
}, 300);

watch(
  () => value.value,
  (val) => updateSettings(val),
  { immediate: true }
);

const select = () => {
  // console.info("select", props.id);
  emit("select-element", props.id);
};

const remove = () => {
  // console.info("removing", props.id);
  emit("remove-element", props.id);
};
</script>

<style scoped lang="scss">
.m-report--editor {
  .m-report__heading {
    padding: $spacing-0 $spacing-6;
  }
}

.m-report__heading {
  width: 100%;

  &--left {
    align-self: flex-start;
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    align-self: flex-end;
    text-align: right;
  }
}
</style>
