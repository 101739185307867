<template>
  <div
    v-if="!appStore.fullscreen"
    class="m-nav"
    :class="{ 'm-nav--double': hasSecondNav }"
  >
    <div class="m-nav__wrapper">
      <m-main-nav />
      <div v-if="hasSecondNav" class="m-divider"></div>
      <m-views-nav
        v-if="
          firstCrumb == 'views' &&
          !['views', 'createManageFilters'].includes(currentRoute.name)
        "
      />
      <m-settings-nav v-if="firstCrumb == 'settings'" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import MMainNav from "@components/navigation/MMainNav.vue";
import MViewsNav from "@components/navigation/MViewsNav.vue";
import MSettingsNav from "@components/navigation/MSettingsNav.vue";
import { useAppStore } from "@root/store/app";

const { currentRoute } = useRouter();
const appStore = useAppStore();

const firstCrumb = computed(() => currentRoute.value?.meta?.breadcrumbs[0]);

const hasSecondNav = computed(() => {
  return (
    (firstCrumb.value == "views" &&
      currentRoute.value.name != "createManageFilters") ||
    firstCrumb.value == "settings"
  );
});
</script>

<style scoped lang="scss">
.m-nav {
  padding: $spacing-2;

  &--double .m-main-nav {
    border-right: none;
    @include round-left-corners($spacing-1);
  }

  &__wrapper {
    height: calc(100vh - $spacing-4);
    @include round-corners($spacing-1);
    @include flex(flex-start, stretch, row);
    position: relative;
    @include elevate-navigation;
  }

  .m-divider {
    width: 1px;
    height: 100%;
    opacity: 0.5;
  }
}
</style>
