<template>
  <div class="m-settings-nav m-settings-nav--expanded">
    <div class="m-settings-nav__list">
      <div v-for="(element, i) in navList" :key="i" class="m-view-nav__wrapper">
        <div v-if="i != 0" class="my-2 m-divider"></div>
        <div tabindex="0" role="button" class="m-settings-nav__option">
          <span class="h5">
            {{ element.label }}
          </span>
        </div>
        <div
          v-if="element.children?.length > 0"
          class="m-settings-nav__children"
        >
          <div
            v-for="(child, ci) in element.children"
            :id="`m_settings_nav_${element.value}_${child.value}`"
            :data-tooltip-content="child.description"
            data-tooltip-position="dynamic"
            :key="ci"
            tabindex="0"
            role="button"
            class="m-children__option m-clickable"
            :class="{
              'm-children__option--active': isActive(child),
              '--a11y': appStore.a11y,
            }"
            @click="open(child)"
            @keydown.enter="open(child)"
          >
            <div class="m-sidenav__details">
              <m-icon
                :icon="child.icon"
                status="active"
                :tooltip="child.tooltip"
                variant="secondary"
                :weight="isActive(child) ? 'bold' : 'regular'"
                @click="open(child)"
              />
              <span class="mx-4 h5">
                {{ child.label }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";
import { useRouter } from "vue-router";
import MIcon from "@components/MIcon.vue";
import { useAppStore } from "@root/store/app";

const { t } = useI18n();
const appStore = useAppStore();
const { currentRoute, push } = useRouter();
const { api } = useApi();

const entityRules = ref([]);
const getEntityRules = (timestamp) => {
  api.user.getEntities(timestamp).then((res) => {
    entityRules.value = res.data.mentionRules;
  });
};

const tags = ref([]);
/* const getTags = () => {
  api.tags.getUserTagsSettingsPage().then((e) => {
    tags.value = e.data;
  });
}; */

onMounted(() => {
  getEntityRules("start");
  //getTags();
});

const setIcon = (val) => {
  switch (val) {
    case "account":
      return "user";
    case "languages":
      return "language";
    case "tags":
      return "tag";
    case "entities":
      return "entity-rules";
    case "teams":
      return "team";
    case "filters":
      return "manage-filters";
    default:
      return val;
  }
};

const generalChildren = computed(() => {
  const list = ["account", "languages"];
  if (tags.value.length) list.push("tags");

  return list.map((m) => ({
    value: m,
    icon: setIcon(m),
    label: t(`navigation.settings.general_${m}`),
    description: t(`navigation.settings.general_${m}_description`),
  }));
});

const workspaceChildren = computed(() => {
  const list = [/*"teams",*/ "filters"];
  if (entityRules.value.length) list.push("entities");

  return list.map((m) => ({
    value: m,
    icon: setIcon(m),
    label: t(`navigation.settings.workspace_${m}`),
    description: t(`navigation.settings.workspace_${m}_description`),
  }));
});

const navList = computed(() => {
  const list = ["general", "workspace"];

  return list.map((m) => {
    const object = {
      value: m,
      icon: setIcon(m),
      label: t(`navigation.settings.${m}`),
      description: t(`navigation.settings.${m}_description`),
    };

    if (m == "general") object.children = generalChildren.value;
    if (m == "workspace") object.children = workspaceChildren.value;

    return object;
  });
});

const isActive = (val) => {
  return currentRoute.value?.name == val.value;
};

const open = (val) => {
  push({ name: val.value });
};
</script>

<style scoped lang="scss">
.m-settings-nav {
  min-width: $sidenav-width;
  width: $sidenav-width;
  padding: $spacing-0 $spacing-1;
  border: 1px solid color($sec-200);
  border-left: none;
  @include round-right-corners($spacing-1);
  background-color: color($white);

  &__option {
    height: $spacing-9;
    padding: $spacing-1 $spacing-2;
    margin: $spacing-0 $spacing-0 $spacing-1;
    @include flex(space-between, center, row);
  }

  &__children {
    margin-bottom: $spacing-1;

    .m-children__option {
      height: $spacing-9;
      padding: $spacing-2;
      margin: $spacing-1 $spacing-0;
      @include round-corners($spacing-1);
      @include flex(space-between, center, row);

      .h5,
      .m-icon {
        color: color($pri-action-inactive);
        fill: color($pri-action-inactive);
      }

      &:hover {
        .h5,
        .m-icon {
          color: color($pri-action);
          fill: color($pri-action);
        }
      }

      &--active {
        background-color: color($pri-action, 0.1);
        @include opacity-active;

        .m-icon {
          fill: color($pri-action);
          stroke: color($pri-action);
        }

        .h5 {
          font-weight: 700;
          color: color($pri-action);
        }
      }
    }
  }
}
</style>
