<template>
  <div class="m-modal__body">
    <m-selector
      id="m_feedback__type"
      :selected="feedbackType"
      :options="feedbackTypeOpts"
      @update:selected="updatefeedbackType"
      class="m_feedback__type"
    />
    <m-textarea
      id="create_report_description"
      v-model:modelValue="message"
      type="text"
      :label="$t('views.reports.create.description')"
      :placeholder="$t('views.reports.create.description')"
      class="my-3"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";

const { t } = useI18n();
const { api } = useApi();

const props = defineProps({
  /** @description {mode, value, documentLang, documentIptcs} */
  slotProps: Object,
  name: String,
});

const feedbackType = ref(null);
const feedbackTypeOpts = ref([
  { label: t("feedbackModal.feedback"), value: "feedback" },
  { label: t("feedbackModal.help"), value: "help" },
]);
const updatefeedbackType = (val) => {
  feedbackType.value = val.value;
};

const message = ref(null);
</script>

<style scoped lang="scss">
.m-modal {
  &__footer {
    @include flex(flex-end, center, row);
  }
}
.m-mention {
  &__actions {
    .m-actions {
      &__mention {
        margin: $spacing-7 $spacing-0 $spacing-3;
        @include flex(flex-start, center, row);
      }

      &__actions {
        @include flex(flex-end, center, row);
        align-self: flex-end;
        flex-grow: 1;
      }
    }
  }

  &__preview {
    padding: $spacing-4 $spacing-5;
    margin-bottom: $spacing-3;
    @include elevate-inset;

    > div:not(.m-preview__rule) {
      margin-bottom: $spacing-3;
      @include flex(flex-start, center, row);
    }

    .m-rule__content {
      > div {
        @include flex(flex-start, center, row);
      }
    }
  }

  &__edit {
    max-height: 50vh;
    overflow: auto;
    > *:not(:last-child) {
      margin-bottom: $spacing-5;
    }
  }

  .m-rule {
    &__content {
      .type--warning {
        padding: $spacing-1 $spacing-3;
        border-radius: $spacing-1;
        background-color: color($warning-light, 0.3);
      }

      > *:not(:last-child) {
        margin-bottom: $spacing-4;
      }
    }

    &__topics,
    &__languages {
      @include flex(flex-start, center, row);
      flex-wrap: wrap;

      > div {
        padding: $spacing-0 $spacing-2;
        margin-bottom: $spacing-2;
        @include flex(flex-start, center, row);
        background-color: color($pri-action-light, 0.2);
        @include round-corners($spacing-6);
      }
    }
  }
}

.iblock {
  display: inline-block;
}
</style>
