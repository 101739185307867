/**
 * File with common code for sharing between compoennts that react to shares (websockets)
 */

import { useRoute, useRouter } from "vue-router";
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import { useI18n } from "vue-i18n";
import guid from "@root/utils/guid";
import structuredClone from "@root/utils/structuredClone";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";

export default () => {
  const viewsStore = useViewsStore();
  const alertsStore = useAlertsStore();
  const userStore = useUserStore();
  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n();

  const react = (evt) => {
    if (evt.detail.value.producerType !== "workspaceUser") return;
    if (evt.detail.value.producerId !== userStore.details.id) return;
    if (evt.detail.value.eventType === "ExportArticles") {
      alertsStore.add({
        id: guid.NewGuid(),
        type: "toast",
        variant: "load",
        message: `Export progress: ${evt.detail.value.payload.progress}`,
      });
    }
    if (evt.detail.value.eventType === "ExportArticlesFinish") {
      alertsStore.add({
        id: guid.NewGuid(),
        type: "toast",
        variant: "success",
        message: `Your file is ready! <a href="${evt.detail.value.payload.url}">Click here to download</a>.`,
      });
    }
  };

  return { react };
};
