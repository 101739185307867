import { createI18n } from "vue-i18n";

const loadLocaleMessages = async () => {
  const locales = import.meta.glob("/src/locales/*.json");
  const messages = {};
  for (const path in locales) {
    if (Object.prototype.hasOwnProperty.call(locales, path)) {
      const lang = path.match(/([A-Za-z0-9-_]+)\./i)?.[1];
      if (!lang) return;
      const data = await locales[path]();
      messages[lang] = data;
    }
  }
  return messages;
};

const setupI18n = async () => {
  const i18n = createI18n({
    //missingWarn: process.env.NODE_ENV === "development",
    //fallbackWarn: process.env.NODE_ENV === "development",
    legacy: false,
    locale:
      localStorage.getItem("preferredLanguage") ||
      window._env_.VUE_APP_I18N_LOCALE ||
      "en",
    fallbackLocale: window._env_.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: await loadLocaleMessages(),
  });
  return i18n;
};

export { setupI18n };
