/**
 * File with common code for sharing between compoennts that react to shares (websockets)
 */

import { useRoute, useRouter } from "vue-router";
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import { useI18n } from "vue-i18n";
import guid from "@root/utils/guid";
import structuredClone from "@root/utils/structuredClone";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";

export default () => {
  const viewsStore = useViewsStore();
  const userStore = useUserStore();
  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n();

  /** @description Function that does the necessary store manipulation when a view is modified in some way */
  const changeViewProperties = async (evt) => {
    /** @type {import("@root/types.api.local").MonitioAPI.ViewDTO} */
    const view = evt.detail.value.payload;
    if (!view) return;
    await viewsStore.updateViewProperty("name", view);
    await viewsStore.updateViewProperty("details", view);
    return;
  };

  /** @description Function that does the necessary store manipulation of the unshare */
  const unshareViewWithUser = (evt) => {
    const viewId = evt.detail.context;
    const workspaceUserId = evt.detail.value.payload.id;
    if (workspaceUserId !== userStore.details.id)
      viewsStore.removeUserFromView(viewId, workspaceUserId);
    return;
  };

  const reactToChange = (evt) => {
    switch (evt.detail.value.eventType) {
      case "ChangeViewProperties":
        changeViewProperties(evt);
        return;
      case "UnshareViewWithUser": {
        unshareViewWithUser(evt);
        return;
      }
      default:
        return;
    }
  };

  const reactToDelete = async (evt) => {
    const { open, close } = useModal("m-dialog");

    // View delete, no need to check for type
    const viewId = evt.detail.value.contextId;
    if (route.params.viewId == viewId) {
      await open("dialog", {
        variant: "warning",
        heading: t("views.remote_deletion.heading"),
        message: [t("views.remote_deletion.message")],
        buttons: ["resolve", "cancel"],
        resolve: {
          label: t("views.remote_deletion.buttons.resolve"),
          action: async () => {
            // User wants to clone the view, redirect him to it
            const sourceView = structuredClone(viewsStore.getViewById(viewId));
            sourceView.id = guid.NewGuid();
            await viewsStore.saveView(sourceView, null, true);
            await router.push({
              name: "summary",
              params: { viewId: sourceView.id },
            });

            viewsStore.removeSharedViewById(viewId);
          },
        },
        cancel: {
          label: t("views.remote_deletion.buttons.reject"),
          action: async () => {
            // User wants to leave
            close();
            await router.push({ name: "explore" });
            viewsStore.removeSharedViewById(viewId);
          },
        },
      });
    }
  };

  return { reactToChange, reactToDelete };
};
