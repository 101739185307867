<template>
  <div
    :id="`m_toast_${id}`"
    role="alert"
    class="m-toast"
    :class="{ 'm-toast--out': exiting }"
  >
    <m-icon
      :id="`m_toast_remove_${id}`"
      variant="terciary"
      icon="close"
      hover="elevate"
      class="m-toast__remove"
      @click="action('close')"
    />

    <div class="m-toast__header">
      <m-icon
        v-if="toast.variant && toast.variant != 'load'"
        :id="`m_toast_icon_${id}`"
        :variant="icon?.variant"
        :icon="icon?.name"
        size="small"
        status="active"
        class="m-toast__icon"
      />
      <m-loading
        v-else-if="toast.variant == 'load'"
        size="xsmall"
        class="m-toast__icon"
      />
      <div class="m-toast__message">
        <p
          v-for="(msg, i) in message"
          :key="i"
          class="type--small"
          v-html="msg"
        ></p>
      </div>
    </div>
    <div v-if="verifyResolve || verifyCancel" class="m-toast__action">
      <m-button
        v-if="verifyCancel"
        :id="`m_toast_cancel_${id}`"
        :label="toast.cancel.label"
        size="small"
        @click="action('cancel')"
      />
      <m-button
        v-if="verifyResolve"
        :id="`m_toast_resolve_${id}`"
        type="contained"
        :variant="icon?.variant ?? 'primary'"
        :label="toast.resolve.label"
        size="small"
        @click="action('resolve')"
      />
    </div>
  </div>
</template>

<script setup>
/*
 * Monitio Toast component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932348039/Toast
 */

import { ref, computed, watch } from "vue";

import MIcon from "@components/MIcon.vue";
import MImage from "@components/MImage.vue";
import MButton from "@components/MButton.vue";
import MLoading from "@components/MLoading.vue";
import { snakeCase } from "lodash-es";
import { useAlertsStore } from "@root/store/modules/alerts";

const props = defineProps({
  toast: {
    type: Object,
    required: true,
  },
});

watch(
  () => props,
  () => {
    if (props.toast.message) {
      if (
        typeof props.toast.message !== "string" &&
        !Array.isArray(props.toast.message)
      ) {
        console.error(
          `%cComponent id: m_toast_${props.toast.id}`,
          "font-weight:bold",
          `\n Invalid prop "toast": expected attribute "message" to be a string or array. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932348039/Toast for instructions on how to use the MToast component.`
        );
      }
    } else {
      console.error(
        `%cComponent id: m_toast_${props.toast.id}`,
        "font-weight:bold",
        '\n Invalid prop "toast": expected attribute "message". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932348039/Toast for instructions on how to use the MToast component.'
      );
    }
    if (props.toast.variant) {
      if (
        !["success", "error", "warning", "info", "save", "load"].includes(
          props.toast.variant
        )
      ) {
        console.error(
          `%cComponent id: m_toast_${props.toast.id}`,
          "font-weight:bold",
          `\n Invalid prop "toast": expected attribute "variant" with value "success", "error", "warning" or "info" and got "${props.toast.variant}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932348039/Toast for instructions on how to use the MToast component.`
        );
      }
    }
    if (props.toast.resolve) {
      if (typeof props.toast.resolve?.action !== "function") {
        console.error(
          `%cComponent id: m_toast_${props.toast.id}`,
          "font-weight:bold",
          `\n Invalid prop "toast": expected attribute "resolve.action" to be a function. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932348039/Toast for instructions on how to use the MToast component.`
        );
      }
      if (typeof props.toast.resolve?.label !== "string") {
        console.error(
          `%cComponent id: m_toast_${props.toast.id}`,
          "font-weight:bold",
          `\n Invalid prop "toast": expected attribute "resolve.label" to be a string. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932348039/Toast for instructions on how to use the MToast component.`
        );
      }
    }
    if (props.toast.cancel) {
      if (typeof props.toast.cancel?.action !== "function") {
        console.error(
          `%cComponent id: m_toast_${props.toast.id}`,
          "font-weight:bold",
          `\n Invalid prop "toast": expected attribute "cancel.action" to be a function. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932348039/Toast for instructions on how to use the MToast component.`
        );
      }
      if (typeof props.toast.cancel?.label !== "string") {
        console.error(
          `%cComponent id: m_toast_${props.toast.id}`,
          "font-weight:bold",
          `\n Invalid prop "toast": expected attribute "cancel.label" to be a string. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932348039/Toast for instructions on how to use the MToast component.`
        );
      }
    }
  }
);

const alertsStore = useAlertsStore();

const id = computed(() => snakeCase(props.toast.id));
const exiting = ref(false);

const action = (callType) => {
  switch (callType) {
    case "resolve":
      if (typeof props.toast.resolve?.action === "function") {
        props.toast.resolve.action();
      }
      break;
    case "cancel":
      if (typeof props.toast.cancel?.action === "function") {
        props.toast.cancel.action();
      }
      break;
    case "close":
      break;
    default:
      console.error('MToast: function "action" has an invalid argument');
      return;
  }

  exiting.value = true;
  alertsStore.remove(props.toast.id);
};

const icon = computed(() => {
  const variant = props.toast?.variant;
  const icon = { name: "", variant: "" };
  if (variant != "load") {
    icon.variant = variant;
    switch (variant) {
      case "info":
        icon.name = variant;
        icon.variant = "primary";
        break;
      case "success":
        icon.name = "check";
        break;
      case "error":
        icon.name = "clear";
        break;
      case "save":
        icon.name = "saved-filled";
        break;
      case "warning":
        icon.name = variant;
    }
    return icon;
  } else return null;
});

const message = computed(() => {
  let fullMessage = [];
  if (typeof props.toast.message == "string") {
    fullMessage.push(props.toast.message);
  } else if (Array.isArray(props.toast.message)) {
    fullMessage = props.toast.message;
  }
  return fullMessage;
});

const verifyResolve = computed(() => {
  return (
    typeof props.toast.resolve?.action === "function" &&
    typeof props.toast.resolve?.label === "string"
  );
});

const verifyCancel = computed(() => {
  return (
    typeof props.toast.cancel?.action === "function" &&
    typeof props.toast.cancel?.label === "string"
  );
});
</script>

<style scoped lang="scss">
.m-toast {
  min-width: $column-2;
  max-width: $column-4;
  padding: $spacing-2 $spacing-3 $spacing-2;
  @include round-corners($spacing-1);
  @include flex(flex-start, flex-start, column);
  gap: $spacing-3;
  position: relative;
  background-color: color($white);
  @include elevate-notification;
  animation: m-toast--in 0.4s ease-out;
  z-index: calc($z-dialog - 1);

  &__icon {
    margin-top: math.div($spacing-1, 2);

    .m-icon--saved {
      fill: color($gold);
    }

    .m-loading--overlay {
      width: calc($spacing-7 - 1px);
    }
  }

  &__remove {
    padding: $spacing-0;
    @include round-corners;
    display: none;
    position: absolute;
    top: $spacing-0;
    right: $spacing-0;
    background-color: color(white);
    @include elevate-button;
    transform: translate($spacing-2, -$spacing-2);
  }

  &__header {
    width: 100%;
    @include flex(flex-start, flex-start, row);
    gap: $spacing-2;
  }

  &__action {
    width: 100%;
    @include flex(flex-end, flex-end, row);
    gap: $spacing-2;
  }

  &:hover {
    @include elevate-popover;

    .m-toast__remove {
      @include flex(center, center, row);
    }
  }

  &--out {
    //transform: translateX($column-5);
    animation: m-toast--out 0.4s ease-out;
  }
}

@keyframes m-toast--in {
  0% {
    transform: translateX($column-4);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes m-toast--out {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX($column-5);
    opacity: 0;
  }
}
</style>
@root/utils/
