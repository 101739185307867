<template>
  <div class="m-modal__body">
    <div class="m-mention__actions">
      <div class="m-actions__mention">
        <h4 class="mr-3">{{ t("editMentionLink.mention") }}</h4>
        <h6>{{ slotProps.mention }}</h6>
      </div>
    </div>
    <div class="m-mention__edit m-mention__edit--scroll">
      <div class="mt-2 m-mention__entity">
        <h4 class="mx-0 mb-2 m-toolbox__title">
          {{ t("editMentionLink.entity") }}
        </h4>
        <div>
          <label class="mb-1">
            {{ t("editMentionLink.name") }}
          </label>
          <m-autocomplete
            id="entity_name"
            @select="(val) => changedName(val)"
            :propertyTypes="allPropertyTypes"
            :allowCustomQuerySelection="true"
            :query="entity.name"
            :clear="false"
            floating
          />
        </div>
        <m-input
          id="entity_link"
          v-model="entity.description"
          :placeholder="t('editMentionLink.description')"
          :label="t('editMentionLink.description')"
        />
        <m-input
          id="entity_link"
          v-model="entity.uri"
          :placeholder="t('editMentionLink.link')"
          :label="t('editMentionLink.link')"
        />
        <m-selector
          id="entity_iptc"
          :options="keyTypes"
          :selected="entity.type"
          @update:selected="changedType"
          contentType="multiple"
          :outsideLabel="t('editMentionLink.type')"
          floating
        />
        <m-toggle
          id="toggle_value"
          v-model="applyToPresentDocument"
          :label="t('editMentionLink.changeOnlyPresentDocument')"
          class="mb-2"
        />
        <div v-if="!applyToPresentDocument" class="m-preview__rule">
          <div class="mt-4 mb-2 m-divider"></div>
          <h5 class="mx-0 m-toolbox__title">
            {{ t("editMentionLink.ruleFilters") }}
          </h5>
          <div class="m-rule__content">
            <p class="type--warning type--small">
              {{ `${t("editMentionLink.viewsAffected")}:` }}
              <span class="ml-1 type--500">
                {{ backend.platformId }}
              </span>
            </p>
            <m-toggle
              id="m_edit_mention"
              v-model:modelValue="filters.caseSensitive"
              :label="t('editMentionLink.caseSensitive')"
            />
            <div>
              <m-selector
                id="entity_iptc"
                :options="IPTCs"
                :selected="filters.iptcs"
                @update:selected="updateFilterIPTCs"
                class="eem-autocomplete"
                type="multiple"
                :outsideLabel="t('editMentionLink.filterByIptc')"
                :placeholder="t('editMentionLink.filterByIptcPlaceholder')"
                floating
              />
              <h6 class="ma-1 type--xsmall type--empty">
                <span v-for="(iptc, i) in filters.iptcs" :key="i" class="pr-2">
                  {{ iptc.label }}
                </span>
              </h6>
            </div>
            <div>
              <m-selector
                id="entity_languages"
                :options="languages"
                :selected="filters.languages"
                type="multiple"
                @update:selected="updateFilterLanguages"
                :outsideLabel="t('editMentionLink.filterByLanguage')"
                :placeholder="t('editMentionLink.filterByLanguagePlaceholder')"
                floating
              />
              <h6 class="ma-1 type--xsmall type--empty">
                <span
                  v-for="(language, i) in filters.languages"
                  :key="i"
                  class="pr-2"
                >
                  {{ language.label }}
                </span>
              </h6>
            </div>
            <!--      <h6 v-if="!applyToPresentDocument" class="type--xsmall">
              {{ t("editMentionLink.changeWillNotAffectPresentDocument") }}
            </h6> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="m-mention m-modal__footer">
    <m-button
      v-if="slotProps.close"
      id="m_modal_cancel"
      :label="t('general.buttons.cancel')"
      type="text"
      variant="terciary"
      class="mt-3"
      @click="slotProps.close"
    />
    <m-button
      v-if="slotProps.cancel"
      id="m_modal_continue"
      :label="t('createMention.buttonContinue')"
      type="contained"
      variant="primary"
      class="mt-3 ml-6"
      @click="createMention"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue";
import { IPTCLayer0Labels } from "../../../iptcs";
import { checkForWikiImage } from "@utils/utils";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";
import MInput from "@components/MInput.vue";
import MAutocomplete from "@components/MAutocomplete.vue";
import MButton from "@components/MButton.vue";
import MToggle from "@components/MToggle.vue";
import useTranslator from "@hooks/useTranslator";
import MSelector from "@components/MSelector.vue";
import structuredClone from "@utils/structuredClone";
import { isEqual } from "lodash-es";
import { useUserStore } from "@root/store/modules/user";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const dropdown = ref(false);
const overOption = ref(false);
const { t } = useI18n();
const userStore = useUserStore();
const workspacesStore = useWorkspacesStore();
const { api } = useApi();
const { localizeLanguageCode } = useTranslator();

const userLocale = computed(() => userStore.i18nLanguage);
const userLang = computed(() => userLocale.value.split("-")[0]);

/** @type {Readonly<{slotProps: {mention: string, startPos : number, endPos:number, article: import("@root/types.api.local").MonitioAPI.SearchDocumentDTO}}>} */
const props = defineProps({
  slotProps: Object,
});

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const workspaceId = ref(workspaceConfig.value.id);
const allPropertyTypes = computed(() => {
  return userStore.config.propertyTypeSettings.facets[workspaceId.value]
    .filter((x) => x.active && x.selected)
    .map((x) => x.searchKey);
});

const backend = ref(workspacesStore.currentWorkspaceConfig?.backend);
const entity = ref({
  name: "",
  link: "",
  type: "",
  description: "",
  id: "",
  uri: "",
});

const filters = ref({ languages: [], iptcs: [], caseSensitive: true });

const applyToPresentDocument = ref(false);

const IPTCs = ref(
  IPTCLayer0Labels.map((element) => {
    return {
      label: element,
      value: element,
    };
  })
);

const changedName = async (val) => {
  isSearching.value = false;
  entity.value.type = val.filter.replace("_qid", "");
  entity.value.name = val.label;
  if (val.uri) {
    entity.value.uri = val.uri;
  } else {
    const data = await checkForWikiImage(
      userLang.value,
      val.label,
      val.label,
      userLang.value
    );
    if (data.page) entity.value.uri = data.page;
    else entity.value.uri = "";
  }
  entity.value.description = val.description;
};

const updateFilterIPTCs = (val) => {
  if (!filters.value.iptcs.find((x) => x.value == val.value)) {
    filters.value.iptcs.push(val);
  } else {
    filters.value.iptcs = filters.value.iptcs.filter(
      (x) => x.value != val.value
    );
  }
};

const languages = ref(
  workspacesStore.languages.map((x) => ({
    value: x.isoCode,
    label: localizeLanguageCode(x.isoCode),
  }))
);

const updateFilterLanguages = (val) => {
  if (!filters.value.languages.find((x) => x.value == val.value)) {
    filters.value.languages.push(val);
  } else {
    filters.value.languages = filters.value.languages.filter(
      (x) => x.value != val.value
    );
  }
};

const isSearching = ref(false);
const isUpdatingEntity = ref(false);

const keyTypes = computed(() => {
  return [
    { label: t("general.facets.people"), value: "people" },
    { label: t("general.facets.organization"), value: "organization" },
    { label: t("general.facets.places"), value: "places" },
    { label: t("general.facets.other"), value: "other" },
  ];
});
const changedType = (val) => {
  entity.value.type = val.value;
};

const createMention = async () => {
  /** @type {import("@root/types.api.local").MonitioAPI.EntityMentionRuleRequest} */
  const rule = {
    targetEntity: {
      nature: "UserDefined", //Or if the type is a QID and comes from autocomplet change this type?
      uri: entity.value.uri,
      baseForm: entity.value.name,
      baseFormLanguage: props.slotProps.article.documentLang,
      type: entity.value.type,
      description: entity.value.description,
    },
    mention: props.slotProps.mention,
    mentionStartPos: props.slotProps.startPos,
    mentionEndPos: props.slotProps.endPos,
    documentId: props.slotProps.article.id,
    filterByDocIptcAnyOf: filters.value.iptcs,
    filterByLanguages: filters.value.languages,
    mentionMatchCaseSensitive: filters.value.caseSensitive,
    changeOnlyPresentDocument: applyToPresentDocument.value,
    action: "CreateLink",
  };

  try {
    /** @type {import("@root/types.api.local").MonitioAPI.MentionSection} */
    const result = await api.entity.putEntityMentionRule([rule]);

    if (result.data?.translations)
      //Return the new document
      props.slotProps.close({
        event: "editMention",
        result: result.data,
      });
  } catch (error) {
    alert("something went wrong display error message here");
  }
};
</script>

<style scoped lang="scss">
.m-mention {
  &__edit {
    padding: $spacing-0 $spacing-6;
    flex-grow: 1;
    overflow-y: auto;

    &--scroll {
      @include elevate-inset;
      background-color: color($white);
    }
  }

  &__entity {
    > *:not(:last-child) {
      margin-bottom: $spacing-5;
    }
  }

  &.m-modal {
    &__body {
      padding: $spacing-0;
      @include flex(flex-start, stretch, column);
      gap: $spacing-5;

      .m-rule__content {
        @include flex(flex-start, stretch, column);
        gap: $spacing-5;
      }
    }

    &__footer {
      margin-top: $spacing-3;
      @include flex(flex-end, center, row);
    }
  }

  &__actions {
    padding: $spacing-0;

    .m-actions {
      &__mention {
        margin: $spacing-1 $spacing-0 $spacing-4;
        @include flex(flex-start, center, row);
      }

      &__actions {
        @include flex(flex-end, center, row);
        align-self: flex-end;
        flex-grow: 1;
      }
    }
  }
}
</style>
