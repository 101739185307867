<template>
  <div :id="`m_dialog_${slotProps.id}`" class="m-dialog">
    <div class="m-dialog__wrapper" :class="`m-dialog__wrapper--${variant}`">
      <div
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="m_dialog_label"
        aria-describedby="m_dialog_desc"
        class="m-dialog__container"
      >
        <div class="m-dialog__header">
          <m-icon
            :icon="icon.name"
            status="active"
            :variant="icon.variant"
            size="large"
          />
          <h2 id="m_dialog_label" class="type--small">{{ heading }}</h2>
        </div>
        <div class="m-dialog__body">
          <div id="m_dialog_desc" class="m-dialog__content">
            <m-icon icon="none" size="large" />
            <div class="m-dialog__label" v-if="message">
              <p v-for="(msg, i) in message" :key="i" v-html="msg"></p>
            </div>
          </div>
        </div>
        <div v-if="hasButton" class="m-dialog__message">
          <m-button
            v-if="slotProps.cancel"
            id="m_dialog_cancel"
            :label="buttonCancelLabel"
            type="text"
            variant="terciary"
            @click="dialogAction('cancel')"
          />
          <m-button
            v-if="rejectValidation"
            id="m_dialog_reject"
            :label="buttonRejectLabel"
            type="text"
            variant="terciary"
            @click="dialogAction('reject')"
          />
          <m-button
            v-if="resolveValidation"
            id="m_dialog_resolve"
            :label="buttonResolveLabel"
            :type="icon.variant === 'warning' ? 'contained' : 'text'"
            :variant="icon.variant"
            @click="dialogAction('resolve')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/*
 * Monitio Dialog component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog
 */

import { ref, computed, watch, onBeforeUnmount, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import MIcon from "@components/MIcon.vue";
import MButton from "@components/MButton.vue";
import { useAlertsStore } from "@root/store/modules/alerts";

const { t } = useI18n();
const props = defineProps({
  slotProps: {
    type: Object,
    required: true,
  },
  close: Function,
  cancel: Function,
});

watch(
  () => props,
  () => {
    //Heading Validator
    if (props.slotProps.heading) {
      if (typeof props.slotProps.heading !== "string") {
        console.error(
          `%cComponent id: m_dialog_${props.slotProps.id}`,
          "font-weight:bold",
          `\n Invalid prop "dialog": expected attribute "heading" to be a string. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
        );
      }
    } else {
      console.error(
        `%cComponent id: m_dialog_${props.slotProps.id}`,
        "font-weight:bold",
        '\n Invalid prop "dialog": expected attribute "heading". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.'
      );
    }
    //Message Validator
    if (props.slotProps.message) {
      if (
        typeof props.slotProps.message !== "string" &&
        !Array.isArray(props.slotProps.message)
      ) {
        console.error(
          `%cComponent id: m_dialog_${props.slotProps.id}`,
          "font-weight:bold",
          `Invalid prop "dialog": expected attribute "message" to be a string or array. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
        );
      }
    } else {
      console.error(
        `%cComponent id: m_dialog_${props.slotProps.id}`,
        "font-weight:bold",
        '\n Invalid prop "dialog": expected attribute "message". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.'
      );
    }
    //Variant Validator
    if (props.slotProps.variant) {
      if (
        !["success", "error", "warning", "info"].includes(
          props.slotProps.variant
        )
      ) {
        console.error(
          `%cComponent id: m_dialog_${props.slotProps.id}`,
          "font-weight:bold",
          `\n Invalid prop "dialog": expected attribute "variant" with value "success", "error", "warning" or "info" and got "${props.slotProps.variant}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
        );
      }
    } else {
      console.error(
        `%cComponent id: m_dialog_${props.slotProps.id}`,
        "font-weight:bold",
        '\n Invalid prop "dialog": expected attribute "variant". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.'
      );
    }
    //Resolve Validator
    if (typeof props.slotProps.resolve === "object") {
      if (props.slotProps.resolve.action) {
        if (typeof props.slotProps.resolve?.action !== "function") {
          console.error(
            `%cComponent id: m_dialog_${props.slotProps.id}`,
            "font-weight:bold",
            `\n Invalid prop "dialog": expected attribute "resolve.action" to be a function. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
          );
        }
      } else {
        console.error(
          `%cComponent id: m_dialog_${props.slotProps.id}`,
          "font-weight:bold",
          `\n Invalid prop "dialog": attribute "resolve" should have the "action" attribute defined. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
        );
      }
      if (props.slotProps.resolve.label) {
        if (typeof props.slotProps.resolve?.label !== "string") {
          console.error(
            `%cComponent id: m_dialog_${props.slotProps.id}`,
            "font-weight:bold",
            `\n Invalid prop "dialog": expected attribute "resolve.label" to be a string. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
          );
        } else {
          console.error(
            `%cComponent id: m_dialog_${props.slotProps.id}`,
            "font-weight:bold",
            `\n Invalid prop "dialog": attribute "resolve" should have the "label" attribute defined. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
          );
        }
      }
    }
    //Reject Validator
    if (props.slotProps.reject && typeof props.slotProps.reject === "object") {
      if (props.slotProps.reject.action) {
        if (typeof props.slotProps.reject?.action !== "function") {
          console.error(
            `%cComponent id: m_dialog_${props.slotProps.id}`,
            "font-weight:bold",
            `\n Invalid prop "dialog": expected attribute "reject.action" to be a function. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
          );
        }
      } else {
        console.error(
          `%cComponent id: m_dialog_${props.slotProps.id}`,
          "font-weight:bold",
          `\n Invalid prop "dialog": attribute "reject" should have the "action" attribute defined. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
        );
      }
      if (
        props.slotProps.reject.label &&
        typeof props.slotProps.reject?.label !== "string"
      ) {
        console.error(
          `%cComponent id: m_dialog_${props.slotProps.id}`,
          "font-weight:bold",
          `\n Invalid prop "dialog": attribute "reject" should have the "label" attribute defined. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
        );
      }
    }
    //Cancel Validator
    if (
      typeof props.slotProps.cancel === "object" ||
      typeof props.slotProps.cancel === "string" ||
      typeof props.slotProps.cancel === "boolean"
    ) {
      if (
        props.slotProps.cancel.action &&
        typeof props.slotProps.cancel?.action !== "function"
      ) {
        console.error(
          `%cComponent id: m_dialog_${props.slotProps.id}`,
          "font-weight:bold",
          `\n Invalid prop "dialog": expected attribute "cancel.action" to be a function. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
        );
      }
      if (
        props.slotProps.cancel.label &&
        typeof props.slotProps.cancel?.label !== "string"
      ) {
        console.error(
          `%cComponent id: m_dialog_${props.slotProps.id}`,
          "font-weight:bold",
          `\n Invalid prop "dialog": attribute "cancel" should have the "label" attribute defined. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
        );
      }
    } else {
      console.error(
        `%cComponent id: m_dialog_${props.slotProps.id}`,
        "font-weight:bold",
        `\n Invalid prop "dialog": expected attribute "cancel" to be an object, string or boolean. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/932610085/Dialog for instructions on how to use the MDialog component.`
      );
    }
  }
);

const alertStore = useAlertsStore();
const heading = computed(() => props.slotProps.heading);
const variant = computed(() => props.slotProps.variant);

const keyMovement = (evt) => {
  if (evt.keyCode === 27) dialogAction("cancel");
};

onMounted(() => {
  document.body.addEventListener("keyup", keyMovement);
});

onBeforeUnmount(() => {
  document.body.removeEventListener("keyup", keyMovement);
});

const message = computed(() => {
  let fullMessage = [];
  if (typeof props.slotProps.message == "string") {
    fullMessage.push(props.slotProps.message);
  } else if (Array.isArray(props.slotProps.message)) {
    fullMessage = props.slotProps.message;
  }
  return fullMessage;
});

const rejectValidation = computed(() => {
  return typeof props.slotProps.reject?.action === "function";
});

const resolveValidation = computed(() => {
  return typeof props.slotProps.resolve?.action === "function";
});

const icon = computed(() => {
  const icon = { name: "", variant: "" };
  if (variant.value) {
    icon.variant = variant.value;
    switch (variant.value) {
      case "info":
        icon.name = variant.value;
        icon.variant = "primary";
        break;
      case "success":
        icon.name = "check";
        break;
      case "error":
        icon.name = "clear";
        break;
      case "warning":
        icon.name = variant.value;
        break;
    }
    return icon;
  }
  return null;
});

const hasButton = computed(() => {
  return (
    props.slotProps.resolve || props.slotProps.cancel || props.slotProps.reject
  );
});

const buttonRejectLabel = computed(() => {
  return props.slotProps.reject?.label ?? t("general.buttons.reject");
});

const buttonCancelLabel = computed(() => {
  const cancel = props.slotProps.cancel;
  if (typeof cancel === "string") {
    return cancel;
  } else return cancel?.label ?? t("general.buttons.cancel");
});

const buttonResolveLabel = computed(() => {
  return props.slotProps.resolve?.label ?? t("general.buttons.resolve");
});

const dialogAction = (action) => {
  switch (action) {
    case "resolve":
      props.slotProps.resolve?.action();
      props.close("resolve");
      break;
    case "reject":
      props.slotProps.reject?.action();
      props.close("reject");
      break;
    case "cancel":
      if (typeof props.slotProps.cancel.action === "function") {
        props.slotProps.cancel?.action();
      }
      props.cancel();
      break;
  }

  alertStore.remove(props.slotProps.id);
};
</script>

<style scoped lang="scss">
.m-dialog {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-dialog;

  &__wrapper {
    width: 100vw;
    height: 100vh;
    @include flex(center, center, row);
    background: linear-gradient(
      120deg,
      color($overlay, 0.8) 0%,
      color($overlay, 0.8) 100%
    );
    backdrop-filter: blur(1px);

    &--info {
      .m-dialog__container {
        background: linear-gradient(
          to bottom,
          $pri-action $spacing-1,
          $white 0%
        );
      }
    }

    &--success {
      .m-dialog__container {
        background: linear-gradient(to bottom, $success $spacing-1, $white 0%);
      }
    }

    &--warning {
      .m-dialog__container {
        background: linear-gradient(to bottom, $warning $spacing-1, $white 0%);
      }
    }

    &--error {
      .m-dialog__container {
        background: linear-gradient(to bottom, $error $spacing-1, $white 0%);
      }
    }
  }

  &__body {
    width: 100%;
    @include flex(flex-start, flex-start, column);
    gap: $spacing-4;
  }

  &__container {
    padding: $spacing-6;
    @include round-corners($spacing-1);
    @include flex(flex-start, flex-start, column);
    gap: $spacing-4;
    position: relative;
    background: color($white);
    @include elevate-modal;
  }

  &__header {
    @include flex(flex-start, center, row);
    gap: $spacing-4;

    :deep(.m-icon span) {
      width: $spacing-6;
    }
  }

  &__content {
    @include flex(flex-start, stretch, row);
    gap: $spacing-4;
  }

  &__label {
    @include flex(flex-start, flex-start, column);
    gap: $spacing-2;
  }

  &__message {
    width: 100%;
    @include flex(flex-end, flex-end, row);
    gap: $spacing-2;
  }
}

@include mq(desktopXS) {
  .m-dialog__container {
    width: $column-5;
  }
}

@include mq(desktopS) {
  .m-dialog__container {
    width: $column-5;
  }
}

@include mq(desktopM) {
  .m-dialog__container {
    width: $column-5;
  }
}

@include mq(desktopL) {
  .m-dialog__container {
    width: $column-4;
  }
}

@include mq(desktopXL) {
  .m-dialog__container {
    width: $column-4;
  }
}
</style>
