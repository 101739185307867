<template>
  <div class="m-open-team m-modal__body" data-test="m_modal_open_team">
    <div class="m-open-team__heading">
      <h2 class="type--small">{{ team?.name }}</h2>
      <m-options
        id="m_open_team_options"
        :options="teamOptions"
        :position="['left', 'bottom']"
        size="small"
        disableSort
        class="m-clickable py-1"
        @select="teamSelectOpt"
      />
    </div>
    <div class="my-5 m-divider"></div>
    <div class="m-open-team__add">
      <m-search
        v-if="addingUser"
        id="m_create_team_add"
        :options="availableUsers"
        :placeholder="t('settings.teams.createTeam.teammatesPlaceholder')"
        type="autocomplete"
        size="small"
        class="pa-1"
        @select="add"
      />
      <div
        v-else
        class="pa-1 m-badge m-badge--add m-clickable"
        @click="addingUser = true"
      >
        <div class="m-badge__image">
          <m-icon icon="add" status="active" variant="primary" />
        </div>
        <h5 class="type--small">Add user</h5>
      </div>
    </div>
    <div class="m-open-team__list">
      <div
        v-for="(user, i) in team?.members"
        :key="i"
        class="pa-1 m-list__user m-clickable"
        :class="{ 'd-none': user.id == userStore.details.id }"
        @mouseenter="hover = user.id"
        @mouseleave="hover = undefined"
      >
        <m-user-badge :user="user" type="badge" />
        <m-icon
          v-if="hover == user.id"
          id="m_team_remove"
          icon="close"
          size="small"
          @click="remove(user)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";
import MSearch from "@components/MSearch.vue";
import MUserBadge from "@components/MUserBadge.vue";
import MIcon from "@components/MIcon.vue";
import MOptions from "@components/MOptions.vue";
import structuredClone from "@root/utils/structuredClone";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useSessionStore } from "@root/store/modules/session";

const props = defineProps({
  slotProps: Object,
});

const { t } = useI18n();
const userStore = useUserStore();
const alertsStore = useAlertsStore();
const sessionStore = useSessionStore();
const workspacesStore = useWorkspacesStore();
const { api } = useApi();

const hover = ref(undefined);

const addingUser = ref(false);
onUnmounted(() => {
  addingUser.value = false;
});

const selectedTeamId = computed(() => sessionStore.modalData);

const team = computed(() => {
  return userStore.details?.teams?.find((f) => f.id == selectedTeamId.value);
});

const teamOptions = ref([
  // { value: "rename", label: "Rename" },
  { value: "delete", label: "Delete" },
]);

const deleteTeam = () => {
  const team = userStore.details?.teams?.find(
    (f) => f.id == selectedTeamId.value
  );

  props.slotProps.close();
  alertsStore.add({
    type: "toast",
    variant: "success",
    message: t("general.alerts.toast.deletedTeam", { name: team.name }),
  });
  userStore.archiveTeam(selectedTeamId.value);
};

const teamSelectOpt = async (val) => {
  if (val == "rename") {
    // code
  } else if (val == "delete") {
    alertsStore.add({
      type: "dialog",
      variant: "error",
      heading: t("general.alerts.dialog.deleteTeam.heading", {
        name: team.value.name,
      }),
      message: t("general.alerts.dialog.deleteTeam.message"),
      resolve: {
        label: t("general.buttons.delete"),
        action: deleteTeam,
      },
      cancel: true,
    });
  }
};

const availableUsers = computed(() => {
  const available =
    workspacesStore?.currentWorkspaceConfig?.workspaceUsers.map((m) => ({
      value: m.id,
      label: `${m.accountDetails.firstName} ${m.accountDetails.lastName}`,
      type: "user",
      ...m,
    })) || [];

  team.value.members?.forEach((fe) => {
    const idx = available.findIndex((f) => f.id == fe.id);
    if (idx != -1) available.splice(idx, 1);
  });

  return available;
});

const add = (user) => {
  userStore.addToTeam(user.id, selectedTeamId.value);
};

const remove = (user) => {
  userStore.removeFromTeam(user.id, selectedTeamId.value);
};
</script>

<style scoped lang="scss">
.m-open-team {
  &__heading {
    @include flex(space-between, center, row);
  }

  :deep(.m-search) {
    width: calc(100% - $spacing-2);
  }

  &__add {
    margin-bottom: $spacing-2;
  }

  &__list {
    max-height: $column-9;
    @include flex(flex-start, flex-start, column);
    gap: $spacing-2;
    overflow: auto;

    *,
    :deep(*) {
      cursor: pointer;
    }

    .m-list__user {
      width: 100%;
      @include flex(space-between, center, row);
    }
  }
}
</style>
