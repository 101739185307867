export default (app) => {
  const hasErrorClassName = "has-validation-message";
  const errContainerClassName = "validation-message-container";

  const findErrEl = (el) => {
    let errEl = null;
    let current = el && el.nextElementSibling;
    while (errEl == null && current) {
      // Se houver inserções "maradas" no DOM pode acontecer apanhar a errEl
      // de outro el. Para já não é necessário precaver situações destas
      if (current.classList.contains(errContainerClassName)) errEl = current;
      current = current.nextElementSibling;
    }

    return errEl;
  };

  const positionErrEl = (el) => {
    return function () {
      let errEl = findErrEl(el);
      if (errEl) {
        errEl.style.top = `${el.offsetTop + el.offsetHeight}px`;
        errEl.style.left = `${el.offsetLeft}px`;
      }
    };
  };

  app.directive("validation-message", {
    bind: function (el, binding, vnode) {
      let handler = positionErrEl(el);
      vnode.context.__validationHandler = handler;
      window.addEventListener("resize", handler);
    },
    unbind: function (el, binding, vnode) {
      window.removeEventListener("resize", vnode.context.__validationHandler);
    },
    componentUpdated: function (el, binding, vnode) {
      if (binding.value) {
        let errEl = findErrEl(el);
        if (binding.value !== binding.oldValue || errEl === null) {
          if (errEl == null) {
            el.classList.add(hasErrorClassName);
            errEl = el.ownerDocument.createElement("div");
            errEl.setAttribute(vnode.context.$options._scopeId, null);
            errEl.classList.add(errContainerClassName);
            el.parentNode.insertBefore(errEl, el.nextSibling);
            //errEl.style.position = "absolute";
          }

          let type = binding.arg === "warn" ? "warn" : "err";
          //clean
          errEl.classList.remove("warn");
          errEl.classList.remove("err");
          errEl.classList.add(type);

          errEl.innerHTML = binding.value;
          // errEl.style.top = `${el.offsetTop + el.offsetHeight}px`;
          // errEl.style.left = `${el.offsetLeft}px`;
        }
      } else {
        el.classList.remove(hasErrorClassName);
        let errEl = findErrEl(el);
        if (errEl) errEl.parentNode.removeChild(errEl);
      }
    },
  });
};
