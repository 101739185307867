<template>
  <div class="m-modal__body">
    <m-picture-upload
      id="settings_view"
      :label="$t('views.settings.thumbnail')"
      :tooltip="thumbnailTooltip"
      @upload="uploadPicture"
      :imageURL="viewImage"
    />
    <m-input
      id="settings_view_title"
      v-model:modelValue="newView.name"
      type="text"
      :label="$t('views.settings.title')"
      :placeholder="$t('views.settings.title')"
      class="my-3"
      @resolve="finishedEditing"
    />
    <m-textarea
      id="settings_view_description"
      v-model:modelValue="newView.description"
      type="text"
      :label="$t('views.settings.description')"
      :placeholder="$t('views.settings.description')"
      class="my-3"
      @resolve="finishedEditing"
    />
  </div>
  <div class="m-mention m-modal__footer">
    <m-button
      v-if="props.slotProps.cancel"
      id="m_modal_cancel"
      :label="t('general.buttons.cancel')"
      type="text"
      variant="terciary"
      class="mt-3 mr-6"
      @click="props.slotProps.cancel"
    />
    <m-button
      v-if="props.slotProps.close"
      id="m_modal_continue"
      :label="t('views.settings.buttons_resolve')"
      type="contained"
      variant="primary"
      class="mt-3"
      :disabled="disabled"
      @click="save"
    />
  </div>
</template>

<script setup>
import { reactive, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";

import MButton from "@components/MButton.vue";
import MInput from "@components/MInput.vue";
import MTextarea from "@components/MTextarea.vue";
import MPictureUpload from "@components/MPictureUpload.vue";
import structuredClone from "@utils/structuredClone";
import { useAlertsStore } from "@root/store/modules/alerts";

const props = defineProps({
  /** @description {view} */
  slotProps: Object,
});
const { t } = useI18n();
const alertsStore = useAlertsStore();
const { open, close, cancel } = useModal("m-modal");

const { api } = useApi();

const newView = reactive({
  name: props.slotProps.view?.name,
  description: props.slotProps.view?.details?.description,
  thumbnail: props.slotProps.view?.thumbnail,
});

const disabled = computed(() => {
  return !(
    newView.name != props.slotProps.view?.name ||
    newView.description != props.slotProps.view?.details?.description ||
    newView.thumbnail != props.slotProps.view?.thumbnail
  );
});
watch(
  () => disabled.value,
  (val) => {
    // eslint-disable-next-line vue/no-mutating-props
    props.slotProps.disabled = val;
  }
);

const save = () => {
  props.slotProps.close(newView);

  alertsStore.add({
    type: "toast",
    variant: "success",
    message: t("general.alerts.toast.changedView", { name: newView.name }),
  });
};

const viewImage = computed(() => {
  return props.slotProps.view?.details?.image || "";
});

const uploadPicture = (val) => {
  // should be the temporary url
  newView.thumbnail = val;
};

const finishedEditing = () => {
  if (!disabled.value) {
    close();
  }
};

const thumbnailTooltip = computed(() => {
  let content;
  if (props.slotProps.view?.details?.image) {
    content = t("views.settings.thumbnail_update");
  } else {
    content = t("views.settings.thumbnail_add");
  }
  return {
    content: content,
    position: "dynamic",
  };
});
</script>

<style scoped lang="scss">
.m-modal__body {
  @include flex(flex-start, stretch, column);
}
</style>
