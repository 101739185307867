<template>
  <div class="m-modal">
    <div ref="modalWrapperRef" class="m-modal__wrapper">
      <div
        ref="modalContainerRef"
        class="m-modal__container"
        data-tour="tour_createView_viewSetup"
        :style="containerStyle"
      >
        <div v-if="heading || description" class="m-modal__header">
          <h2 v-if="heading">{{ heading }}</h2>
          <h6 v-if="description" class="mt-1">{{ description }}</h6>
        </div>

        <m-icon
          id="m_modal_close"
          icon="close"
          size="small"
          variant="secondary"
          :tooltip="closeTooltip"
          @click="cancelClick"
        />

        <div v-if="message" ref="modalBodyRef">
          <h6>{{ message }}</h6>
        </div>

        <div
          v-else
          ref="modalBodyRef"
          :class="{ 'm-modal__scroll': buttons != 'custom' && hasScroll }"
        >
          <slot name="default"></slot>
        </div>

        <div v-if="buttons != 'custom'" class="m-modal__footer">
          <slot name="footer"></slot>
          <m-button
            v-if="buttons == 'cancel' || buttons?.includes('cancel')"
            id="m_modal_cancel"
            :label="buttonCancel?.label || t('general.buttons.cancel')"
            type="text"
            variant="terciary"
            :size="buttonsSize"
            class="mt-3"
            @click="cancelClick"
          />
          <m-button
            v-if="buttons == 'resolve' || buttons?.includes('resolve')"
            id="m_modal_continue"
            :label="buttonResolve?.label || t('general.buttons.continue')"
            type="contained"
            :variant="buttonResolve?.variant || 'primary'"
            :size="buttonsSize"
            class="mt-3 ml-6"
            :disabled="disabled"
            :hint="buttonResolve?.hint"
            @click="resolveClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import useWindowSize from "@hooks/useWindowSize";
import MIcon from "@components/MIcon.vue";
import MButton from "@components/MButton.vue";

const { t } = useI18n();

const props = defineProps({
  slotProps: Object,
  close: Function,
  cancel: Function,
});

const { windowHeight } = useWindowSize();

const keyMovement = (evt) => {
  if (evt.keyCode == 27) props.cancel();
};

const modalWrapperRef = ref(null);
const modalContainerRef = ref(null);
const modalBodyRef = ref(null);

const clickOutside = (evt) => {
  if (modalWrapperRef.value == evt.target) props.cancel();
};

onMounted(() => {
  document.body.addEventListener("keyup", keyMovement);
  document.body.addEventListener("click", clickOutside);
});

onBeforeUnmount(() => {
  document.body.removeEventListener("keyup", keyMovement);
  document.body.removeEventListener("click", clickOutside);
});

const heading = computed(() => props.slotProps?.heading);

const description = computed(() => props.slotProps?.description);
const message = computed(() => props.slotProps?.message);

const buttons = computed(() => {
  const btns = props.slotProps?.buttons;
  let validateButtons = true;
  btns.forEach((fe) => {
    if (validateButtons) {
      validateButtons = ["resolve", "cancel", "custom"].includes(fe);
    }
  });

  if (
    !Array.isArray(btns) ||
    !validateButtons ||
    (btns.includes("custom") && btns?.length > 1)
  ) {
    console.error(
      `Invalid prop "buttons". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589529166/Modals for instructions on how to use the MBase component.`
    );
  }
  return btns;
});

const hasScroll = computed(() => {
  return modalBodyRef.value?.scrollHeight > windowHeight.value * 0.6;
});

const findAndCloseDropdown = () => {
  const dropdowns = document.getElementsByClassName("m-dropdown");
  if (dropdowns.length) {
    document.body.removeChild(dropdowns[0]);
  }
};

const buttonCancel = computed(() => {
  return props.slotProps?.cancel;
});

const cancelClick = () => {
  findAndCloseDropdown();
  props.cancel();
};

const closeTooltip = computed(() => {
  return {
    content: t("general.buttons.close"),
    position: "dynamic",
  };
});

const buttonResolve = computed(() => {
  return props.slotProps?.resolve;
});

const resolveClick = () => {
  findAndCloseDropdown();
  props.close();
};

const disabled = computed(() => {
  return props.slotProps?.disabled;
});

const containerStyle = computed(() => {
  let width;

  if (!props.slotProps?.width) {
    width = "unset";
  } else if (
    typeof props.slotProps?.width == "string" &&
    props.slotProps.width.includes("px")
  ) {
    width = props.slotProps.width;
  } else {
    width = `var(--column-${props.slotProps.width})`;
  }

  return `width: ${width}`;
});

const buttonsSize = computed(() => {
  if (modalContainerRef.value?.getBoundingClientRect()?.width < 400) {
    return "block";
  } else return "default";
});
</script>

<style scoped lang="scss">
.m-modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-modal;

  &__wrapper {
    width: 100vw;
    height: 100vh;
    @include flex(center, center, row);
    background: linear-gradient(
      120deg,
      color($overlay, 0.8) 0%,
      color($overlay, 0.8) 100%
    );
    backdrop-filter: blur(1px);
  }

  &__container {
    min-width: $column-2;
    padding: $spacing-6 $spacing-0;
    @include round-corners($spacing-1);
    position: relative;
    background-color: color($white);
    @include elevate-modal;
  }

  &__header {
    padding: $spacing-0 $spacing-6;
    margin-bottom: $spacing-5;
  }

  :deep(.m-modal__body) {
    max-height: 60vh;
    padding: $spacing-0 $spacing-6;
    overflow-y: auto;
  }

  &__scroll {
    :deep(.m-modal__body) {
      padding: $spacing-0;
      @include elevate-inset;
    }
  }

  // :deep(.m-modal__body) {
  //   max-height: 60vh;
  //   padding: $spacing-0;
  //   overflow-y: auto;
  // }

  // :deep(.m-modal__body--scroll) {
  //   @include elevate-inset;
  // }

  .m-divider {
    width: calc(100% - $spacing-12);
  }

  #m_modal_close {
    position: absolute;
    top: $spacing-2;
    right: $spacing-2;
  }

  &__footer,
  :deep(.m-modal__footer) {
    padding: $spacing-0 $spacing-6;
    margin-top: $spacing-5;
    @include flex(flex-end, center, row);
  }
}
</style>
