<template>
  <div
    :id="`m_toolbox_${id}`"
    data-tour="tour_m_toolbox_element"
    class="m-toolbox__module"
  >
    <h6 class="m-toolbox__title">
      {{ t("views.reports.toolbox.heading") }}
    </h6>
    <label class="mb-1 mx-3 type--small">
      {{ t("views.reports.toolbox.style") }}
    </label>
    <div class="m-toolbox__tool m-toolbox__style">
      <component
        v-for="(tag, i) in tags"
        :key="i"
        :is="tag"
        class="m-toolbox__option"
        :class="{ 'm-toolbox__option--selected': tag == settings?.style }"
        @click="change(tag, 'style')"
      >
        {{ t(`views.reports.toolbox.style_${tag}`) }}
      </component>
    </div>
    <label class="mb-1 mx-3 type--small">
      {{ t("views.reports.toolbox.alignment") }}
    </label>
    <div class="m-toolbox__tool m-toolbox__alignment">
      <m-toolbox-icon
        v-for="(alignment, i) in alignments"
        :key="i"
        property="text-align"
        :icon="alignment"
        :selected="alignment == settings?.align"
        :label="t(`views.reports.toolbox.alignment_${alignment}`)"
        class="m-toolbox__option"
        :class="{
          'm-toolbox__option--selected': alignment == settings?.align,
        }"
        @click="change(alignment, 'align')"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import MToolboxIcon from "@root/components/MToolboxIcon.vue";

const props = defineProps({
  id: { type: [String, Number] },
  settings: { type: Object },
});

const emit = defineEmits(["update:settings"]);

const { t } = useI18n();

const tags = ref(["h1", "h2", "h3"]);
const alignments = ref(["left", "center", "right"]);

const change = (val, type) => {
  let s = { ...props.settings };
  s[type] = val;
  emit("update:settings", s, props.id);
};
</script>

<style scoped lang="scss">
.m-toolbox {
  &__style {
    .m-toolbox__option {
      height: 40px;
      @include round-corners($spacing-1);
      @include flex(center, center, row);
      color: color($pri-action-inactive);

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &:hover,
      &--selected {
        color: color($pri-action);
      }
    }
  }

  &__alignment {
    @include flex(center, center, row);
    gap: $spacing-4;
  }
}
</style>
