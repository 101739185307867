<template>
  <div
    :id="`m_picture_upload_${id}`"
    class="m-picture-upload"
    :class="`m-picture-upload--${size}`"
    @dragenter.stop.prevent
    @dragover.stop.prevent
    @drop.stop.prevent="handleFiles"
    data-test="m-picture-upload"
  >
    <label
      v-if="label"
      :for="id"
      class="m-picture-upload__label"
      :class="{ 'type--small': size == 'small' }"
    >
      {{ label }}
    </label>
    <div class="p-relative">
      <m-hint v-if="displayHints" id="m_hint_create_view_thumbnail" />
      <div
        :id="`m_picture_upload_${id}_uploader`"
        data-tour="tour_createView_viewSetup_thumbnail"
        :data-tooltip-position="tooltip?.position"
        :data-tooltip-content="tooltip?.content"
        class="m-picture-upload__uploader"
        :class="{ 'm-picture-upload__uploader--error': imgError }"
        @click.stop.prevent="openFile"
        @keydown.enter="openFile"
      >
        <input
          ref="fileRef"
          :id="id"
          type="file"
          accept="image/*"
          class="d-none"
          @click.stop
          @change="pictureUploadChange"
          autocomplete="off"
          data-autofocus="false"
        />
        <m-image
          v-if="fileUrl && !imgError"
          :tooltip="tooltip"
          :src="fileUrl"
          class="m-picture-upload__preview"
          @error="errorUploading"
        />
        <m-loading v-else-if="isLoading" type="spinner" size="xsmall" />
        <m-icon
          v-else-if="imgError"
          :id="`${id}_thumbnail_error`"
          icon="error"
          variant="error"
          size="small"
          status="active"
          :tooltip="tooltip"
          @click="openFile"
        />
        <m-icon
          v-else
          :id="`${id}_thumbnail_upload`"
          icon="upload"
          variant="placeholder"
          size="small"
          :tooltip="tooltip"
          class="m-picture-upload__icon"
          @click="openFile"
        />
        <div v-if="fileUrl && !imgError" class="m-picture-upload__edit">
          <m-icon
            :id="`${id}_thumbnail_edit`"
            icon="edit"
            variant="primary"
            status="active"
            :tooltip="tooltip"
            @click="openFile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/*
 * Monitio Picture Upload component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/882114726/Picture+Upload
 */

import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";
import MIcon from "@components/MIcon.vue";
import MImage from "@components/MImage.vue";
import MLoading from "@components/MLoading.vue";
import MHint from "@components/MHint.vue";
import { useUserStore } from "@root/store/modules/user";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  size: {
    type: String,
    default: "default",
    validator(size) {
      if (!["default", "small", "xsmall"].includes(size)) {
        console.error(
          `Invalid prop "size": expected string with value "default", "small" or "xsmall" and got "${size}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/882114726/Picture+Upload for instructions on how to use the MPictureUpload component.`
        );
      }
      return true;
    },
  },
  tooltip: {
    type: Object,
    validator(tooltip) {
      if (!tooltip.content || typeof tooltip.content !== "string") {
        console.error(
          `Invalid prop "tooltip": tooltip should have the attribute "content" defined. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/882114726/Picture+Upload for instructions on how to use the MPictureUpload component.`
        );
      }
      if (
        tooltip.position &&
        ![
          "dynamic",
          "top-left",
          "top-center",
          "top-right",
          "left",
          "right",
          "bottom-left",
          "bottom-center",
          "bottom-right",
        ].includes(tooltip.position)
      ) {
        console.error(
          `Invalid prop "tooltip": expected string attribute "position" with value "dynamic", "top-left", "top-center", "top-right", "left", "right", "bottom-left", "bottom-center" or "bottom-right", and got "${tooltip.position}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/882114726/Picture+Upload for instructions on how to use the MPictureUpload component.`
        );
      }
      return true;
    },
  },
  customUploadFunction: {
    type: Function,
  },
  label: { type: String },
  imageURL: { type: String },
});

const emit = defineEmits(["upload", "upload-started", "upload-ended"]);
const { t } = useI18n();
const { api } = useApi();
const userStore = useUserStore();

const fileRef = ref();
const fileUrl = ref(props.imageURL || undefined);
const isLoading = ref(false);
const imgError = ref(false);

const tooltip = computed(() => {
  const tooltip = { content: "", position: "dynamic" };

  if (imgError.value) {
    tooltip.content = t("general.errors.imageUploadError");
  } else tooltip.content = t("general.forms.uploadImage");

  return tooltip;
});

const errorUploading = () => {
  imgError.value = true;
};

const openFile = () => {
  fileUrl.value = undefined;
  fileRef.value.click();
  imgError.value = false;
};

// Maybe a loading component when we are waiting for the blob url
const pictureUploadChange = async (evt) => {
  if (evt.target.files.length) {
    emit("upload-started");
    isLoading.value = true;
    const file = evt.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("loadend", async (evt) => {
      try {
        let result;
        if (props.customUploadFunction) {
          result = await props.customUploadFunction(reader.result);
        } else {
          result = await api.workspaces.uploadPicture(reader.result);
        }
        const { data } = result;
        fileUrl.value = data;
        evt.target.value = null;
        emit("upload", data);
        isLoading.value = false;
        emit("upload-ended");
      } catch (error) {
        isLoading.value = false;
        emit("upload-ended");
      }
    });
    reader.readAsArrayBuffer(file);
  }
};

const handleFiles = (evt) => {
  const dt = evt.dataTransfer;
  const files = dt.files;
  evt.target.files = files;
  pictureUploadChange(evt);
};
//tour hints
const displayHints = computed(() => {
  return (
    userStore.ongoingTour.name == "createView" &&
    userStore.ongoingTour.currentStep == 2
  );
});
</script>

<style lang="scss">
.m-picture-upload {
  @include flex(center, center, column);

  &__label {
    margin-bottom: $spacing-1;
  }

  &__uploader {
    width: 60px;
    height: 60px;
    border: 1px solid color($pri-action-inactive, 0.3);
    @include round-corners;
    position: relative;
    @include flex(center, center, row);
    background-color: color($sec-50);
    overflow: hidden;

    &--error {
      border: 1px solid color($error, 0.3);
      background-color: color($error-light);
    }

    img {
      object-fit: cover;
    }
  }

  &__preview {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__icon {
    fill: color($pri-action-inactive, 0.6);
  }

  &__edit {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: color($pri-action-light, 0.2);
    cursor: pointer;
  }

  &__uploader:hover {
    cursor: pointer;

    .m-picture-upload {
      &__preview {
        @include opacity-disabled;
      }

      &__icon {
        fill: color($pri-action);
        color: color($pri-action);
        stroke: color($pri-action);
        @include opacity-active;
      }

      &__edit {
        @include flex(center, center, row);
      }
    }
  }

  .m-hint {
    transform: translate($spacing-3, 3px);
    z-index: 1;
  }
}
</style>
